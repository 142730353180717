import styled, { css } from 'styled-components';
import { defaultSpacing, doubleSpacing, halfSpacing, separatorColorDefault } from '../../../theme/selectors';
import type { cssHelperType, Transient } from '../../../types';
import { FuseFlex } from '../FuseFlex';
import type { IFuseSeparatorProps } from './types';

export const SFuseSeparator = styled(FuseFlex).attrs(() => ({
	alignItems: 'center',
	justifyContent: 'space-between',
	wrap: 'nowrap',
}))<Transient<IFuseSeparatorProps>>`
	width: 100%;

	${({ $spacing }): cssHelperType =>
		({
			xs: css`
				margin: ${halfSpacing} 0;
			`,
			sm: css`
				margin: ${defaultSpacing} 0;
			`,
			md: css`
				margin: ${doubleSpacing} 0;
			`,
			lg: css`
				margin: 1.375rem 0;
			`,
		})[$spacing] ?? null};

	&:before,
	&:after {
		content: '';
		width: 100%;
		height: 1px;
		background-color: ${separatorColorDefault};
	}
`;
