import type { JSX, PropsWithChildren } from 'react';
import React from 'react';
import type { IFuseChartWrapperProps } from './types';
import { FuseTypography } from '../../dataDisplay/FuseTypography';
import { FuseTooltip } from '../../dataDisplay/FuseTooltip';
import { FuseSeparator } from '../../layout/FuseSeparator';
import { FuseFlexItem } from '../../layout/FuseFlex';
import { SChartCardWrapper, STitleMainWrapper, STitleWrapper, STooltipIcon } from './FuseChartWrapper.styles';

export const FuseChartWrapper = ({ children, title, width, height, tooltip, subTitle }: PropsWithChildren<IFuseChartWrapperProps>): JSX.Element => {
	return (
		<SChartCardWrapper $width={width} $height={height} data-test-id="chart-wrapper">
			<STitleMainWrapper>
				<FuseFlexItem>
					<STitleWrapper>
						<FuseTypography as="span" variant="b2" weight="bold">
							{title}
						</FuseTypography>
						{!!subTitle && (
							<>
								<FuseTypography as="span" variant="b2">
									|
								</FuseTypography>
								<FuseTypography as="span" variant="b3">
									{subTitle}
								</FuseTypography>
							</>
						)}
					</STitleWrapper>
				</FuseFlexItem>
				{tooltip ? (
					<FuseTooltip content={tooltip} data-test-id="chart-wrapper">
						<STooltipIcon />
					</FuseTooltip>
				) : null}
			</STitleMainWrapper>
			<FuseSeparator />
			{children}
		</SChartCardWrapper>
	);
};
