import styled from 'styled-components';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { FloatingArrow } from '@floating-ui/react';
import {
	borderRadiusDefault,
	defaultSpacing,
	fontFamily,
	fontSizeBody3,
	secondaryColorContrastText,
	secondaryColorDefault,
	zIndexTooltip,
} from '../../../theme/selectors';

export const SArrow = styled(FloatingArrow).attrs(({ theme }) => ({
	fill: secondaryColorDefault({ theme }),
	tipRadius: 2,
}))``;

export const STooltip = styled(motion.div).attrs<void, MotionProps>(() => ({
	initial: { opacity: 0, scale: 0.85 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0 },
	transition: { duration: 0.2 },
}))`
	font-family: ${fontFamily};
	z-index: ${zIndexTooltip};
	background-color: ${secondaryColorDefault};
	color: ${secondaryColorContrastText};
	font-size: ${fontSizeBody3};
	border-radius: ${borderRadiusDefault};
	padding: ${defaultSpacing};
	max-width: 15rem;
`;

export const SChildrenWrapper = styled.div`
	display: inline-flex;
	max-width: 100%;
`;
