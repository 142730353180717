import styled, { css } from 'styled-components';
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa';
import { RiAlertFill } from 'react-icons/ri';
import type { IconType } from 'react-icons/lib';
import { IoCloseOutline } from 'react-icons/io5';
import { FuseFlex } from '../../layout/FuseFlex';
import {
	activeColorDefault,
	backgroundColorDefault,
	borderColorDefault,
	borderRadiusDefault,
	defaultSpacing,
	errorColorDefault,
	fontSizeH3,
	fontSizeH4,
	halfSpacing,
	infoColorDefault,
	primaryColorDefault,
	shadowDefault,
	successColorDefault,
	textColorSecondary,
	transitionDurationDefault,
	warningColorDefault,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';
import type { cssHelperType } from '../../../types';
import type { FuseAlertBaseType, IFuseAlertBaseStylesProps } from './types';

export const typeToIconMap: Record<FuseAlertBaseType, { type: IconType; color: ThemeSelector<string> }> = {
	primary: {
		type: FaExclamationCircle,
		color: primaryColorDefault,
	},
	info: {
		type: FaExclamationCircle,
		color: infoColorDefault,
	},
	warning: {
		type: RiAlertFill,
		color: warningColorDefault,
	},
	error: {
		type: FaTimesCircle,
		color: errorColorDefault,
	},
	success: {
		type: FaCheckCircle,
		color: successColorDefault,
	},
};

export const Icon = styled.div.attrs<IFuseAlertBaseStylesProps>(({ theme, $type }) => ({
	color: typeToIconMap[$type].color({ theme }),
	as: typeToIconMap[$type].type,
}))<IFuseAlertBaseStylesProps>`
	flex-shrink: 0;
	width: ${fontSizeH3};
	height: ${fontSizeH3};
`;

export const SFuseAlertBaseWrapper = styled.div<IFuseAlertBaseStylesProps>`
	background: ${backgroundColorDefault};
	box-shadow: ${shadowDefault};
	border-radius: ${borderRadiusDefault};
	padding: ${defaultSpacing};
	display: flex;
	align-items: center;
	border: 1px solid ${borderColorDefault};
    position: relative; 
    gap: ${defaultSpacing};
  

    ${({ $color }): cssHelperType => css`
		border-left: 5px solid ${$color};
	`}
}
`;

export const SRoundDecorator = styled(FuseFlex)`
	margin-left: ${halfSpacing};
`;

export const SContentWrapper = styled.div`
	padding-inline-end: ${defaultSpacing};
`;

export const SCloseIcon = styled(IoCloseOutline)`
	position: relative;
	cursor: pointer;
	inset-block-start: 0.125rem;
	inset-inline-end: 0.125rem;
	width: ${fontSizeH4};
	height: ${fontSizeH4};
	color: ${textColorSecondary};
	transition: color ${transitionDurationDefault} ease;

	:active,
	:hover {
		color: ${activeColorDefault};
	}
`;

export const SCloseIconWrapper = styled.div`
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
`;
