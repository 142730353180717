import { createContext } from 'react';
import { noop } from 'lodash-es';
import type { IFuseToastContext } from './types';
import { toastsByPositionInitialValue } from './constants';

export const ToastsContext = createContext<IFuseToastContext>({
	configs: null,
	options: null,
	toasts: {},
	addToast: noop,
	removeToast: noop,
	selectToastsByPosition: () => toastsByPositionInitialValue,
});
