import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import type { IFuseCardStylesProps } from './types';
import type { cssHelperType } from '../../../types';
import {
	backgroundColorDefault,
	borderDefault,
	borderRadiusDefault,
	borderWidthDefault,
	disabledColorDefault,
	errorColorDefault,
	primaryColorDefault,
	shadowDarker,
	transitionDurationDefault,
} from '../../../theme/selectors';
import { EThemeName } from '../../../theme/types';

export const Card = styled.div<IFuseCardStylesProps>`
	background-color: ${backgroundColorDefault};
	border-radius: ${borderRadiusDefault};
	border: ${borderDefault};
	overflow: hidden;

	${({ $isClickable, $isDisabled }): cssHelperType =>
		$isClickable &&
		!$isDisabled &&
		css`
			cursor: pointer;
			transition: box-shadow ${transitionDurationDefault};

			&:hover {
				box-shadow: ${shadowDarker};
			}
		`}

	${({ $isSelected, $isDisabled }): cssHelperType =>
		$isSelected &&
		css`
			border: ${borderWidthDefault} solid ${$isDisabled ? disabledColorDefault : primaryColorDefault};
		`}

  ${({ $hasError }): cssHelperType =>
		$hasError &&
		css`
			border: ${borderWidthDefault} solid ${errorColorDefault};
		`}

  ${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			cursor: not-allowed;
			background-color: ${({ theme }): Property.BackgroundColor =>
				theme.name === EThemeName.Light ? theme.colors.common.gray.gray3 : disabledColorDefault({ theme })};
		`}
`;
