import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
	backgroundColorDefault,
	borderColorDefault,
	borderColorFocused,
	borderRadiusDefault,
	borderWidthDefault,
	doubleSpacing,
	fontFamily,
	fontSizeBody1,
	inputPaddingBlock,
	inputPaddingInline,
	primaryColorDefault,
	textColorDefault,
	textColorSecondary,
	zIndexSelect,
} from '../../../theme/selectors';
import { ellipsisStyle } from '../../../theme/commonStyles';
import type { ISOptionProps, ISOptionsListProps, ISSelectInputAndIconsWrapperProps } from './types';
import type { cssHelperType } from '../../../types';
import { SInput, SInputAndIconsWrapper } from '../FuseInputBase';

const optionHeight = '2.5rem';

export const SSelectInputAndIconsWrapper = styled(SInputAndIconsWrapper)<ISSelectInputAndIconsWrapperProps>`
	${({ $disableBordersWhenClosed, $isOptionsListOpen }): cssHelperType =>
		$disableBordersWhenClosed &&
		!$isOptionsListOpen &&
		css`
			border-color: transparent;

			:focus-within {
				border-color: transparent;
			}
		`}

	${({ $optionsListPlacement, $isOptionsListOpen }): cssHelperType =>
		$isOptionsListOpen &&
		$optionsListPlacement === 'bottom' &&
		css`
			border-color: ${borderColorFocused};
			border-block-end: none;
			border-end-start-radius: 0;
			border-end-end-radius: 0;
		`}

  ${({ $optionsListPlacement, $isOptionsListOpen }): cssHelperType =>
		$isOptionsListOpen &&
		$optionsListPlacement === 'top' &&
		css`
			border-color: ${borderColorFocused};
			border-block-start: none;
			border-start-start-radius: 0;
			border-start-end-radius: 0;
		`}
`;

export const SSelectInput = styled(SInput)`
	text-overflow: ellipsis;

	:not(:first-child) {
		padding-inline-start: 0;
	}
`;

export const SOption = styled.li<ISOptionProps>`
	display: flex;
	align-items: center;
	gap: ${doubleSpacing};
	color: ${textColorDefault};
	background-color: ${backgroundColorDefault};
	padding-inline-start: ${inputPaddingInline};
	padding-inline-end: ${inputPaddingInline};
	height: ${optionHeight};
	box-sizing: border-box;
	cursor: pointer;

	:hover {
		color: ${primaryColorDefault};
	}

	${({ $isHighlighted }): cssHelperType =>
		$isHighlighted &&
		css`
			color: ${primaryColorDefault};
		`}
`;

export const SOptionLabel = styled.div`
	${ellipsisStyle};
	font-family: ${fontFamily};
	font-size: ${fontSizeBody1};
`;

export const SNoOptionsMessage = styled.li`
	color: ${textColorSecondary};
	font-family: ${fontFamily};
	font-size: ${fontSizeBody1};
	background-color: ${backgroundColorDefault};
	padding-inline-start: ${inputPaddingInline};
	padding-inline-end: ${inputPaddingInline};
	padding-block-start: ${inputPaddingBlock};
	padding-block-end: ${inputPaddingBlock};
	height: ${optionHeight};
	box-sizing: border-box;
	text-align: center;
`;

export const SOptionsList = styled.ul<ISOptionsListProps>`
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-y: auto;
	overflow-x: initial;
	list-style: none;
	border: ${borderWidthDefault} solid ${borderColorDefault};
	box-sizing: content-box;
	z-index: ${zIndexSelect};
	background-color: ${backgroundColorDefault};

	${({ $isOpen }): cssHelperType =>
		!$isOpen &&
		css`
			display: none;
		`}

	${({ $isOpen }): cssHelperType =>
		$isOpen &&
		css`
			border-color: ${borderColorFocused};
		`}
	
	${({ $optionsCount, $maxOptionsInView }): cssHelperType =>
		$optionsCount > 0 &&
		css`
			height: calc(${optionHeight} * ${Math.min($optionsCount, $maxOptionsInView)} + 0.4rem);
		`}

    ${({ $placement }): cssHelperType =>
		$placement === 'bottom' &&
		css`
			border-block-start: none;
			border-end-start-radius: ${borderRadiusDefault};
			border-end-end-radius: ${borderRadiusDefault};
		`}

    ${({ $placement }): cssHelperType =>
		$placement === 'top' &&
		css`
			border-block-end: none;
			border-start-start-radius: ${borderRadiusDefault};
			border-start-end-radius: ${borderRadiusDefault};
		`}
`;

export const SDivider = styled.hr`
	margin-inline-start: ${inputPaddingInline};
	margin-inline-end: ${inputPaddingInline};
	margin-block-start: 0;
	margin-block-end: 0;
	border: none;
	height: 1px;
	background-color: ${borderColorDefault};
`;

export const SClearIcon = styled(FaTimes)`
	cursor: pointer;
	width: 1.5rem;
	height: 1.5rem;
`;

const arrowIconStyles = css`
	&& {
		fill: ${textColorDefault};
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
	}
`;

export const SArrowUpIcon = styled(MdKeyboardArrowUp)`
	${arrowIconStyles}
`;

export const SArrowDownIcon = styled(MdKeyboardArrowDown)`
	${arrowIconStyles}
`;
