import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { fontSizeBody1, fontSizeBody2, fontSizeBody3 } from '../../../theme/selectors';
import { useAppTheme } from '../../../hooks/useAppTheme';
import { FuseLinkButtonBase } from '../../../components/internal/FuseLinkButtonBase/FuseLinkButtonBase';
import type { IFuseLinkButtonProps } from './types';

export const FuseLinkButton = forwardRef<HTMLButtonElement, PropsWithChildren<IFuseLinkButtonProps>>(
	({ buttonSize = 'md', ...otherProps }: PropsWithChildren<IFuseLinkButtonProps>, ref): JSX.Element => {
		const theme = useAppTheme();
		const fontSize = {
			lg: fontSizeBody1,
			md: fontSizeBody2,
			sm: fontSizeBody3,
		}[buttonSize]({ theme });

		return <FuseLinkButtonBase fontSize={fontSize} ref={ref} {...otherProps} />;
	}
);

FuseLinkButton.displayName = 'FuseLinkButton';
