import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import type { StylesMixin } from '../../../types';
import type { ISImageProps } from './types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';

const stylesMixin: StylesMixin = (props: ISImageProps) => css`
	width: ${props.$width};
	height: ${props.$height};
`;

export const SImage = styled.img<ISImageProps>`
	display: ${({ $isLoading }): Property.Display => ($isLoading ? 'none' : 'block')};
	${getResponsiveStyles(stylesMixin)};
`;
