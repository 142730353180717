import type { IFuseCommonProps } from '../../../types';

export enum EModalSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	ExtraLarge = 'extra-large',
}

export interface IFuseModalBaseProps extends Omit<IFuseCommonProps, 'data-test-id'> {
	isOpen: boolean;
	onClose: () => void;
	size?: EModalSize;
	fixedHeight?: boolean;
	disableCloseOnClickAway?: boolean;
	hideCloseButton?: boolean;
	showAsDrawer?: boolean;
	isFullScreen?: boolean;
	'data-test-id': string;
}

export interface ISModalProps {
	$isFixedHeight?: boolean;
	$size?: EModalSize;
	$isDrawer?: boolean;
	$isFullScreen?: boolean;
}
