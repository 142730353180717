import styled from 'styled-components';
import type { Property } from 'csstype';
import { fontFamily, shadowDefault, whiteColor } from '../../../theme/selectors';
import type { ISAvatarProps } from './types';

export const SAvatar = styled.div<ISAvatarProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ $color }): Property.BackgroundColor => $color}99;
	width: ${({ $size }): Property.Width => $size};
	height: ${({ $size }): Property.Height => $size};
	font-size: ${({ $fontSize }): Property.FontSize => $fontSize};
	color: ${whiteColor};
	font-family: ${fontFamily};
	text-transform: uppercase;
	box-shadow: ${shadowDefault};
	border-radius: 50%;
`;
