import styled from 'styled-components';
import type { Property } from 'csstype';
import { PulseLoader } from 'react-spinners';
import type { FuseLinkButtonColor, IFuseLinkButtonBaseStyleProps } from './types';
import {
	disabledColorDefault,
	errorColorDark,
	errorColorDefault,
	fontSizeBody2,
	fontWeightBold,
	fontWeightDefault,
	halfSpacing,
	infoColorDark,
	infoColorDefault,
	primaryColorDark,
	primaryColorDefault,
	secondaryColorDark,
	secondaryColorDefault,
	successColorDark,
	successColorDefault,
	textColorDefault,
	textColorWhite,
	warningColorDark,
	warningColorDefault,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';
import { hideInPrint } from '../../../theme/commonStyles';
import { FuseFlex } from '../../layout/FuseFlex';

const buttonColorStyles: Record<FuseLinkButtonColor, ThemeSelector<Property.Color>> = {
	success: successColorDefault,
	secondary: secondaryColorDefault,
	text: textColorDefault,
	primary: primaryColorDefault,
	info: infoColorDefault,
	error: errorColorDefault,
	warning: warningColorDefault,
};

const buttonActiveColorStyles: Record<FuseLinkButtonColor, ThemeSelector<Property.Color>> = {
	success: successColorDark,
	secondary: secondaryColorDark,
	text: textColorDefault,
	primary: primaryColorDark,
	info: infoColorDark,
	error: errorColorDark,
	warning: warningColorDark,
};

export const LinkButtonBase = styled.button<IFuseLinkButtonBaseStyleProps>`
	padding: 0;
	width: auto;
	border: none;
	display: inline-flex;
	background-color: transparent;
	gap: ${halfSpacing};
	justify-content: center;
	position: relative;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	flex-direction: ${({ iconPlacement }): Property.FlexDirection => (iconPlacement === 'start' ? 'row' : 'row-reverse')};
	text-transform: ${({ uppercase }): Property.TextTransform => (uppercase ? 'uppercase' : 'none')};
	font-weight: ${(props): Property.FontWeight => (props.bold ? fontWeightBold(props) : fontWeightDefault(props))};
	color: ${({ color, theme }): Property.Color => buttonColorStyles[color]({ theme })};
	font-size: ${({ fontSize, theme }): Property.FontSize => fontSize ?? fontSizeBody2({ theme })};

	:active,
	:hover {
		color: ${({ color, theme }): Property.Color => buttonActiveColorStyles[color]({ theme })};
	}

	${hideInPrint};

	&:disabled {
		color: ${({ theme, color, isLoading }): Property.Color => (isLoading ? buttonColorStyles[color]({ theme }) : disabledColorDefault({ theme }))};
		opacity: ${({ isLoading }): Property.Opacity => (isLoading ? '0.6' : '1')};
		cursor: not-allowed;
	}
`;

export const SBadge = styled(FuseFlex).attrs(() => ({
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
}))`
	background: ${primaryColorDefault};
	color: ${textColorWhite};
	width: 1rem;
	height: 1rem;
	border-radius: 10rem;
`;

export const SLoading = styled(PulseLoader).attrs((props) => ({
	size: 10,
	color: buttonColorStyles[props.color](props),
}))``;
