import type { ReactDatePickerProps } from 'react-datepicker';
import type { IFuseInputBaseProps } from '../../internal/FuseInputBase';
import type { IFuseCommonProps } from '../../../types';

export interface IFuseDatePickerProps
	extends Omit<ReactDatePickerProps<any>, 'value' | 'onSelect'>,
		Omit<IFuseInputBaseProps, 'onChange' | 'onKeyDown' | 'onSelect' | 'value' | 'height'>,
		Omit<IFuseCommonProps, 'data-test-id'> {
	value: Date;
	onChange: (value: Date) => void;
	isMonthPicker?: boolean;
	maxDate?: Date;
	minDate?: Date;
	openToDate?: Date;
	isMasked?: boolean;
	['data-test-id']: string;
}

export interface ISCalendarWrapperProps {
	$isMonthPicker?: boolean;
}

export interface IMonthIconProps {
	$isDisabled?: boolean;
}

export interface ISMonthYearSelectsWrapperProps {
	$isMonthPicker?: boolean;
}

export enum EDateFormat {
	Month = 'MM/yyyy',
	CleanMonth = 'MMyyyy',
	MonthEng = 'MMM yyyy',
	FullDate = 'MM/dd/yyyy',
	CleanFullDate = 'MMddyyyy',
	FullDateWithMonthName = 'MMM dd, yyyy',
	ShortTimeFormat = 'HH:MM',
}
