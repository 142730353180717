import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import type { ISignaturesState, ISignatureFieldValue } from './types';

const initialState: ISignaturesState = {
	buyerSignature: null,
	coBuyerSignature: null,
};

export const {
	reducer,
	actions: { setSignatures, setBuyerSignature, setCoBuyerSignature },
} = createSlice({
	name: 'signatures',
	initialState,
	reducers: {
		setSignatures: (state, action: PayloadAction<{ buyerSignature: ISignatureFieldValue; coBuyerSignature: ISignatureFieldValue }>): void => {
			const { buyerSignature, coBuyerSignature } = action.payload;

			state.buyerSignature = cloneDeep(buyerSignature);
			state.coBuyerSignature = cloneDeep(coBuyerSignature);
		},

		setBuyerSignature: (state, action: PayloadAction<{ sig: ISignatureFieldValue }>): void => {
			const { sig } = action.payload;

			state.buyerSignature = cloneDeep(sig);
		},

		setCoBuyerSignature: (state, action: PayloadAction<{ sig: ISignatureFieldValue }>): void => {
			const { sig } = action.payload;

			state.coBuyerSignature = cloneDeep(sig);
		},
	},
});
