import type { Property } from 'csstype';
import type { ThemeSelector } from '../types';

export const fontFamily: ThemeSelector<Property.FontFamily> = ({ theme }) => theme.fontFamily;

export const fontSizeH1: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.h1;
export const fontSizeH2: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.h2;
export const fontSizeH3: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.h3;
export const fontSizeH4: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.h4;
export const fontSizeBody1: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.body1;
export const fontSizeBody2: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.body2;
export const fontSizeBody3: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.body3;
export const fontSizeBody4: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.body4;
export const fontSizeDisclaimer: ThemeSelector<Property.FontSize> = ({ theme }) => theme.fontSize.disclaimer;

export const fontWeightDefault: ThemeSelector<Property.FontWeight> = ({ theme }) => theme.fontWeight.default;
export const fontWeightMd: ThemeSelector<Property.FontWeight> = ({ theme }) => theme.fontWeight.md;
export const fontWeightBold: ThemeSelector<Property.FontWeight> = ({ theme }) => theme.fontWeight.bold;
