import styled from 'styled-components';
import type { Property } from 'csstype';
import { FaPencilAlt } from 'react-icons/fa';
import {
	activeColorDefault,
	borderColorDefault,
	borderWidthDefault,
	borderWidthDouble,
	fontSizeBody4,
	primaryColorDefault,
	warningColorContrastText,
} from '../../../../theme/selectors';
import { mediaQueries } from '../../../../utils/mediaQueries';
import { FuseTypography } from '../../FuseTypography';

export const canvasHeightInPixels = 200;

export const SCanvasWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-height: ${canvasHeightInPixels}px;
	border: ${borderWidthDouble} dashed ${borderColorDefault};
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	background-color: #fff;

	.signature-canvas {
		width: 100%;
		height: 100%;
	}
`;

export const SCanvasHelperWrapper = styled.div`
	position: fixed;
	opacity: 0;
	z-index: -1;
	pointer-events: none;
	width: 100%;
	height: 100%;
	max-height: ${canvasHeightInPixels}px;

	.signature-canvas-helper {
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: -1;
	}
`;

export const SAddSignatureBox = styled.button`
	width: 100%;
	height: 100%;
	border: ${borderWidthDefault} dashed ${borderColorDefault};
	background-color: ${({ theme }): Property.BackgroundColor => theme.colors.common.secondary.yellow};
	cursor: pointer;
`;

export const SAddSignatureText = styled(FuseTypography)`
	color: ${warningColorContrastText};
	${mediaQueries.mobileAndBelow} {
		font-size: ${fontSizeBody4};
	}
`;

export const SSignatureImage = styled.img.attrs(() => ({
	alt: 'signature',
}))`
	object-fit: contain;
	width: 100%;
	height: 100%;
`;

export const SEditIcon = styled(FaPencilAlt)`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	fill: ${primaryColorDefault};
	cursor: pointer;

	:hover {
		fill: ${activeColorDefault};
	}
`;

export const STitle = styled(FuseTypography)`
	margin: 0;
`;
