import type { IFuseCommonProps, IFuseDataTestProps } from '../../../types';

export interface IFuseDrawerProps extends IFuseCommonProps, Omit<IFuseDataTestProps, 'data-test-id'> {
	isOpen: boolean;
	onClose: () => void;
	onCloseCompleted?: () => void;
	hideCloseButton?: boolean;
	disableCloseOnClickAway?: boolean;
	disableBackdrop?: boolean;
	placement?: EFuseDrawerPlacement;
	['data-test-id']: string;
}

export enum EFuseDrawerPlacement {
	Right = 'right',
	Left = 'left',
	Top = 'top',
	Bottom = 'bottom',
}

export interface ISDrawerProps {
	$placement: EFuseDrawerPlacement;
}
