import type { PropsWithChildren } from 'react';
import React from 'react';
import { clsx } from 'clsx';
import type { IFuseCardProps } from './types';
import { Card } from './FuseCard.styles';

export const FuseCard = ({
	dangerouslySetClassName,
	children,
	onClick,
	disabled = false,
	isSelected = false,
	hasError = false,
	isLoading,
	'data-test-id': testId,
	...otherProps
}: PropsWithChildren<IFuseCardProps>): JSX.Element => (
	<Card
		className={clsx(dangerouslySetClassName)}
		onClick={(): void => (disabled ? null : onClick?.())}
		$isClickable={!!onClick}
		$isSelected={isSelected}
		$isDisabled={disabled}
		$hasError={hasError}
		data-test-id={`${testId}-card`}
		data-test-loading={isLoading}
		{...otherProps}
	>
		{children}
	</Card>
);
