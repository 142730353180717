import React, { forwardRef } from 'react';
import type { IFuseAvatarBaseProps } from './types';
import { SAvatar } from './FuseAvatarBase.styles';
import { getInitials } from './utils';

export const FuseAvatarBase = forwardRef<HTMLDivElement, IFuseAvatarBaseProps>(
	({ fullName, color, size, fontSize, 'data-test-id': testId, ...otherProps }: IFuseAvatarBaseProps, ref): JSX.Element => (
		<SAvatar data-test-id={`${testId}-avatar`} data-test-value={fullName} ref={ref} $color={color} $size={size} $fontSize={fontSize} {...otherProps}>
			{getInitials(fullName)}
		</SAvatar>
	)
);

FuseAvatarBase.displayName = 'FuseAvatarBase';
