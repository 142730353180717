import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import type { IFuseFlexItemProps } from './types';
import { FlexItem } from './FuseFlexItem.styles';

export const FuseFlexItem = forwardRef<HTMLDivElement, PropsWithChildren<IFuseFlexItemProps>>(
	(
		{
			as = 'div',
			dangerouslySetClassName,
			basis = 'auto',
			grow = 1,
			shrink = 0,
			alignSelf,
			children,
			...otherProps
		}: PropsWithChildren<IFuseFlexItemProps>,
		ref
	): JSX.Element => (
		<FlexItem
			ref={ref}
			className={clsx(dangerouslySetClassName)}
			as={as}
			$basis={basis}
			$grow={grow}
			$shrink={shrink}
			$alignSelf={alignSelf}
			{...otherProps}
		>
			{children}
		</FlexItem>
	)
);

FuseFlexItem.displayName = 'FuseFlexItem';
