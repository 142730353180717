import type { Property } from 'csstype';
import type { InputHTMLAttributes, ReactNode } from 'react';
import type { IFuseCommonProps } from '../../../types';

export interface IFuseTextAreaBaseProps
	extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'required' | 'disabled'>,
		Omit<IFuseCommonProps, 'as' | 'data-test-id'> {
	onChange?: (value: string) => void;
	error?: string;
	tooltipContent?: ReactNode;
	label?: string;
	labelType?: EFuseTextAreaLabelType;
	isRequired?: boolean;
	isDisabled?: boolean;
	borderColor?: Property.BorderColor;
	fontSize?: Property.FontSize;
	['data-test-id']: string;
}

export enum EFuseTextAreaLabelType {
	Primary = 'primary',
	Secondary = 'secondary',
}

export interface ISTextAreaProps {
	$hasError?: boolean;
	$borderColor?: Property.BorderColor;
	$fontSize?: Property.FontSize;
}

export interface ISLabelProps {
	$type?: EFuseTextAreaLabelType;
	$isRequired?: boolean;
	$isDisabled?: boolean;
}
