import type { PropsWithChildren } from 'react';
import React from 'react';
import { OverlaysContainer } from './OverlaysContainer/OverlaysContainer';

export const OverlaysProvider = ({ children }: PropsWithChildren): JSX.Element => (
	<>
		{children}
		<OverlaysContainer />
	</>
);
