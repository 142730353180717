import type { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query';
import { selectUserSelectedDealershipId, selectUserToken } from '@transact-client/store/selectors/auth.selectors';
import { selectSessionId, selectSessionURL } from '@transact-client/store/selectors/session.selectors';
import { v4 as uuid } from 'uuid';
import { convertObjectToURLSearchParams } from '@transact-client/store/api/utils';
import { retryExcludedUrls } from '@transact-client/store/api/constants';
import { isNumber } from 'lodash-es';
import { StatusCodes } from 'http-status-codes';

export const fetchBaseQueryWithRetry = retry<
	BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta & { isAboutToBeInvalidated?: boolean }>
>(
	async (args: string | FetchArgs, api, extraOptions) => {
		const queryFn = fetchBaseQuery({
			baseUrl: `${process.env.ENDPOINT}/api`,
			prepareHeaders: (headers, { getState }) => {
				const state = getState() as any;

				headers.set('Authorization', selectUserToken(state));
				headers.set('Dealership', selectUserSelectedDealershipId(state)?.toString());
				headers.set('SessionURL', selectSessionURL(state));
				headers.set('session-id', selectSessionId(state));
				headers.set('Fuse-Request-ID', uuid());

				return headers;
			},
			paramsSerializer: (params) => {
				if (!params) {
					return '';
				}

				return convertObjectToURLSearchParams(params).toString();
			},
		});

		const result = await queryFn(args, api, extraOptions);
		const { status } = result.error ?? {};

		const hasError = !!result.error;
		const isExcludedUrl = !!retryExcludedUrls.find((path) => (args as FetchArgs)?.url?.includes(path));
		const shouldRetry =
			(status === 'FETCH_ERROR' || (isNumber(status) && (status === StatusCodes.REQUEST_TIMEOUT || status >= StatusCodes.NOT_IMPLEMENTED))) &&
			!isExcludedUrl;

		if (hasError && process.env.REACT_APP_ENVIRONMENT === 'test') {
			// eslint-disable-next-line no-console
			console.log(`Client RTK Query had error. Response:`, JSON.stringify(result));
		}

		if (hasError && !shouldRetry) {
			retry.fail(result.error);
		}

		return result;
	},
	{ maxRetries: 3 }
);
