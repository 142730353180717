import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import {
	backgroundColorSecondary,
	borderRadiusFull,
	borderWidthDefault,
	disabledColorDefault,
	disabledColorLight,
	doubleSpacing,
	errorColorContrastText,
	errorColorDefault,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody4,
	fontSizeH2,
	fontSizeH4,
	infoColorContrastText,
	infoColorDefault,
	primaryColorContrastText,
	primaryColorDefault,
	successColorContrastText,
	successColorDefault,
	textColorDefault,
	textColorSecondary,
	warningColorDefault,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';
import type { cssHelperType } from '../../../types';
import type { EFuseIconButtonColor, ISButtonWrapperProps } from './types';
import type { FuseIconSize } from '../../dataDisplay/FuseIcon/types';

const sizeStyles: Record<FuseIconSize, cssHelperType> = {
	xl: css`
		width: calc(${fontSizeH2} + ${doubleSpacing});
		height: calc(${fontSizeH2} + ${doubleSpacing});

		svg {
			width: ${fontSizeH2};
			height: ${fontSizeH2};
		}
	`,
	lg: css`
		width: calc(${fontSizeH4} + ${doubleSpacing});
		height: calc(${fontSizeH4} + ${doubleSpacing});

		svg {
			width: ${fontSizeH4};
			height: ${fontSizeH4};
		}
	`,
	md: css`
		width: calc(${fontSizeBody2} + ${doubleSpacing});
		height: calc(${fontSizeBody2} + ${doubleSpacing});

		svg {
			width: ${fontSizeBody1};
			height: ${fontSizeBody1};
		}
	`,
	sm: css`
		width: calc(${fontSizeBody4} + ${doubleSpacing});
		height: calc(${fontSizeBody4} + ${doubleSpacing});

		svg {
			width: ${fontSizeBody2};
			height: ${fontSizeBody2};
		}
	`,
};

const buttonColorStyles: Record<EFuseIconButtonColor, ThemeSelector<Property.Color>> = {
	primary: primaryColorDefault,
	secondary: textColorSecondary,
	text: textColorDefault,
	error: errorColorDefault,
	warning: warningColorDefault,
	info: infoColorDefault,
	success: successColorDefault,
};

const buttonContrastColorStyles: Record<EFuseIconButtonColor, ThemeSelector<Property.Color>> = {
	primary: primaryColorContrastText,
	secondary: primaryColorContrastText,
	text: primaryColorContrastText,
	error: errorColorContrastText,
	warning: primaryColorContrastText,
	info: infoColorContrastText,
	success: successColorContrastText,
};

export const SButtonWrapper = styled.button<ISButtonWrapperProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: ${borderWidthDefault} solid transparent;
	border-radius: ${borderRadiusFull};
	box-shadow: none;
	cursor: pointer;
	${(props): cssHelperType => sizeStyles[props.$size]};

	${({ $isCircle, $color }): cssHelperType =>
		$isCircle
			? css`
					background-color: ${buttonColorStyles[$color]};
					color: ${buttonContrastColorStyles[$color]};

					:active,
					:hover {
						background-color: ${buttonContrastColorStyles[$color]};
						color: ${buttonColorStyles[$color]};
					}

					:disabled {
						color: ${disabledColorLight};
					}
				`
			: css`
					background-color: transparent;
					color: ${buttonColorStyles[$color]};

					:active,
					:hover {
						background-color: ${backgroundColorSecondary};
						color: ${buttonColorStyles[$color]};
					}

					:disabled {
						color: ${disabledColorDefault};
					}
				`}

	:disabled {
		cursor: not-allowed;
	}

	svg {
		z-index: 1;
	}
`;
