import type { Property } from 'csstype';
import type { ThemeSelector } from '../types';

export const primaryColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.primary.default;
export const primaryColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.primary.light;
export const primaryColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.primary.dark;
export const primaryColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.primary.contrastText;
export const primaryColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.primary.background;

// Main
export const blueColor: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.main['blue'];
export const whiteColor: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.main['white'];
export const rippleColor: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.secondary['darkTurquoise'];
export const darkGrayColor: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.main['darkGray'];
export const midGrayColor: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.main['midGray'];

// Gray
export const grayColor1: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['gray1'];
export const grayColor2: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['gray2'];
export const grayColor3: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['gray3'];
export const grayColor4: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['gray4'];
export const colorTransparentGray: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['transparentGray'];
export const colorTransparentMidGray: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray['transparentMidGray'];

// Secondary
export const secondaryColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.secondary.default;
export const secondaryColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.secondary.light;
export const secondaryColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.secondary.dark;
export const secondaryColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.secondary.contrastText;
export const secondaryColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.secondary.background;

export const activeColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.active.default;
export const activeColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.active.light;
export const activeColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.active.dark;
export const activeColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.active.contrastText;
export const activeColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.active.background;

export const successColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.success.default;
export const successColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.success.light;
export const successColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.success.dark;
export const successColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.success.contrastText;
export const successColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.success.background;

export const warningColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.warning.default;
export const warningColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.warning.light;
export const warningColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.warning.dark;
export const warningColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.warning.contrastText;
export const warningColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.warning.background;

export const infoColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.info.default;
export const infoColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.info.light;
export const infoColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.info.dark;
export const infoColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.info.contrastText;
export const infoColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.info.background;

export const errorColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.error.default;
export const errorColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.error.light;
export const errorColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.error.dark;
export const errorColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.error.contrastText;
export const errorColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.error.background;

export const disabledColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.disabled.default;
export const disabledColorLight: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.disabled.light;
export const disabledColorDark: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.disabled.dark;
export const disabledColorContrastText: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.disabled.contrastText;
export const disabledColorBackground: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.secondary;

export const backgroundColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.default;
export const backgroundColorSecondary: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.secondary;
export const backgroundColorBackdrop: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.backdrop;
export const backgroundColorContrast: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.contrast;
export const backgroundColorContrastSecondary: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.background.contrastSecondary;

export const textColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.text.default;
export const textColorDisabled: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.common.gray.gray1;
export const textColorSecondary: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.text.secondary;
export const textColorWhite: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.text.white;

export const borderColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.border.default;
export const borderColorFocused: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.border.focused;
export const borderColorSelected: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.border.selected;

export const separatorColorDefault: ThemeSelector<Property.Color> = ({ theme }) => theme.colors.separator.default;
