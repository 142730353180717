import { EBreakpoint, FuseFlex, FuseImage, FuseTypography, useActiveBreakpoint } from '@fuse/react-ui';
import React from 'react';
import { SHeadersWrapper, SLink, SWrapper } from './BrowserIncompatibilityError.styles';
import { supportedBrowsers } from '../constants';

export const BrowserIncompatibilityError = (): JSX.Element => {
	const { activeBreakpoint } = useActiveBreakpoint();
	const isMobile = activeBreakpoint === EBreakpoint.Mobile;

	return (
		<SWrapper direction="column" width="100%" justifyContent="space-between" alignItems="center">
			<FuseImage src="images/fuseLogo.svg" height={isMobile ? '3rem' : '4rem'} />

			<SHeadersWrapper direction="column" gap="2rem" alignItems="center">
				<FuseTypography variant={isMobile ? 'h3' : 'h1'}>
					It looks like your current
					<br /> browser is not supported.
				</FuseTypography>

				<FuseTypography variant="b1">
					To ensure the best experience on our platform,
					<br />
					we recommend using a compatible browser.
				</FuseTypography>

				<FuseTypography variant="b1">Browsers:</FuseTypography>

				<FuseFlex gap={isMobile ? '3rem' : '4rem'} wrap="wrap" justifyContent="center">
					{supportedBrowsers.map(({ logoUrl, name, version }) => (
						<FuseFlex key={name} direction="column" gap="1rem" alignItems="center">
							<FuseImage src={logoUrl} height="2rem" width="2rem" />

							<FuseTypography variant="b3" color="secondary">
								{name} {version}+
							</FuseTypography>
						</FuseFlex>
					))}
				</FuseFlex>
			</SHeadersWrapper>

			<FuseFlex gap="0.25rem">
				<FuseTypography variant="b2" color="secondary">
					Questions?
				</FuseTypography>

				<FuseTypography variant="b2" color="secondary">
					<SLink href="mailto:support@fuseautotech.com">support@fuseautotech.com</SLink>
				</FuseTypography>
			</FuseFlex>
		</SWrapper>
	);
};
