import styled, { css } from 'styled-components';
import { MdError } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import type { Property } from 'csstype';
import {
	backgroundColorDefault,
	borderColorDefault,
	borderColorFocused,
	borderRadiusDefault,
	disabledColorDefault,
	doubleSpacing,
	errorColorDefault,
	fontFamily,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody3,
	halfSpacing,
	textColorDefault,
	textColorSecondary,
} from '../../../theme/selectors';
import type { cssHelperType } from '../../../types';
import type { ISLabelProps, ISTextAreaProps } from './types';
import { EFuseTextAreaLabelType } from './types';

export const SWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${halfSpacing};
	width: 100%;
	height: 100%;
`;

export const STextArea = styled.textarea<ISTextAreaProps>`
	padding: ${doubleSpacing};
	font-size: ${({ $fontSize, theme }): Property.FontSize => $fontSize ?? fontSizeBody1({ theme })};
	font-family: ${fontFamily};
	line-height: ${({ $fontSize, theme }): Property.LineHeight => $fontSize ?? fontSizeBody1({ theme })};
	border: 1px solid ${({ $borderColor, theme }): Property.BorderColor => $borderColor ?? borderColorDefault({ theme })};
	color: ${textColorDefault};
	border-radius: ${borderRadiusDefault};
	text-align: start;
	resize: none;
	outline: none;
	flex-grow: 1;
	background-color: ${backgroundColorDefault};

	::placeholder {
		color: ${textColorSecondary};
		font-style: italic;
	}

	:focus-within {
		border-color: ${borderColorFocused};
	}

	:disabled {
		background-color: ${disabledColorDefault};
		cursor: not-allowed;
	}

	${({ $hasError }): cssHelperType =>
		$hasError &&
		css`
			border-color: ${errorColorDefault};
		`}
`;

export const SErrorMessage = styled.span`
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	color: ${errorColorDefault};
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const SErrorWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.1rem;
`;

export const SErrorIcon = styled(MdError)`
	fill: ${errorColorDefault};
	width: ${fontSizeBody2};
	height: ${fontSizeBody2};
	align-self: flex-start;
	flex-shrink: 0;
`;

export const SLabel = styled.label<ISLabelProps>`
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	font-weight: normal;
	text-transform: uppercase;

	${({ $type }): cssHelperType =>
		$type === EFuseTextAreaLabelType.Primary &&
		css`
			color: ${textColorDefault};
		`}

	${({ $type }): cssHelperType =>
		$type === EFuseTextAreaLabelType.Secondary &&
		css`
			color: ${textColorSecondary};
		`}

  ${({ $isRequired }): cssHelperType =>
		$isRequired &&
		css`
			::after {
				content: '   *';
				color: ${errorColorDefault};
			}
		`}
`;

export const STooltipIcon = styled(FaInfoCircle)`
	fill: ${textColorDefault};
	width: ${fontSizeBody1};
	height: ${fontSizeBody1};
`;

export const SLabelAndTooltipWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
