import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IFilter } from '@transact-client/types/Filter.type';
import { EFilterName, filterDefinitions } from '@transact-client/types/Filter.type';
import type { ILeadFilterState } from './types';

const initialState: ILeadFilterState = {
	availableFilterNames: [EFilterName.Status, EFilterName.DealType, EFilterName.CreationDate],
	selectedFilters: [{ name: null, type: null, value: [] }],
	searchQuery: null,
};

export const {
	reducer,
	actions: { addFilter, setFilterName, setFilterValue, setQueryFilter, removeFilter },
} = createSlice({
	name: 'leads/filters',
	initialState,
	reducers: {
		addFilter: (state, action: PayloadAction<{ name?: IFilter['name'] }>): void => {
			const { name } = action.payload;

			if (name) {
				const { type, defaultValue } = filterDefinitions[name];

				state.selectedFilters.push({ name, type, value: defaultValue });
				state.availableFilterNames = state.availableFilterNames.filter((filterName) => filterName !== name);
			} else {
				state.selectedFilters.push({ name: null, type: null, value: null });
			}
		},

		setFilterName: (state, action: PayloadAction<{ name: IFilter['name']; index: number }>): void => {
			const { name, index } = action.payload;

			const oldName = state.selectedFilters?.[index]?.name;
			const { type, defaultValue } = filterDefinitions[name];

			state.availableFilterNames = state.availableFilterNames.filter((filterName) => filterName !== name);
			state.selectedFilters[index] = { name, type, value: defaultValue };

			// If we replaced a previous filter type, return it to the available types
			if (oldName) {
				state.availableFilterNames.push(oldName);
			}
		},

		setFilterValue: (state, action: PayloadAction<{ value: IFilter['value']; index: number }>): void => {
			const { value, index } = action.payload;

			state.selectedFilters[index].value = value;
		},

		setQueryFilter: (state, action: PayloadAction<string>): void => {
			state.searchQuery = action.payload;
		},

		removeFilter: (state, action: PayloadAction<{ index: number }>): void => {
			const { index } = action.payload;

			const { name: removedFilterName } = state.selectedFilters[index];

			if (!state.availableFilterNames.includes(removedFilterName) && removedFilterName) {
				state.availableFilterNames.push(removedFilterName);
			}

			if (state.selectedFilters.length === 1) {
				state.selectedFilters = initialState.selectedFilters;
			} else {
				state.selectedFilters.splice(index, 1);
			}
		},
	},
});
