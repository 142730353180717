import { useEffect } from 'react';

export const useOnClickOutside = (element: HTMLElement, handler: () => void, ignoredElements: HTMLElement[] = []): void => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent): void => {
			if (!element || element?.contains(event.target as Node) || ignoredElements.some((e) => e?.contains(event.target as Node))) {
				return;
			}

			handler();
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return (): void => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [element, handler, ignoredElements]);
};
