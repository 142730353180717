import { type BaseQueryFn, type MutationDefinition } from '@reduxjs/toolkit/query';

export enum EHttpMethod {
	Get = 'GET',
	Post = 'POST',
	Patch = 'PATCH',
	Put = 'PUT',
	Delete = 'DELETE',
}

export enum ETagType {
	Lead = 'Lead',
	Deal = 'Deal',
	DealDashboardItem = 'DealDashboardItem',
	DealCustomer = 'DealCustomer',
	DealErrors = 'DealErrors',
	DeskingSession = 'DeskingSession',
	DealDocument = 'DealDocument',

	DealDocumentAllExtraDocuments = 'DealDocumentAllExtraDocuments',
	DealDocumentAllDisclosureDocuments = 'DealDocumentAllDisclosureDocuments',
	DealDocumentAllCreditReportDocuments = 'DealDocumentAllCreditReportDocuments',
	DealDocumentAllDealershipContracts = 'DealDocumentAllDealershipContracts',
	DealDocumentAllProtectionProductsContracts = 'DealDocumentAllProtectionProductsContracts',
	DealDocumentAllLenderContracts = 'DealDocumentAllLenderContracts',

	DealDocumentsStatus = 'DealDocumentsStatus',
	DealDisclosuresDocumentsStatus = 'DealDisclosuresDocumentsStatus',
	DealDocumentsSignedAt = 'DealDocumentsSignedAt',
	DealOffer = 'DealOffer',
	Dealership = 'Dealership',
	DealershipConfig = 'DealershipConfig',
	DealershipTemplate = 'DealershipTemplate',
	DealershipTemplateFile = 'DealershipTemplateFile',
	ExportDealershipTemplate = 'ExportDealershipTemplate',
	DealershipSignature = 'DealershipSignature',
	LicensePlate = 'LicensePlate',
	Vehicles = 'Vehicles',
	Vehicle = 'Vehicle',
	VehicleFilter = 'VehicleFilter',
	Users = 'Users',
	Roles = 'Roles',
	CustomerSignature = 'CustomerSignature',
	Lenders = 'Lenders',
	Feeds = 'Feeds',
	Usernames = 'Usernames',
	ProtectionProduct = 'ProtectionProduct',
	ProtectionProductsCategory = 'ProtectionProductsCategory',
	ProtectionProductDmsConfig = 'ProtectionProductDmsConfig',
	CreditReport = 'CreditReport',
	DealDirtyForms = 'DealDirtyForms',
	DealTradeIns = 'DealTradeIns',
	LowestRate = 'LowestRate',
	DealProcess = 'DealProcesses',
	Rate = 'Rate',
	SimpleRate = 'SimpleRate',
	DealRate = 'DealRate',
	DealEditedRate = 'DealEditedRate',
	DealRebates = 'DealRebates',
	DealInsurance = 'DealInsurance',
	DealAdditionalVehicleInfo = 'DealAdditionalVehicleInfo',
	DealOsl = 'DealOsl',
	DealEditedStructure = 'DealEditedStructure',
	DealAccessories = 'DealAccessories',
	LienHolders = 'LienHolders',
	DealProtectionProduct = 'DealProtectionProduct',
	DealProtectionProductSelected = 'DealProtectionProductSelected',
	DealProtectionProductUnSelected = 'DealProtectionProductUnSelected',
	DealProtectionProductEditedPrice = 'DealProtectionProductEditedPrice',
	DealBundle = 'DealBundle',
	DealDecision = 'DealDecision',
	DealLease = 'DealLease',
	DealFinance = 'DealFinance',
	DealManualCalculator = 'DealManualCalculator',
	DealSettings = 'DealSettings',
	Rebates = 'Rebates',
	Notification = 'Notification',
	BiData = 'BiData',
	Unauthorized = 'UNAUTHORIZED',
	CsChat = 'CsChat',
	DealTaxes = 'DealTaxes',
}

export enum ETagId {
	All = 'ALL',
	AllSelectedDealProtectionProducts = 'ALL_SELECTED_DEAL_PROTECTION_PRODUCTS',
	AllUnselectedDealProtectionProducts = 'ALL_UNSELECTED_DEAL_PROTECTION_PRODUCTS',
}

export interface ITag {
	type: ETagType;
	id: ETagId | string | number;
}

export type OptimisticUpdate<Response, Request> = MutationDefinition<Request, BaseQueryFn, ETagType, Response>['onQueryStarted'];

export interface IPagination {
	offset?: number;
	total?: number;
	limit?: number;
}
