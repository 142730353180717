import styled, { css } from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';
import type { IFuseChartWrapperStyleProps } from './types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';
import { FuseCard } from '../../dataDisplay/FuseCard/FuseCard';
import { FuseFlex } from '../../layout/FuseFlex';
import { doubleSpacing, fontSizeBody1, halfSpacing, textColorDefault } from '../../../theme/selectors';

export const SChartCardWrapper = styled(FuseCard)<IFuseChartWrapperStyleProps>`
	max-width: 100%;

	${getResponsiveStyles(
		({ $width, $height }) => css`
			width: ${$width};
		  height: ${$height}};
		`
	)}
`;

export const STooltipIcon = styled(FaInfoCircle)`
	fill: ${textColorDefault};
	width: ${fontSizeBody1};
	height: ${fontSizeBody1};
`;

export const STitleMainWrapper = styled(FuseFlex)`
	padding: ${doubleSpacing};
	justify-content: space-between;
`;

export const STitleWrapper = styled(FuseFlex)`
	gap: ${halfSpacing};
	align-items: center;
`;
