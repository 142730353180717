import { $enum } from 'ts-enum-util';
import { camelCase } from 'change-case';
import type { MediaQueries } from '../types';
import { EBreakpoint } from '../types';

const breakpoints = $enum(EBreakpoint);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const mediaQueries: MediaQueries = breakpoints.getEntries().reduce((result, [key, value]) => {
	const breakpointName = camelCase(key);
	const index = breakpoints.indexOfKey(key);
	const isFirst = index === 0;
	const isLast = index === breakpoints.length - 1;
	const nextBreakpoint = isLast ? null : `calc(${breakpoints[index + 1][1]} - 0.05px)`;

	if (isFirst) {
		return {
			...result,
			[breakpointName]: `@media screen and (max-width: ${nextBreakpoint})`,
			[`${breakpointName}AndBelow`]: `@media screen and (max-width: ${nextBreakpoint})`,
			[`${breakpointName}AndAbove`]: `@media screen and (min-width: 0px)`,
		};
	}

	if (isLast) {
		return {
			...result,
			[breakpointName]: `@media screen and (min-width: ${value})`,
			[`${breakpointName}AndBelow`]: `@media screen and (min-width: 0px)`,
			[`${breakpointName}AndAbove`]: `@media screen and (min-width: ${value})`,
		};
	}

	return {
		...result,
		[breakpointName]: `@media screen and (min-width: ${value}) and (max-width: ${nextBreakpoint})`,
		[`${breakpointName}AndBelow`]: `@media screen and (max-width: ${nextBreakpoint})`,
		[`${breakpointName}AndAbove`]: `@media screen and (min-width: ${value})`,
	};
}, {});
