import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IThemeState } from '@transact-client/store/slices/theme/types';
import { EThemeName } from '@fuse/react-ui';

const initialState: IThemeState = {
	// Check if the user has a system preference for dark mode
	type: window.matchMedia('(prefers-color-scheme: dark)').matches ? EThemeName.Dark : EThemeName.Light,
};

export const {
	reducer,
	actions: { setTheme },
} = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (state, action: PayloadAction<EThemeName>): void => {
			state.type = action.payload;
		},
	},
});
