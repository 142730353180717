import type { PropsWithChildren, ReactElement } from 'react';
import React, { Children, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import * as pdfjs from 'pdfjs-dist';
import { isNil, isObject, map } from 'lodash-es';
import type { IFusePdfPage, IFusePdfProps } from './types';
import { SDocumentWrapper } from './FusePdf.styles';
import { convertPdfToImages } from './utils';
import { FusePdfPage } from './FusePdfPage';
import { FusePdfSkeleton } from './FusePdf.skeleton';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const FusePdf = forwardRef<HTMLDivElement, PropsWithChildren<IFusePdfProps>>(
	(
		{
			pdfBase64,
			pages: pdfPages,
			errorComponent = null,
			showBorder = false,
			disableImageCaching = false,
			children,
			'data-test-id': testId,
			isLoadingData,
			...otherProps
		}: PropsWithChildren<IFusePdfProps>,
		ref
	): JSX.Element => {
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [hasError, setHasError] = useState<boolean>(false);
		const [pages, setPages] = useState<IFusePdfPage[]>([]);

		const overlayItemsByPage = useMemo(
			() =>
				// TODO Need to re-implement this in a way that allows non-overlay children to be rendered, and also not look in the children's props
				Children.toArray(children).reduce(
					(result, child: ReactElement) => {
						const page = child?.props?.field?.page ?? child?.props?.page;

						if (isObject(child) && 'props' in child && result[page - 1]) {
							result[page - 1].push(child);
						}

						return result;
					},
					Array.from({ length: pages.length }, () => [])
				),
			[children, pages.length]
		);

		const parsePdf = useCallback(async (): Promise<void> => {
			try {
				setIsLoading(true);

				if (!isNil(pdfPages) && pdfPages?.length > 0) {
					const pagesData: IFusePdfPage[] = map(pdfPages, (page) => ({
						base64: page.base64,
						url: page.url,
						width: page.width,
						height: page.height,
					}));

					setPages(pagesData);
				} else {
					const pagesData = await convertPdfToImages(pdfBase64);

					setPages(pagesData);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				setHasError(true);
			} finally {
				setIsLoading(false);
			}
		}, [pdfBase64, pdfPages]);

		useEffect(() => {
			const base64condition = !isNil(pdfBase64);
			const pagesCondition = !isNil(pdfPages) && pdfPages.length > 0;

			if (base64condition || pagesCondition) {
				parsePdf();
			}
		}, [pdfBase64, parsePdf, pdfPages]);

		const getPageUrl = (url: string): string => {
			if (!isNil(url)) {
				return disableImageCaching ? `${url}?t=${Date.now()}` : url;
			}

			return null;
		};

		if (isLoading || isLoadingData) {
			return <FusePdfSkeleton showBorder={showBorder} />;
		}

		if (hasError) {
			return errorComponent;
		}

		return (
			<SDocumentWrapper ref={ref} data-test-id={`${testId}-pdf`} $showBorder={showBorder} {...otherProps}>
				{pages.map(({ base64, width, height, url }, index) => (
					<FusePdfPage
						key={`${testId}-${index.toString()}`}
						data-test-id={testId}
						index={index}
						base64={base64}
						originalWidth={width}
						originalHeight={height}
						url={getPageUrl(url)}
					>
						{overlayItemsByPage[index]}
					</FusePdfPage>
				))}
			</SDocumentWrapper>
		);
	}
);
