import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
	IInitBundleAlignmentStatusesPayload,
	IProtectionProductsState,
	ISetBundleAlignmentStatusPayload,
} from '@transact-client/store/slices/protectionProducts/types';

export const initialState: IProtectionProductsState = {
	bundles: {
		alignmentStatuses: {},
	},
};

export const {
	reducer,
	actions: { initBundleAlignmentStatuses, setBundleAlignmentStatus },
} = createSlice({
	name: 'protectionProducts',
	initialState,
	reducers: {
		initBundleAlignmentStatuses: (state, action: PayloadAction<IInitBundleAlignmentStatusesPayload>): void => {
			state.bundles.alignmentStatuses = action.payload.bundleIds.reduce((result, bundleId) => ({ ...result, [bundleId]: 'unaligned' }), {});
		},

		setBundleAlignmentStatus: (state, action: PayloadAction<ISetBundleAlignmentStatusPayload>): void => {
			const { bundleId, status } = action.payload;

			state.bundles.alignmentStatuses[bundleId] = status;
		},
	},
});
