import React, { useEffect, useState } from 'react';
import { useAppTheme } from '../../../../hooks/useAppTheme';
import type { IRippeProps } from './types';
import { SRippleContainer, SRippleSpan } from './Ripple.styles';

export const Ripple = ({ speed }: IRippeProps): JSX.Element => {
	const theme = useAppTheme();
	const { rippleEnter } = theme.transitions.duration;

	const [rippleArray, setRippleArray] = useState([]);

	useEffect(() => {
		let bounce = null;

		if (rippleArray.length > 0) {
			clearTimeout(bounce);
			bounce = setTimeout(() => {
				setRippleArray([]);
			}, +rippleEnter);
		}

		return (): void => {
			clearTimeout(bounce);
		};
	}, [rippleArray.length, rippleEnter]);

	const addRipple = (event): void => {
		const rippleContainer = event.currentTarget.getBoundingClientRect();
		const size = rippleContainer.width > rippleContainer.height ? rippleContainer.width : rippleContainer.height;
		const x = event.pageX - rippleContainer.x - rippleContainer.width / 2;
		const y = event.pageY - rippleContainer.y - rippleContainer.width / 2;
		const newRipple = {
			x,
			y,
			size,
		};

		setRippleArray([newRipple]);
	};

	return (
		<SRippleContainer onMouseDown={addRipple} $speed={speed}>
			{rippleArray && rippleArray.length > 0 && <SRippleSpan $x={rippleArray[0].x} $y={rippleArray[0].y} $size={rippleArray[0].size} />}
		</SRippleContainer>
	);
};
