import styled, { css } from 'styled-components';
import type { cssHelperType, Transient } from '../../../types';
import type { FuseIconColor, FuseIconSize, ISIconWrapperProps } from './types';
import {
	errorColorDefault,
	fontSizeBody2,
	fontSizeBody4,
	fontSizeH2,
	fontSizeH4,
	infoColorDefault,
	primaryColorDefault,
	secondaryColorDefault,
	successColorDefault,
	warningColorDefault,
} from '../../../theme/selectors';

const colorStyles: Record<FuseIconColor, cssHelperType> = {
	info: css`
		color: ${infoColorDefault};
	`,
	primary: css`
		color: ${primaryColorDefault};
	`,
	secondary: css`
		color: ${secondaryColorDefault};
	`,
	success: css`
		color: ${successColorDefault};
	`,
	error: css`
		color: ${errorColorDefault};
	`,
	warning: css`
		color: ${warningColorDefault};
	`,
};

const sizeStyles: Record<FuseIconSize, cssHelperType> = {
	sm: css`
		width: ${fontSizeBody4};
		height: ${fontSizeBody4};
	`,
	md: css`
		width: ${fontSizeBody2};
		height: ${fontSizeBody2};
	`,
	lg: css`
		width: ${fontSizeH4};
		height: ${fontSizeH4};
	`,
	xl: css`
		width: ${fontSizeH2};
		height: ${fontSizeH2};
	`,
};

export const SIconWrapper = styled.div<Transient<ISIconWrapperProps>>`
	${({ $size }): cssHelperType => sizeStyles[$size]};

	svg {
		${({ $size, $color }): cssHelperType => css`
			${sizeStyles[$size]}
			${colorStyles[$color]};
		`};
	}
`;
