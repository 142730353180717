import type { ReactNode } from 'react';
import type { IFuseCommonProps } from '../../../types';

export enum ETooltipPlacement {
	Top = 'top',
	Right = 'right',
	Bottom = 'bottom',
	Left = 'left',
}

export interface IFuseTooltipProps extends Omit<IFuseCommonProps, 'data-test-id'> {
	content: ReactNode;
	placement?: ETooltipPlacement;
	disableOpenOnHover?: boolean;
	disableOpenOnClick?: boolean;
	disableCloseOnClickOutside?: boolean;
	disableAutoPlacement?: boolean;
	['data-test-id']: string;
}

export interface ISArrowProps {
	$placement?: ETooltipPlacement;
	$x?: number;
	$y?: number;
}
