import type { InputHTMLAttributes } from 'react';
import type { IFuseCommonProps } from '../../../types';

export enum EFuseToggleTextDirection {
	Left = 'left',
	Right = 'right',
}

export enum EFuseToggleSize {
	Small = 'small',
	Medium = 'medium',
}

export interface IFuseToggleProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'size'>,
		Omit<IFuseCommonProps, 'as' | 'data-test-id'> {
	value: boolean;
	isDisabled?: boolean;
	showInsideLabel?: boolean;
	label?: string;
	labelDirection?: EFuseToggleTextDirection;
	errorMessage?: string;
	size?: EFuseToggleSize;
	onChange?: (newStatus: boolean) => void;
	['data-test-id']: string;
}

export interface IToggleOutsideWrapperStyleProps {
	isDisabled: boolean;
	labelDirection?: EFuseToggleTextDirection;
}

export interface IToggleSpanStyleProps {
	isDisabled: boolean;
}

export interface IToggleWrapperStyleProps {
	size: EFuseToggleSize;
	isDisabled: boolean;
	isActive: boolean;
}
