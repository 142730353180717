import type { Placement } from '@floating-ui/react';
import { ETooltipPlacement } from './types';

export const tooltipPlacementToFloatingUiPlacement = (placement: ETooltipPlacement): Placement => {
	switch (placement) {
		case ETooltipPlacement.Top:
			return 'top';
		case ETooltipPlacement.Right:
			return 'right';
		case ETooltipPlacement.Bottom:
			return 'bottom';
		case ETooltipPlacement.Left:
			return 'left';
		default:
			return 'bottom';
	}
};

export const floatingUiPlacementToTooltipPlacement = (placement: Placement): ETooltipPlacement => {
	switch (placement) {
		case 'top':
			return ETooltipPlacement.Top;
		case 'right':
			return ETooltipPlacement.Right;
		case 'bottom':
			return ETooltipPlacement.Bottom;
		case 'left':
			return ETooltipPlacement.Left;
		default:
			return ETooltipPlacement.Bottom;
	}
};
