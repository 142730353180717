import styled, { css } from 'styled-components';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import {
	backgroundColorSecondary,
	borderColorDefault,
	borderRadiusDefault,
	borderWidthDefault,
	defaultSpacing,
	disabledColorDefault,
	primaryColorDefault,
	textColorSecondary,
} from '../../../theme/selectors';
import type { ISCheckmarkProps, ISRadioButtonProps } from './types';
import type { cssHelperType } from '../../../types';

export const SIconAndLabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${defaultSpacing};
`;

export const SRadioButton = styled.button.attrs(() => ({
	type: 'button',
}))<ISRadioButtonProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: ${borderRadiusDefault};
	padding: 1rem;
	border-color: ${borderColorDefault};
	border-width: ${borderWidthDefault};
	border-style: solid;
	background-color: transparent;
	color: ${backgroundColorSecondary};
	cursor: pointer;

	${SIconAndLabelWrapper} svg {
		fill: ${textColorSecondary};
	}

	${({ $isSelected }): cssHelperType =>
		$isSelected &&
		css`
			border-color: ${primaryColorDefault};

			${SIconAndLabelWrapper} svg {
				fill: ${primaryColorDefault};
			}
		`}

	${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			background-color: ${disabledColorDefault};
			border-color: ${disabledColorDefault};
			cursor: not-allowed;
		`}

    ${({ $isLoading }): cssHelperType =>
		$isLoading &&
		css`
			cursor: not-allowed;
		`}
`;

export const SLabelAndCheckmarkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const commonCheckmarkStyles = css<ISCheckmarkProps>`
	width: 1rem;
	height: 1rem;
`;

export const SUncheckedIcon = styled(FaRegCircle)`
	${commonCheckmarkStyles};
	fill: ${borderColorDefault};
`;

export const SCheckedIcon = styled(FaCheckCircle)`
	${commonCheckmarkStyles};
	fill: ${primaryColorDefault};
`;

export const SChildrenWrapper = styled.div`
	padding-block-start: 1rem;
	width: 100%;
`;
