import type { Property } from 'csstype';

export interface IColorGroup {
	light: Property.Color;
	default: Property.Color;
	dark: Property.Color;
	contrastText: Property.Color;
	background: Property.Color;
}

export interface IColors {
	main: Record<string, Property.Color>;
	secondary: Record<string, Property.Color>;
	tint: Record<string, Property.Color>;
	gray: Record<string, Property.Color>;
	transparent: Property.Color;
}

export type ThemeSelector<T> = ({ theme }: { theme: ITheme }) => T;

export interface ITheme {
	name: EThemeName;
	colors: {
		common: IColors;
		primary: IColorGroup;
		secondary: IColorGroup;
		active: IColorGroup;
		success: IColorGroup;
		info: IColorGroup;
		warning: IColorGroup;
		error: IColorGroup;
		disabled: IColorGroup;
		background: {
			default: Property.Color;
			secondary: Property.Color;
			backdrop: Property.Color;
			contrast: Property.Color;
			contrastSecondary: Property.Color;
		};
		text: {
			default: Property.Color;
			secondary: Property.Color;
			white: Property.Color;
		};
		border: {
			default: Property.Color;
			focused: Property.Color;
			selected: Property.Color;
		};
		separator: {
			default: Property.Color;
		};
	};
	shadows: {
		default: Property.BoxShadow;
		darker: Property.BoxShadow;
	};
	fontFamily: Property.FontFamily;
	fontSize: {
		h1: Property.FontSize;
		h2: Property.FontSize;
		h3: Property.FontSize;
		h4: Property.FontSize;
		body1: Property.FontSize;
		body2: Property.FontSize;
		body3: Property.FontSize;
		body4: Property.FontSize;
		disclaimer: Property.FontSize;
	};
	fontWeight: {
		light: Property.FontWeight;
		default: Property.FontWeight;
		md: Property.FontWeight;
		bold: Property.FontWeight;
	};
	spacing: {
		noSpacing: Property.Padding;
		half: Property.Padding;
		default: Property.Padding;
		double: Property.Padding;
	};
	gap: {
		default: Property.RowGap;
	};
	borderWidth: {
		default: Property.BorderWidth;
		double: Property.BorderWidth;
	};
	borderRadius: {
		half: Property.BorderRadius;
		default: Property.BorderRadius;
		round: Property.BorderRadius;
		full: Property.BorderRadius;
		circle: Property.BorderRadius;
	};
	heights: {
		input: {
			sm: Property.Height;
			md: Property.Height;
			lg: Property.Height;
			xl: Property.Height;
		};
	};
	zIndex: {
		badge: Property.ZIndex;
		footer: Property.ZIndex;
		sticky: Property.ZIndex;
		select: Property.ZIndex;
		menu: Property.ZIndex;
		backdrop: Property.ZIndex;
		drawer: Property.ZIndex;
		modal: Property.ZIndex;
		tooltip: Property.ZIndex;
		popover: Property.ZIndex;
		datePicker: Property.ZIndex;
		toast: Property.ZIndex;
	};
	transitions: {
		duration: {
			default: Property.TransitionDuration;
			accordionExpansion: Property.TransitionDuration;
			progressBar: Property.TransitionDuration;
			rippleEnter: Property.TransitionDuration;
			rippleExit: Property.TransitionDuration;
		};
	};
	constants: {
		button: { borderRadius: Property.BorderRadius; gap: Property.Gap };
		card: { borderRadius: Property.BorderRadius };
		input: { paddingInline: Property.Padding; paddingBlock: Property.Padding };
		icon: { borderRadius: Property.BorderRadius };
		header: { height: Property.Height };
		form: { padding: Property.Padding };
		section: {
			spacing: { default: Property.Padding };
		};
	};
}

export enum EThemeName {
	Light = 'light',
	Dark = 'dark',
}
