import styled, { css } from 'styled-components';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { IoCloseCircleOutline } from 'react-icons/io5';
import type { Property } from 'csstype';
import {
	activeColorDefault,
	backgroundColorDefault,
	borderRadiusDefault,
	doubleSpacing,
	fontSizeH2,
	halfSpacing,
	shadowDarker,
	textColorSecondary,
	transitionDurationDefault,
	zIndexModal,
} from '../../../theme/selectors';
import { FuseBackdrop } from '../../overlay/FuseBackdrop';
import { EModalSize } from '../../overlay/FuseModal';
import type { ISModalProps } from './types';
import type { cssHelperType } from '../../../types';

export const SBackdrop = styled(FuseBackdrop)`
	z-index: ${zIndexModal};
`;

const modalSizeToDimensions: Record<EModalSize, { height: Property.Height; width: Property.Width }> = {
	[EModalSize.Small]: { height: '21.25rem', width: '28.75rem' },
	[EModalSize.Medium]: { height: '28.125rem', width: '37.5rem' },
	[EModalSize.Large]: { height: '40.625rem', width: '45.625rem' },
	[EModalSize.ExtraLarge]: { height: '46.25rem', width: '51.25rem' },
};

const drawerMotionProps: MotionProps = {
	initial: { y: '100%' },
	animate: { y: 0 },
	exit: { y: '100%' },
};

const modalMotionProps: MotionProps = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};

const drawerStyles = css`
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	padding: 2rem ${doubleSpacing};
	overflow: hidden;
	max-height: 90%;
	max-width: 100vw;
	width: 100vw;
`;

const modalStyles = css`
	inset-block-start: 50%;
	inset-inline-start: 50%;
	transform: translate(-50%, -50%);
	padding: 1.8rem;
	overflow: visible;
	max-height: 92vh;
	max-width: 92vw;
`;

export const SModal = styled(motion.div).attrs<ISModalProps, MotionProps>(({ $isDrawer }) => ({
	key: $isDrawer ? 'drawer' : 'modal',
	transition: { duration: 0.2 },
	...($isDrawer ? drawerMotionProps : modalMotionProps),
}))<ISModalProps>`
	position: fixed;
	background-color: ${backgroundColorDefault};
	box-shadow: ${shadowDarker};
	border-radius: ${borderRadiusDefault};
	z-index: ${zIndexModal};
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	${({ $size, $isFixedHeight }): cssHelperType => css`
		width: ${modalSizeToDimensions[$size]?.width};
		height: ${$isFixedHeight ? modalSizeToDimensions[$size]?.height : 'auto'};
	`};

	${({ $isDrawer }): cssHelperType => ($isDrawer ? drawerStyles : modalStyles)};

	${({ $isFullScreen }): cssHelperType =>
		$isFullScreen &&
		css`
			width: 100%;
			height: 100%;
			max-height: 100vh;
			max-width: 100vw;
		`};
`;

export const SCloseIcon = styled(IoCloseCircleOutline)`
	position: absolute;
	top: ${halfSpacing};
	right: ${halfSpacing};
	cursor: pointer;
	width: ${fontSizeH2};
	height: ${fontSizeH2};
	color: ${textColorSecondary};
	transition: color ${transitionDurationDefault} ease;

	:active,
	:hover {
		color: ${activeColorDefault};
	}
`;
