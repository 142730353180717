import type { Selector } from '@transact-client/store/types';
import type { IUser } from '@transact-client/commonUtils/auth';
import type { IDealership } from '@shared/proto/dealership/v1/messages/Dealership';

export const selectUser: Selector<IUser> = (state) => state.auth?.user;
export const selectUserId: Selector<string> = (state) => state.auth?.user?.id;
export const selectUserName: Selector<string> = (state) => state.auth?.user?.name;
export const selectUserEmail: Selector<string> = (state) => state.auth?.user?.email;
export const selectUserPermissions: Selector<string[]> = (state) => state.auth?.user?.permissions;
export const selectUserToken: Selector<string> = (state) => state.auth?.token;
export const selectUserSelectedDealershipId: Selector<IDealership['id']> = (state) => state.auth?.selectedDealershipId;
export const selectUserSelectedDealershipIds: Selector<IDealership['id'][]> = (state) => state.auth?.selectedDealershipIds;
