import React, { useMemo } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { values } from 'lodash-es';
import { AnimatePresence } from 'framer-motion';
import { useOverlaysStore } from '../../../hooks/useOverlaysStore';
import { removeAllHiddenOverlays } from '../../../stores/Overlays.store';
import { OverlayContext } from '../../../contexts/Overlay.context';

export const OverlaysContainer = (): JSX.Element => {
	const overlays = useOverlaysStore();

	const contextValues = useMemo(
		() =>
			values(overlays).reduce(
				(result, { id }) => ({
					...result,
					[id]: { id, onExitComplete: removeAllHiddenOverlays },
				}),
				{}
			),
		[overlays]
	);

	return (
		<FloatingPortal id="fuse-overlays-container">
			<AnimatePresence>
				{values(overlays).map(({ id, component: Component, props }) => (
					<OverlayContext.Provider key={id} value={contextValues[id]}>
						<Component {...(props as Record<string, unknown>)} />
					</OverlayContext.Provider>
				))}
			</AnimatePresence>
		</FloatingPortal>
	);
};
