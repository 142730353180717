import styled, { css } from 'styled-components';
import type { IFuseFlexProps } from './types';
import type { StylesMixin, Transient } from '../../../types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';

const stylesMixin: StylesMixin = (props: Transient<IFuseFlexProps>) => css`
	display: ${props.$inline ? 'inline-flex' : 'flex'};
	flex-direction: ${props.$direction};
	flex-wrap: ${props.$wrap};
	justify-content: ${props.$justifyContent};
	align-content: ${props.$alignContent};
	align-items: ${props.$alignItems};
	gap: ${props.$gap};
	width: ${props.$width};
	height: ${props.$height};
	flex: ${props.$flex};
	flex-basis: ${props.$basis};
	flex-grow: ${props.$grow};
	flex-shrink: ${props.$shrink};
	align-self: ${props.$alignSelf};
`;

export const Flex = styled.div<Transient<IFuseFlexProps>>`
	${getResponsiveStyles(stylesMixin)}
`;
