import type { ChangeEvent } from 'react';
import React, { forwardRef } from 'react';
import type { IFuseTextAreaBaseProps } from './types';
import { EFuseTextAreaLabelType } from './types';
import { SErrorIcon, SErrorMessage, SErrorWrapper, SLabel, SLabelAndTooltipWrapper, STextArea, STooltipIcon, SWrapper } from './FuseTextAreaBase.styles';
import { ETooltipPlacement, FuseTooltip } from '../../dataDisplay/FuseTooltip';

export const FuseTextAreaBase = forwardRef<HTMLTextAreaElement, IFuseTextAreaBaseProps>(
	(
		{
			onChange,
			error,
			tooltipContent,
			label,
			labelType = EFuseTextAreaLabelType.Primary,
			isRequired = false,
			isDisabled = false,
			borderColor,
			fontSize,
			'data-test-id': testId,
			...otherProps
		}: IFuseTextAreaBaseProps,
		ref
	): JSX.Element => {
		const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
			onChange?.(event.target.value);
		};

		return (
			<SWrapper>
				{(label || tooltipContent) && (
					<SLabelAndTooltipWrapper data-test-id={`${testId}-text-area-label`}>
						{label && (
							<SLabel $type={labelType} $isRequired={isRequired}>
								{label}
							</SLabel>
						)}

						{tooltipContent && (
							<FuseTooltip data-test-id={`${testId}-text-area-tooltip`} content={tooltipContent} placement={ETooltipPlacement.Right}>
								<STooltipIcon />
							</FuseTooltip>
						)}
					</SLabelAndTooltipWrapper>
				)}

				<STextArea
					data-test-id={`${testId}-text-area`}
					onChange={handleChange}
					$borderColor={borderColor}
					$fontSize={fontSize}
					$hasError={error?.length > 0}
					required={isRequired}
					disabled={isDisabled}
					ref={ref}
					{...otherProps}
				/>

				{error?.length > 0 && (
					<SErrorWrapper>
						<SErrorIcon />

						<SErrorMessage>{error}</SErrorMessage>
					</SErrorWrapper>
				)}
			</SWrapper>
		);
	}
);

FuseTextAreaBase.displayName = 'FuseTextAreaBase';
