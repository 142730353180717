import styled, { css, keyframes } from 'styled-components';
import type { Property } from 'csstype';
import type { IFuseSkeletonProps } from './types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';
import type { cssHelperType, StylesMixin, Transient } from '../../../types';
import type { FuseTypographyVariant } from '../FuseTypography/types';
import {
	borderRadiusDefault,
	borderRadiusFull,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody3,
	fontSizeBody4,
	fontSizeDisclaimer,
	fontSizeH1,
	fontSizeH2,
	fontSizeH3,
	fontSizeH4,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';

export const WaveAnimation = keyframes`
	0% {
		transform:translateX(-100%);
	}
	50% {
		transform:translateX(100%);
	}
	100% {
		transform:translateX(100%);
	}
`;

const typographyTypeToFontSize: Record<FuseTypographyVariant, ThemeSelector<Property.FontSize>> = {
	h1: fontSizeH1,
	h2: fontSizeH2,
	h3: fontSizeH3,
	h4: fontSizeH4,
	b1: fontSizeBody1,
	b2: fontSizeBody2,
	b3: fontSizeBody3,
	b4: fontSizeBody4,
	disclaimer: fontSizeDisclaimer,
};

const stylesMixin: StylesMixin = (props: Transient<IFuseSkeletonProps>) => css`
	width: ${props.$width};
	height: ${props.$height};
`;

export const Skeleton = styled.div<Transient<IFuseSkeletonProps>>`
	background-color: ${({ theme }): Property.BackgroundColor => theme.colors.common.gray.gray2};
	position: relative;
	overflow: hidden;
	${getResponsiveStyles(stylesMixin)};

	${({ $type, $typographyType, $height, $width }): cssHelperType =>
		({
			text: css`
				border-radius: ${borderRadiusDefault};
				height: ${typographyTypeToFontSize?.[$typographyType]};
			`,
			rectangle: css`
				border-radius: 1px;
				height: ${$height || 'auto'};
			`,
			circle: css`
				border-radius: 50%;
				height: ${$height || $width};
			`,
			button: css`
				height: 2.625rem;
				width: 100%;
				border-radius: ${borderRadiusDefault};
			`,
			chip: css`
				height: ${$height || '1.5rem'};
				border-radius: ${borderRadiusFull};
			`,
		})[$type] ?? null}

	::after {
		content: '';
		animation: ${WaveAnimation} 1.2s linear 0.5s infinite normal none running;
		background: linear-gradient(90deg, transparent, ${({ theme }): Property.BackgroundColor => theme.colors.common.gray.gray3}80, transparent);
		position: absolute;
		transform: translateX(-100%);
		inset: 0;
	}
`;
