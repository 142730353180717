import type { EPasswordlessAuthLinkType } from '@shared/proto/passwordlessAuth/v1/enums/linkType';
import type { EOrganizationType } from '@shared/proto/organization/v1/enums/organizationType';

export const APP_METADATA_KEY = `${process.env.AUTH0_CONTEXT}/appMetadata`;

export type Auth0UserRole = {
	id: string;
	name: string;
	description?: string;
};

export interface IUserCoreFields {
	email: string;
	id: string;
	picture: string;
	name: string;
	authStrategy?: string;
	isEnterpriseConnection?: boolean;
}

export interface IUserMetadata extends Record<string, unknown> {
	dealershipIds: number[];
	isMaster?: boolean;
	roles?: Auth0UserRole[];
	permissions?: string[];
	internalUser?: boolean;
	phoneNumber?: string;
	guestUser?: boolean;
	dmsId?: string;
	financeSourceProviderId?: string;
	inviterEmail?: string;
	remoteType?: EPasswordlessAuthLinkType;
	orgType?: EOrganizationType;
	fuseOrgId?: string | number;
}

export type IUser = IUserCoreFields & IUserMetadata;

export type JsonValue = string | number | boolean;
