import type { PropsWithChildren, ReactElement } from 'react';
import React, { Children, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isNil, noop } from 'lodash-es';
import { useImmer } from 'use-immer';
import type { IFusePdfPageProps } from './types';
import { SImage, SOverlayItemWrapper, SPageWrapper } from './FusePdf.styles';
import { FusePdfSkeleton } from './FusePdf.skeleton';

export const FusePdfPage = ({
	index,
	base64,
	originalWidth,
	originalHeight,
	'data-test-id': testId,
	children,
	url,
}: PropsWithChildren<IFusePdfPageProps>): JSX.Element => {
	const imgRef = useRef<HTMLImageElement>(null);

	const [{ isLoading, loadedImage }, setLoadingState] = useImmer({ isLoading: true, loadedImage: null });
	const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>(null);

	// If the base 64 doesnt start with data:image/png;base64, then add it
	const base64Img = useMemo(() => {
		if (!isNil(base64) && base64?.startsWith('data:image/png;base64,')) {
			return base64;
		}

		return `data:image/png;base64,${base64}`;
	}, [base64]);

	useEffect(() => {
		if ((isNil(url) && isNil(base64Img)) || (!isLoading && loadedImage !== url && loadedImage !== base64Img)) {
			setLoadingState((state) => {
				state.isLoading = true;
			});
		} else if (!isNil(loadedImage) && (loadedImage === url || loadedImage === base64Img)) {
			setLoadingState((state) => {
				state.isLoading = false;
			});
		}
	}, [base64Img, isLoading, loadedImage, setLoadingState, url]);

	const handleImageLoad = (): void => {
		// If the loaded image is the same as current image set loading to false
		if (imgRef.current.src === url || imgRef.current.src === base64Img) {
			setLoadingState((state) => {
				state.isLoading = false;
				state.loadedImage = imgRef.current.src;
			});
		}
	};

	useLayoutEffect(() => {
		if (!imgRef.current) {
			return noop;
		}

		const observer = new ResizeObserver((entries) => {
			const { inlineSize = 0, blockSize = 0 } = entries?.[0]?.contentBoxSize?.[0] ?? {};

			setImageDimensions({ width: inlineSize, height: blockSize });
		});

		observer.observe(imgRef.current);

		return (): void => {
			observer.disconnect();
		};
	}, []);

	return (
		<SPageWrapper data-test-id={`${testId}-pdf-page-${index}`}>
			<SImage
				style={isLoading ? { display: 'none' } : {}}
				data-test-id={`${testId}-pdf-page-image-${index}`}
				onLoad={handleImageLoad}
				src={url ?? base64Img}
				alt=""
				ref={imgRef}
			/>

			{isLoading ? (
				<FusePdfSkeleton showBorder={false} />
			) : (
				<>
					{imgRef.current &&
						imageDimensions &&
						Children.map(children, (child: ReactElement, childIndex): ReactElement | void => {
							const props = child?.props?.field ?? child?.props;
							const x = (imageDimensions.width / originalWidth) * (props?.x ?? 0);
							const y = (imageDimensions.height / originalHeight) * (props?.y ?? 0);
							const width = (imageDimensions.width / originalWidth) * (props?.width ?? 0);
							const height = (imageDimensions.height / originalHeight) * (props?.height ?? 0);

							return (
								<SOverlayItemWrapper
									key={`pdf-page-${index + 1}-overlay-item-${childIndex.toString()}`}
									data-test-id={`pdf-page-${index + 1}-overlay-item-${childIndex.toString()}`}
									$x={x}
									$y={y}
									$width={width}
									$height={height}
									data-test-x={x}
									data-test-y={y}
									data-test-width={width}
									data-test-height={height}
								>
									{child}
								</SOverlayItemWrapper>
							);
						})}
				</>
			)}
		</SPageWrapper>
	);
};
