import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import type { IFuseAlertBaseProps } from './types';
import { Icon, SCloseIcon, SCloseIconWrapper, SContentWrapper, SFuseAlertBaseWrapper, SRoundDecorator, typeToIconMap } from './FuseAlertBase.styles';
import { FuseAvatarBase } from '../../internal/FuseAvatarBase';
import { useAppTheme } from '../../../hooks/useAppTheme';
import { fontSizeBody1 } from '../../../theme/selectors';

export const FuseAlertBase = forwardRef<HTMLDivElement, PropsWithChildren<IFuseAlertBaseProps>>(
	(
		{ color = 'primary', onClose, avatarFullName, hideIcon, 'data-test-id': testId, children, ...otherProps }: PropsWithChildren<IFuseAlertBaseProps>,
		ref
	): JSX.Element => {
		const theme = useAppTheme();

		const handleClose = (): void => {
			onClose();
		};

		const mappedColor = typeToIconMap[color].color({ theme });

		return (
			<SFuseAlertBaseWrapper data-test-id={`${testId}-alert`} ref={ref} $color={mappedColor} {...otherProps}>
				{!hideIcon && (
					<SRoundDecorator>
						{avatarFullName ? (
							<FuseAvatarBase
								data-test-id={testId}
								fullName={avatarFullName}
								color={mappedColor}
								fontSize={fontSizeBody1({ theme })}
								size="2.5rem"
							/>
						) : (
							<Icon $type={color} />
						)}
					</SRoundDecorator>
				)}

				<SContentWrapper>{children}</SContentWrapper>

				{onClose && (
					<SCloseIconWrapper onClick={handleClose}>
						<SCloseIcon />
					</SCloseIconWrapper>
				)}
			</SFuseAlertBaseWrapper>
		);
	}
);

FuseAlertBase.displayName = 'FuseAlertBase';
