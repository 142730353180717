import type { EDealStatus } from '@shared/proto/deal/v1/enums/DealStatus';
import type { EDealType } from '@shared/proto/common/v1/enums/DealType';
import { pickBy } from 'lodash-es';
import { addDays, parseISO } from 'date-fns';

export enum EFilterName {
	Status = 'Status',
	DealType = 'Deal Type',
	CreationDate = 'Creation Date',
	Store = 'Store',
	DealOwner = 'Deal Owner',
	Manager = 'Manager',
}

export enum EFilterType {
	MultiSelect,
	DateRange,
}

export interface IFilter {
	name: EFilterName;
	type: EFilterType;
	value: unknown;
}

export interface IFilterDefinition {
	type: IFilter['type'];
	defaultValue: IFilter['value'];
	getQueryParams: (value: IFilter['value']) => Record<string, unknown>;
}

export const filterDefinitions: Record<IFilter['name'], IFilterDefinition> = {
	[EFilterName.Status]: {
		type: EFilterType.MultiSelect,
		defaultValue: [],
		getQueryParams: (values: EDealStatus[]) => ({ statuses: values ?? [] }),
	},
	[EFilterName.DealType]: {
		type: EFilterType.MultiSelect,
		defaultValue: [],
		getQueryParams: (values: EDealType[]) => ({ types: values ?? [] }),
	},
	[EFilterName.CreationDate]: {
		type: EFilterType.DateRange,
		defaultValue: { from: null, to: null },
		getQueryParams: (value: { from: string; to: string }) =>
			pickBy({ creationDateMin: value?.from, creationDateMax: value?.to ? addDays(parseISO(value?.to), 1).toISOString() : null }),
	},
	[EFilterName.Store]: {
		type: EFilterType.MultiSelect,
		defaultValue: [],
		getQueryParams: (values: string[]) => ({ dealershipIds: values ?? [] }),
	},
	[EFilterName.DealOwner]: {
		type: EFilterType.MultiSelect,
		defaultValue: [],
		getQueryParams: (values: string[]) => ({ dealOwnerIds: values ?? [] }),
	},
	[EFilterName.Manager]: {
		type: EFilterType.MultiSelect,
		defaultValue: [],
		getQueryParams: (values: string[]) => ({ managerIds: values ?? [] }),
	},
};
