import React, { forwardRef } from 'react';
import type { IFuseChipProps } from './types';
import { SChip, SClearIcon } from './FuseChip.styles';

export const FuseChip = forwardRef<HTMLDivElement, IFuseChipProps>(
	({ text, color = 'primary', size = 'md', isClearable, onClear, 'data-test-id': testId, ...otherProps }: IFuseChipProps, ref): JSX.Element => (
		<SChip data-test-id={`${testId}-chip`} ref={ref} $color={color} $size={size} {...otherProps}>
			{text}
			{isClearable && <SClearIcon $size={size} onClick={onClear} />}
		</SChip>
	)
);

FuseChip.displayName = 'FuseChip';
