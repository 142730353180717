import React, { Fragment } from 'react';
import { range } from 'lodash-es';
import { FuseSkeleton } from '../FuseSkeleton';
import { FuseSpacer } from '../../layout/FuseSpacer';
import { SSkeletonWrapper } from './FusePdf.styles';
import type { IFusePdfSkeletonProps } from './types';

export const FusePdfSkeleton = ({ showBorder = false }: IFusePdfSkeletonProps): JSX.Element => (
	<SSkeletonWrapper $showBorder={showBorder} data-test-id="pdf-skeleton" data-test-loading>
		<FuseSkeleton type="text" typographyType="h1" width="15rem" />

		<FuseSpacer height="2rem" />

		{range(2).map((index) => (
			<Fragment key={`pdf-skeleton-paragraph-1-${index}`}>
				{range(4).map((line) => (
					<Fragment key={`pdf-skeleton-paragraph-1-${index}-${line}`}>
						<FuseSkeleton type="text" typographyType="b1" width="100%" />
						<FuseSpacer height="0.25rem" />
					</Fragment>
				))}

				<FuseSkeleton type="text" typographyType="b1" width="70%" />

				<FuseSpacer height="2rem" />
			</Fragment>
		))}

		<FuseSpacer height="2rem" />

		<FuseSkeleton type="text" typographyType="h1" width="18rem" />

		<FuseSpacer height="2rem" />

		{range(3).map((index) => (
			<Fragment key={`pdf-skeleton-paragraph-2-${index}`}>
				{range(6).map((line) => (
					<Fragment key={`pdf-skeleton-paragraph-2-${index}-${line}`}>
						<FuseSkeleton type="text" typographyType="b1" width="100%" />
						<FuseSpacer height="0.25rem" />
					</Fragment>
				))}

				<FuseSkeleton type="text" typographyType="b1" width="50%" />

				<FuseSpacer height="2rem" />
			</Fragment>
		))}
	</SSkeletonWrapper>
);
