import type { Property } from 'csstype';
import type { MouseEventHandler } from 'react';

export enum EFuseChatBubbleSize {
	Small = 'small',
	Large = 'large',
}

export interface IFuseChatBubbleProps {
	isOpen: boolean;
	onClick?: MouseEventHandler;
}

export interface IFuseChatBubbleStyleProps {
	$width: Property.Width;
	$height: Property.Height;
}
