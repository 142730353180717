import type { PropsWithChildren } from 'react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '@transact-client/styles/globalStyles';
import { selectUserSelectedThemeType } from '@transact-client/store/selectors/theme.selectors';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme, EThemeName } from '@fuse/react-ui';
import { useFeatureFlag } from '@transact-client/hooks/useFeatureFlag';
import { IS_THEME_TOGGLE_ENABLED } from 'shared/featureFlags/fuseapp/isThemeToggleEnabled.flag';

export const StyledComponentsProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const isThemeToggleEnabled = useFeatureFlag(IS_THEME_TOGGLE_ENABLED);

	const themeType = useSelector(selectUserSelectedThemeType);

	const selectedTheme = themeType === EThemeName.Dark ? darkTheme : lightTheme;

	return (
		<ThemeProvider theme={isThemeToggleEnabled ? selectedTheme : lightTheme}>
			<GlobalStyles />
			{children}
		</ThemeProvider>
	);
};
