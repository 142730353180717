import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IRemoteState } from '@transact-client/store/slices/remote/types';
import { setRemoteAuthData } from '@transact-client/store/slices/auth';
import jwtDecode from 'jwt-decode';

const initialState: IRemoteState = {
	customerId: null,
	type: null,
	dealershipName: null,
};

export const {
	reducer,
	actions: { setDealGuestDealershipName },
} = createSlice({
	name: 'remote',
	initialState,
	reducers: {
		setDealGuestDealershipName: (state, action: PayloadAction<{ dealershipName: string }>): void => {
			state.dealershipName = action.payload.dealershipName;
		},
	},
	extraReducers: {
		[setRemoteAuthData.type]: (state, action: PayloadAction<string>): void => {
			const decodedToken = jwtDecode<any>(action.payload);

			state.customerId = decodedToken.id;
			state.type = decodedToken.remoteType;
		},
	},
});
