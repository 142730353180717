import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import type { IDeal } from '@shared/proto/deal/v1/messages/Deal';
import { setRemoteAuthData } from '@transact-client/store/slices/auth';

export interface IDeskingState {
	currentStep: number;
	selectedDealId: IDeal['id'] | null;
}

export const initialState: IDeskingState = {
	currentStep: 0,
	selectedDealId: null,
};

export const {
	reducer,
	actions: { setSelectedDealId, clearSelectedDealId, setDeskingCurrentStep },
} = createSlice({
	name: 'desking',
	initialState,
	reducers: {
		setSelectedDealId: (state, action: PayloadAction<string>): void => {
			state.selectedDealId = action.payload;
		},

		clearSelectedDealId: (state): void => {
			state.selectedDealId = null;
		},

		setDeskingCurrentStep: (state, action: PayloadAction<number>): void => {
			state.currentStep = action.payload;
		},
	},
	extraReducers: {
		[setRemoteAuthData.type]: (state, action: PayloadAction<string>): void => {
			const decodedToken = jwtDecode<any>(action.payload);

			state.selectedDealId = decodedToken.dealId;
		},
	},
});
