import styled, { css } from 'styled-components';
import { CgClose } from 'react-icons/cg';
import type { FuseChipColor, FuseChipSize, ISChipProps, ISClearIconProps } from './types';
import type { cssHelperType } from '../../../types';
import {
	backgroundColorSecondary,
	borderRadiusFull,
	defaultSpacing,
	doubleSpacing,
	errorColorDefault,
	fontFamily,
	fontSizeBody2,
	fontSizeBody3,
	halfSpacing,
	infoColorDefault,
	primaryColorContrastText,
	primaryColorDefault,
	successColorDefault,
	textColorDefault,
	warningColorDefault,
} from '../../../theme/selectors';

const chipColorToChipStyles: Record<FuseChipColor, cssHelperType> = {
	primary: css`
		background-color: ${primaryColorDefault};
		color: ${primaryColorContrastText};
	`,
	secondary: css`
		background-color: ${backgroundColorSecondary};
		color: ${textColorDefault};
	`,
	info: css`
		background-color: ${infoColorDefault}1F;
		color: ${infoColorDefault};
	`,
	error: css`
		background-color: ${errorColorDefault}1F;
		color: ${errorColorDefault};
	`,
	warning: css`
		background-color: ${warningColorDefault}1F;
		color: ${warningColorDefault};
	`,
	success: css`
		background-color: ${successColorDefault}1F;
		color: ${successColorDefault};
	`,
};

const chipSizeToChipStyles: Record<FuseChipSize, cssHelperType> = {
	md: css`
		height: 2rem;
		padding: 0 ${doubleSpacing};
		font-size: ${fontSizeBody2};
	`,
	sm: css`
		height: 1.5rem;
		padding: 0 ${defaultSpacing};
		font-size: ${fontSizeBody3};
	`,
};

export const SChip = styled.div<ISChipProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: ${halfSpacing};
	border-radius: ${borderRadiusFull};
	font-family: ${fontFamily};
	white-space: nowrap;

	${({ $color }): cssHelperType => chipColorToChipStyles[$color]};
	${({ $size }): cssHelperType => chipSizeToChipStyles[$size]};
`;

export const chipSizeToIconStyles: Record<FuseChipSize, cssHelperType> = {
	md: css`
		height: 1rem;
		width: 1rem;
	`,
	sm: css`
		height: 0.75rem;
		width: 0.75rem;
	`,
};

export const SClearIcon = styled(CgClose)<ISClearIconProps>`
	cursor: pointer;
	opacity: 0.7;
	transition: opacity 0.2s ease-in-out;

	:hover {
		opacity: 1;
	}

	${({ $size }): cssHelperType => chipSizeToIconStyles[$size]};
`;
