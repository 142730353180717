import { DateTime } from 'luxon';

export const TodayOption = {
	label: 'Today',
	value: {
		from: DateTime.now().startOf('day').toJSDate(),
		to: DateTime.now().endOf('day').toJSDate(),
	},
};
export const YesterdayOption = {
	label: 'Yesterday',
	value: {
		from: DateTime.now().minus({ day: 1 }).startOf('day').toJSDate(),
		to: DateTime.now().minus({ day: 1 }).endOf('day').toJSDate(),
	},
};

export const Last7DaysOption = {
	label: '7D',
	value: { from: DateTime.now().minus({ day: 7 }).startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() },
};
export const Last30DaysOption = {
	label: '30D',
	value: { from: DateTime.now().minus({ day: 30 }).startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() },
};
export const Last3MonthsOption = {
	label: '3M',
	value: { from: DateTime.now().minus({ month: 3 }).startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() },
};

export const Last6MonthsOption = {
	label: '6M',
	value: { from: DateTime.now().minus({ month: 6 }).startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() },
};
export const Last12months = {
	label: '12M',
	value: { from: DateTime.now().minus({ month: 12 }).startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() },
};
export const MYD = {
	label: 'MYD',
	value: { from: DateTime.now().startOf('month').startOf('day').toJSDate(), to: DateTime.now().plus({ millisecond: 1 }).endOf('day').toJSDate() },
};
export const YTD = { label: 'YTD', value: { from: DateTime.now().startOf('year').startOf('day').toJSDate(), to: DateTime.now().endOf('day').toJSDate() } };
