import type { PropsWithChildren } from 'react';
import React, { useEffect, useMemo } from 'react';
import type { IUser } from '@transact-client/commonUtils/auth';
import { fromAuth0ToUser } from '@transact-client/commonUtils/auth';
import { setUser, setUserToken } from '@transact-client/store/slices/auth';
import { LoadingIndicator } from '@transact-client/components/LoadingIndicator';
import { isNil } from 'lodash-es';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useRemoveAuthPersist } from '@transact-client/store/hooks';
import { useSelector } from 'react-redux';
import { selectUserSelectedDealershipId } from '@transact-client/store/selectors/auth.selectors';
import { useFetchDealershipQuery, useLazyFetchDealershipQuery } from '@transact-client/store/api/dealerships';
import { EOrganizationType, eOrganizationTypeToJSON } from '@shared/proto/organization/v1/enums/organizationType';
import { AccessDeniedPage } from '@transact-client/pages/client/AccessDenied';
import { invalidateTags } from '@transact-client/store/api/common.api';
import { ETagType } from '@transact-client/store/api/types';

export const FuseAuth0Provider = ({ children }: PropsWithChildren): JSX.Element => {
	const dispatch = useAppDispatch();
	const dealershipId = useSelector(selectUserSelectedDealershipId);
	const { isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently, error, logout } = useAuth0();
	const { data: dealership } = useFetchDealershipQuery(dealershipId, { skip: !dealershipId });
	const [fetchDealershipQuery] = useLazyFetchDealershipQuery();
	const removeAuthPersist = useRemoveAuthPersist();

	const isReloadingToken = useMemo(() => {
		const isMaster = user?.isMaster;
		const isCompany = user?.fuseOrgType === eOrganizationTypeToJSON(EOrganizationType.Company).toLowerCase();
		const isDealershipChanged = user?.fuseOrgId !== dealershipId?.toString();

		return isDealershipChanged && !isCompany && !isMaster && !isNil(dealership);
	}, [user, dealershipId, dealership]);

	const fuseLogout = (): void => {
		removeAuthPersist();
		logout({ logoutParams: { returnTo: window.location.origin } });
	};

	useEffect(() => {
		(async (): Promise<void> => {
			if (!isLoading && !isAuthenticated && !error) {
				loginWithRedirect();
			} else if (!isNil(user)) {
				let dealershipData = dealership;

				if (dealershipId && dealershipId !== dealershipData?.id) {
					const { data: dealershipDataFromFetch } = await fetchDealershipQuery(dealershipId);

					dealershipData = dealershipDataFromFetch;
				}

				const token = await getAccessTokenSilently({
					authorizationParams: { organization: dealershipData?.authOrganizationId },
					cacheMode: isReloadingToken ? 'off' : 'on',
				});

				const userObj: IUser = fromAuth0ToUser(token);

				if (!isNil(token) && !isNil(userObj)) {
					dispatch(setUser(userObj));
					dispatch(setUserToken(`Bearer ${token}`));
					dispatch(invalidateTags([{ type: ETagType.Unauthorized }]));
				} else {
					dispatch(setUserToken(null));
				}
			}
		})();
	}, [
		dispatch,
		getAccessTokenSilently,
		isAuthenticated,
		isLoading,
		loginWithRedirect,
		user,
		dealershipId,
		dealership?.authOrganizationId,
		dealership,
		isReloadingToken,
		error,
		fetchDealershipQuery,
	]);

	if (error) {
		return <AccessDeniedPage onAction={fuseLogout} errorMessage={error.message} />;
	}

	if (isLoading || !isAuthenticated || isReloadingToken) {
		return <LoadingIndicator />;
	}

	return <>{children}</>;
};
