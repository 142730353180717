import React, { forwardRef } from 'react';
import type { IFuseIconProps } from './types';
import { SIconWrapper } from './FuseIcon.styles';

export const FuseIcon = forwardRef<HTMLDivElement, IFuseIconProps>(
	({ size, color, icon: Icon, ...otherProps }: IFuseIconProps, ref): JSX.Element => (
		<SIconWrapper $size={size} $color={color} ref={ref} {...otherProps}>
			<Icon />
		</SIconWrapper>
	)
);

FuseIcon.displayName = 'FuseIcon';
