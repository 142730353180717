import styled, { css } from 'styled-components';
import type { IFuseLottieWrapperStylesProps } from './types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';
import type { StylesMixin } from '../../../types';

const stylesMixin: StylesMixin = (props: IFuseLottieWrapperStylesProps) => css`
	width: ${props.$width};
	height: ${props.$height};
`;

export const SLottieWrapper = styled.div<IFuseLottieWrapperStylesProps>`
	max-height: 100%;
	max-width: 100%;

	${getResponsiveStyles(stylesMixin)}
`;
