export interface IRippleSpanProps {
	$x: number;
	$y: number;
	$size: number;
}

export interface IRippleSpeedProps {
	$speed: number;
}

export interface IRippeProps {
	speed?: number;
}

export const rippleSpeedLarge = 4;
export const rippleSpeedSmall = 2;
