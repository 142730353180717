import styled from 'styled-components';
import type { Property } from 'csstype';
import type { ISImageProps } from './types';
import { EFuseAvatarSize } from './types';
import { fontSizeBody1, fontSizeBody2, fontSizeH1, fontSizeH2 } from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';

export const avatarSizeToDimensions: Record<EFuseAvatarSize, Property.Width> = {
	[EFuseAvatarSize.Sm]: '2rem',
	[EFuseAvatarSize.Md]: '2.5rem',
	[EFuseAvatarSize.Lg]: '5rem',
	[EFuseAvatarSize.Xl]: '7rem',
};

export const avatarSizeToFontSize: Record<EFuseAvatarSize, ThemeSelector<Property.FontSize>> = {
	[EFuseAvatarSize.Sm]: fontSizeBody2,
	[EFuseAvatarSize.Md]: fontSizeBody1,
	[EFuseAvatarSize.Lg]: fontSizeH2,
	[EFuseAvatarSize.Xl]: fontSizeH1,
};

export const SImage = styled.img<ISImageProps>`
	width: ${({ $width }): Property.Width => $width};
	height: ${({ $height }): Property.Height => $height};
	border-radius: 50%;
`;
