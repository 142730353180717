import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { $enum } from 'ts-enum-util';
import { pascalCase } from 'change-case';
import { EBreakpoint } from '../../types';
import { mediaQueries } from '../../utils/mediaQueries';
import { isBreakpointName } from '../../utils/isBreakpointName';

export const useActiveBreakpoint = (): { activeBreakpoint: EBreakpoint; activeBreakpointName: Uncapitalize<keyof typeof EBreakpoint> } => {
	const getActiveBreakpoint = useCallback((): EBreakpoint => {
		const bpName = Object.entries(mediaQueries).find(
			([key, value]) => isBreakpointName(key) && window.matchMedia(value.replace('@media ', '')).matches
		)?.[0];

		return $enum(EBreakpoint).getValueOrThrow(pascalCase(bpName ?? ''));
	}, []);

	const getActiveBreakpointName = useCallback((): Uncapitalize<keyof typeof EBreakpoint> => {
		const bpName = Object.entries(mediaQueries).find(
			([key, value]) => isBreakpointName(key) && window.matchMedia(value.replace('@media ', '')).matches
		)?.[0];

		return bpName as Uncapitalize<keyof typeof EBreakpoint>;
	}, []);

	const [activeBreakpoint, setActiveBreakpoint] = useState(getActiveBreakpoint());
	const [activeBreakpointName, setActiveBreakpointName] = useState(getActiveBreakpointName());

	useEffect(() => {
		const handleResize = debounce(() => {
			setActiveBreakpoint(getActiveBreakpoint());
			setActiveBreakpointName(getActiveBreakpointName());
		}, 300);

		window.addEventListener('resize', handleResize);

		return (): void => {
			window.removeEventListener('resize', handleResize);
		};
	}, [getActiveBreakpoint, getActiveBreakpointName]);

	return { activeBreakpoint, activeBreakpointName };
};
