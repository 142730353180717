import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import type { IFuseButtonProps } from './types';
import { Ripple, rippleSpeedLarge, rippleSpeedSmall } from './Ripple';
import { Button, ButtonIcon, SLoading } from './FuseButton.styles';

export const FuseButton = forwardRef<HTMLButtonElement, IFuseButtonProps>(
	(
		{
			dangerouslySetClassName,
			onClick,
			text,
			icon,
			isLoading,
			formId = '',
			isSubmit = false,
			disabled = false,
			color = 'primary',
			variant = 'filled',
			buttonHeight = 'sm',
			iconPlacement = 'start',
			isUppercase = true,
			'data-test-id': testId,
			...otherProps
		}: PropsWithChildren<IFuseButtonProps>,
		ref
	): JSX.Element => {
		const getRippleSpeed = (): number => (['sm', 'md'].includes(buttonHeight) ? rippleSpeedSmall : rippleSpeedLarge);

		return (
			<Button
				className={clsx(dangerouslySetClassName)}
				type={isSubmit ? 'submit' : 'button'}
				form={isSubmit ? formId : null}
				color={color}
				variant={variant}
				buttonHeight={buttonHeight}
				onClick={onClick}
				disabled={isLoading || disabled}
				iconPlacement={iconPlacement}
				isLoading={isLoading}
				ref={ref}
				isUppercase={isUppercase}
				data-test-id={`${testId}-button`}
				data-test-loading={isLoading ? true : undefined}
				data-test-disabled={isLoading || disabled ? true : undefined}
				{...otherProps}
			>
				{isLoading ? (
					<SLoading color={color} size={10} />
				) : (
					<>
						{!!icon && (
							<ButtonIcon buttonHeight={buttonHeight} data-test-id={`${testId}-icon`}>
								{icon}
							</ButtonIcon>
						)}
						{text}

						{!disabled && <Ripple speed={getRippleSpeed()} />}
					</>
				)}
			</Button>
		);
	}
);

FuseButton.displayName = 'FuseButton';
