import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import type { IFuseFlexProps } from './types';
import { Flex } from './FuseFlex.styles';

export const FuseFlex = forwardRef<HTMLDivElement, PropsWithChildren<IFuseFlexProps>>(
	(
		{
			as = 'div',
			dangerouslySetClassName,
			inline = false,
			direction,
			wrap,
			justifyContent,
			alignContent,
			alignItems,
			gap,
			width = 'auto',
			height = 'auto',
			flex,
			basis,
			grow,
			shrink,
			alignSelf,
			children,
			...otherProps
		}: PropsWithChildren<IFuseFlexProps>,
		ref
	): JSX.Element => (
		<Flex
			className={clsx(dangerouslySetClassName)}
			as={as}
			$inline={inline}
			$direction={direction}
			$wrap={wrap}
			$justifyContent={justifyContent}
			$alignContent={alignContent}
			$alignItems={alignItems}
			$gap={gap}
			$width={width}
			$height={height}
			$flex={flex}
			$basis={basis}
			$grow={grow}
			$shrink={shrink}
			$alignSelf={alignSelf}
			ref={ref}
			{...otherProps}
		>
			{children}
		</Flex>
	)
);

FuseFlex.displayName = 'FuseFlex';
