import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import type { cssHelperType, StylesMixin, Transient } from '../../../types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';
import {
	disabledColorDefault,
	errorColorDefault,
	fontFamily,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody3,
	fontSizeBody4,
	fontSizeDisclaimer,
	fontSizeH1,
	fontSizeH2,
	fontSizeH3,
	fontSizeH4,
	fontWeightBold,
	fontWeightDefault,
	fontWeightMd,
	infoColorDefault,
	primaryColorDefault,
	successColorDefault,
	textColorDefault,
	textColorSecondary,
	warningColorDefault,
	whiteColor,
} from '../../../theme/selectors';
import type { FuseTypographyColor, FuseTypographyWeight, IFuseTypographyProps } from './types';

const colorToStyles: Record<FuseTypographyColor, cssHelperType> = {
	text: css`
		color: ${textColorDefault};
	`,
	primary: css`
		color: ${primaryColorDefault};
	`,
	secondary: css`
		color: ${textColorSecondary};
	`,
	disabled: css`
		color: ${disabledColorDefault};
	`,
	success: css`
		color: ${successColorDefault};
	`,
	error: css`
		color: ${errorColorDefault};
	`,
	info: css`
		color: ${infoColorDefault};
	`,
	warning: css`
		color: ${warningColorDefault};
	`,
	contrast: css`
		color: ${whiteColor};
	`,
};
const weightToStyles: Record<FuseTypographyWeight, cssHelperType> = {
	light: css`
		font-weight: ${fontWeightDefault};
	`,
	regular: css`
		font-weight: ${fontWeightDefault};
	`,
	medium: css`
		font-weight: ${fontWeightMd};
	`,
	bold: css`
		font-weight: ${fontWeightBold};
	`,
};

const stylesMixin: StylesMixin = (props: Transient<IFuseTypographyProps>) => css`
	white-space: ${props.$whiteSpace};
`;

const commonStyles = css<Transient<IFuseTypographyProps>>`
	font-family: ${fontFamily};
	text-transform: ${(props): Property.TextTransform => (props.$uppercase ? 'uppercase' : 'none')};
	${getResponsiveStyles(stylesMixin)}
	${(props): cssHelperType => colorToStyles[props.$color]}
    ${(props): cssHelperType => weightToStyles[props.$weight]}
`;

export const H1 = styled.h1`
	${commonStyles};
	font-size: ${fontSizeH1};
	font-weight: ${fontWeightMd};
`;

export const H2 = styled.h2`
	${commonStyles};
	font-size: ${fontSizeH2};
	font-weight: ${fontWeightMd};
`;

export const H3 = styled.h3`
	${commonStyles};
	font-size: ${fontSizeH3};
	font-weight: ${fontWeightMd};
`;

export const H4 = styled.h4`
	${commonStyles};
	font-size: ${fontSizeH4};
`;

export const Body1 = styled.p`
	${commonStyles};
	font-size: ${fontSizeBody1};
`;

export const Body2 = styled.p`
	${commonStyles};
	font-size: ${fontSizeBody2};
`;

export const Body3 = styled.p`
	${commonStyles};
	font-size: ${fontSizeBody3};
`;

export const Body4 = styled.p`
	${commonStyles};
	font-size: ${fontSizeBody4};
`;

export const Disclaimer = styled.p`
	${commonStyles};
	font-size: ${fontSizeDisclaimer};
	font-style: italic;
`;
