import type { Middleware } from '@reduxjs/toolkit';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { isEqual, partition, set, uniqWith } from 'lodash-es';
import { hasPendingQueries } from '../api/utils';
import { selectPendingInvalidations, setPendingInvalidations } from '../slices/pendingInvalidations';
import { api, immediatelyInvalidateTags } from '../api/common.api';

/**
 * This middleware listens to all fulfilled/rejected queries and invalidates any tags that have been pending on that query's end.
 * @param dispatch
 * @param getState
 */
export const pendingInvalidationsHandler: Middleware =
	({ dispatch, getState }) =>
	(next) => {
		return (action) => {
			const isQueryEnd = isFulfilled(action) || isRejected(action);
			const pendingInvalidations = selectPendingInvalidations(getState());

			if (isQueryEnd && pendingInvalidations.length) {
				const state = getState();

				// Find any pending invalidations that are relevant to the current query and don't have any more relevant queries that are pending.
				const [relevantInvalidations, otherInvalidations] = partition(pendingInvalidations, ({ endpoints }) => {
					const [relevantEndpoints, otherEndpoints] = partition(
						endpoints,
						({ endpointName, args }) => isEqual(action?.meta?.arg?.endpointName, endpointName) && isEqual(action?.meta?.arg?.originalArgs, args)
					);

					const isInvalidationRelevantForCurrentQuery = relevantEndpoints.length > 0;
					const hasPendingOtherQueries = hasPendingQueries(
						state,
						otherEndpoints.map(({ endpointName, args }) => ({ endpointName, originalArgs: args }))
					);

					return isInvalidationRelevantForCurrentQuery && !hasPendingOtherQueries;
				});

				if (relevantInvalidations.length) {
					const [invalidationsWithForceRefetch, invalidationsWithoutForceRefetch] = partition(
						relevantInvalidations,
						({ isForceRefetch }) => isForceRefetch
					);

					const tagsToInvalidateImmediately = invalidationsWithoutForceRefetch.reduce((result, { tags }) => [...result, ...tags], []);

					// Remove the relevant invalidations from the pending invalidations array
					dispatch(setPendingInvalidations(otherInvalidations));

					// If this is a successful request, maintain the current data in the store and don't update it with the new data
					// since a new request will be made soon anyway, and we don't want to show stale data in the UI.
					if (isFulfilled(action)) {
						const query = state?.api?.queries?.[action?.meta?.arg?.queryCacheKey];

						set(action, 'payload', query?.data);
						set(action, 'meta.baseQueryMeta.isAboutToBeInvalidated', true);
					}

					// Let the fulfilled/rejected action finish running
					const result = next(action);

					// Invalidate the tags
					if (tagsToInvalidateImmediately.length) {
						dispatch(immediatelyInvalidateTags(tagsToInvalidateImmediately));
					}

					const endpoints = uniqWith(
						invalidationsWithForceRefetch.reduce((res, invalidation) => [...res, ...invalidation.endpoints], []),
						isEqual
					);

					endpoints.forEach(({ endpointName, args }) => {
						const apiEndpoint = api?.endpoints?.[endpointName];

						if (apiEndpoint) {
							const { unsubscribe } = dispatch(apiEndpoint.initiate(args, { forceRefetch: true }));

							unsubscribe();
						}
					});

					return result;
				}

				return next(action);
			}

			return next(action);
		};
	};
