import type { IFuseToastByType } from './types';

export const toastsByPositionInitialValue: IFuseToastByType = {
	'top-start': [],
	'top-center': [],
	'top-end': [],
	'bottom-start': [],
	'bottom-center': [],
	'bottom-end': [],
};
