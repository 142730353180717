import type { PropsWithChildren } from 'react';
import React, { memo } from 'react';
import type { IFuseToastProps } from './types';
import { SProgressBar, SToastContainer } from './FuseToast.styles';
import { FuseAlertBase } from '../../internal/FuseAlertBase';

export const FuseToast = memo(
	({
		color = 'primary',
		position = 'bottom-center',
		disableAutoHide,
		autoHideDuration,
		onClose,
		hideIcon,
		'data-test-id': testId,
		children,
		...otherProps
	}: PropsWithChildren<IFuseToastProps>): JSX.Element => {
		const dataTestId = testId ?? `${otherProps.id}-${color}-toast`;

		return (
			<SToastContainer key={`toast-${otherProps.id}`} data-test-id={dataTestId} $position={position}>
				<FuseAlertBase data-test-id={`${dataTestId}-toast`} onClose={onClose} color={color} hideIcon={hideIcon} {...otherProps}>
					{children}

					{!disableAutoHide && <SProgressBar $duration={autoHideDuration} $color={color} onAnimationEnd={onClose} />}
				</FuseAlertBase>
			</SToastContainer>
		);
	}
);
