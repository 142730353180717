import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import { PulseLoader } from 'react-spinners';
import {
	borderRadiusDefault,
	defaultSpacing,
	disabledColorDefault,
	errorColorDark,
	errorColorDefault,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeH4,
	fontWeightMd,
	infoColorDark,
	infoColorDefault,
	inputPaddingInline,
	primaryColorContrastText,
	primaryColorDark,
	primaryColorDefault,
	shadowDefault,
	successColorDark,
	successColorDefault,
	warningColorDark,
	warningColorDefault,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';
import type { FuseButtonColor, IFuseButtonStyleProps } from './types';
import type { cssHelperType } from '../../../types';

const buttonColorStyles: Record<FuseButtonColor, ThemeSelector<Property.Color>> = {
	success: successColorDefault,
	primary: primaryColorDefault,
	info: infoColorDefault,
	error: errorColorDefault,
	warning: warningColorDefault,
};
const buttonActiveColorStyles: Record<FuseButtonColor, ThemeSelector<Property.Color>> = {
	success: successColorDark,
	primary: primaryColorDark,
	info: infoColorDark,
	error: errorColorDark,
	warning: warningColorDark,
};

export const SLoading = styled(PulseLoader).attrs(({ color }: IFuseButtonStyleProps) => ({
	size: 10,
	color: buttonColorStyles[color],
}))``;

export const Button = styled.button<IFuseButtonStyleProps>`
	padding: 0 ${inputPaddingInline};
	width: 100%;
	border: 1px solid transparent;
	border-radius: ${borderRadiusDefault};
	display: flex;
	gap: ${defaultSpacing};
	text-transform: ${({ isUppercase }): Property.TextTransform => (isUppercase ? 'uppercase' : 'none')};
	font-weight: ${fontWeightMd};
	flex-direction: ${({ iconPlacement }): Property.FlexDirection => (iconPlacement === 'start' ? 'row' : 'row-reverse')};
	justify-content: center;
	position: relative;
	align-items: center;
	cursor: pointer;
	box-shadow: ${({ variant, theme }): Property.BoxShadow => (variant === 'filled' ? shadowDefault({ theme }) : 'none')};

	& > span > span {
		background-color: ${({ variant, color, theme }): string =>
			variant === 'outlined' ? buttonColorStyles[color]({ theme }) : primaryColorContrastText({ theme })};
		width: 0.5rem;
		height: 0.5rem;
	}

	${({ color, variant, isLoading }): cssHelperType =>
		({
			filled: css`
				color: ${primaryColorContrastText};
				background-color: ${buttonColorStyles[color]};

				:active,
				:hover {
					color: ${primaryColorContrastText};
					background-color: ${buttonActiveColorStyles[color]};
				}

				&:disabled {
					background-color: ${({ theme }): Property.Color => (isLoading ? buttonColorStyles[color]({ theme }) : disabledColorDefault({ theme }))};
					opacity: ${(): Property.Opacity => (isLoading ? '0.6' : '1')};
					cursor: not-allowed;
				}
			`,
			outlined: css`
				color: ${buttonColorStyles[color]};
				background-color: transparent;
				border-color: ${buttonColorStyles[color]};

				:active,
				:hover {
					color: ${buttonActiveColorStyles[color]};
					border-color: ${buttonActiveColorStyles[color]};
				}

				&:disabled {
					color: ${disabledColorDefault};
					opacity: ${(): Property.Opacity => (isLoading ? '0.6' : '1')};
					border-color: ${disabledColorDefault};
					cursor: not-allowed;
				}
			`,
			light: css`
				color: ${buttonColorStyles[color]};
				background-color: ${buttonColorStyles[color]}1A;

				:active:not(:disabled),
				:hover:not(:disabled) {
					color: ${primaryColorContrastText};
					background-color: ${buttonColorStyles[color]};
				}

				&:disabled {
					cursor: not-allowed;
				}
			`,
		})[variant] ?? null}

	${({ buttonHeight, iconPlacement }): cssHelperType =>
		({
			xl: css`
				height: 7.5rem;
				font-size: ${fontSizeH4};
				flex-direction: ${iconPlacement === 'start' ? 'column' : 'column-reverse'};
			`,
			lg: css`
				height: 5.625rem;
				font-size: ${fontSizeH4};
			`,
			md: css`
				height: 5rem;
				font-size: ${fontSizeBody1};
			`,
			sm: css`
				height: 2.625rem;
				font-size: ${fontSizeBody2};
			`,
			xs: css`
				height: 2rem;
				font-size: ${fontSizeBody2};
				padding: 0 0.6rem;
			`,
		})[buttonHeight] ?? null}
`;

export const ButtonIcon = styled.span<IFuseButtonStyleProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	${({ buttonHeight }): cssHelperType =>
		({
			xl: css`
				font-size: 2.625rem;
			`,
			lg: css`
				font-size: 2rem;
			`,
			md: css`
				font-size: 1.5rem;
			`,
			sm: css`
				font-size: 1rem;
			`,
			xs: css`
				font-size: 1rem;
			`,
		})[buttonHeight] ?? null}
`;
