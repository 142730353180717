import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './creditApplication.consts';

export const {
	reducer,
	actions: { changeCreditApplicationStep, setCreditApplicationStepLoading },
} = createSlice({
	name: 'creditApplication',
	initialState,
	reducers: {
		changeCreditApplicationStep: (state, action: PayloadAction<number>): void => {
			state.currentStep = action.payload;
		},
		setCreditApplicationStepLoading: (state, action: PayloadAction<boolean>): void => {
			state.isLoading = action.payload;
		},
	},
});
