import styled from 'styled-components';
import { FuseFlex } from '../../../components/layout/FuseFlex';
import { FuseTypography } from '../../../components/dataDisplay/FuseTypography';
import { defaultSpacing } from '../../../theme/selectors';

export const STemplateContainer = styled(FuseFlex).attrs(() => ({
	direction: 'column',
	gap: defaultSpacing,
}))`
	padding: ${defaultSpacing};
`;
export const STitle = styled(FuseTypography).attrs(() => ({
	variant: 'b2',
}))`
	margin: 0;
`;

export const SDescription = styled(FuseTypography).attrs(() => ({
	variant: 'b3',
	type: 'secondary',
}))`
	margin: 0;
`;
export const SButtonsContainer = styled(FuseFlex).attrs(() => ({
	gap: defaultSpacing,
}))``;
