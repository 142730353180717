import styled, { keyframes } from 'styled-components';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import type { Property } from 'csstype';
import {
	borderRadiusDefault,
	errorColorDefault,
	infoColorDefault,
	primaryColorDefault,
	successColorDefault,
	warningColorDefault,
} from '../../../theme/selectors';
import type { ThemeSelector } from '../../../theme/types';
import type { FuseToastColor, FuseToastPosition, ISFuseToastProps, ISProgressBarProps } from './types';

const positioning: Record<FuseToastPosition, MotionProps> = {
	'top-center': {
		initial: { y: '-100%' },
		animate: { y: 0 },
		exit: { y: '-100%' },
	},
	'top-start': {
		initial: { x: '-120%' },
		animate: { x: 0 },
		exit: { x: '-120%' },
	},
	'top-end': {
		initial: { x: '120%' },
		animate: { x: 0 },
		exit: { x: '120%' },
	},
	'bottom-center': {
		initial: { y: '100%' },
		animate: { y: 0 },
		exit: { y: '100%' },
	},
	'bottom-start': {
		initial: { x: '-120%' },
		animate: { x: 0 },
		exit: { x: '-120%' },
	},
	'bottom-end': {
		initial: { x: '120%' },
		animate: { x: 0 },
		exit: { x: '120%' },
	},
};

export const progressAnimation = keyframes`
  	from {
      width: 100%;
	}
	to {
	  width: 0;
	}
`;

export const typeToProgressBarColor: Record<FuseToastColor, ThemeSelector<string>> = {
	primary: primaryColorDefault,
	info: infoColorDefault,
	warning: warningColorDefault,
	error: errorColorDefault,
	success: successColorDefault,
};

export const SProgressBar = styled.div<ISProgressBarProps>`
	position: absolute;
	inset-block-end: 0;
	inset-inline-start: 0;
	height: 3px;
	animation: ${progressAnimation} linear forwards;
	animation-duration: ${({ $duration }): Property.AnimationDuration => `${$duration}ms`};
	animation-play-state: running;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		height: 100%;
		width: 100%;
		background-color: ${({ $color, theme }): Property.BackgroundColor => typeToProgressBarColor[$color]({ theme })};
	}
`;

export const SToastContainer = styled(motion.div).attrs<ISFuseToastProps, MotionProps>(({ key, $position }) => ({
	key,
	initial: positioning[$position].initial,
	animate: positioning[$position].animate,
	exit: positioning[$position].exit,
	layout: true,
}))<ISFuseToastProps>`
	position: relative;
	border-radius: ${borderRadiusDefault};
	width: fit-content;
	min-width: 270px;

	:hover {
		${SProgressBar} {
			animation-play-state: paused;
		}
	}
`;
