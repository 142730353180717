import type { ITheme } from './types';

export const commonTheme: Pick<
	ITheme,
	'fontFamily' | 'fontSize' | 'fontWeight' | 'spacing' | 'gap' | 'borderWidth' | 'borderRadius' | 'heights' | 'zIndex' | 'transitions' | 'constants'
> = {
	fontFamily: "'Roboto',sans-serif",
	fontSize: {
		h1: '2.25rem',
		h2: '1.625rem',
		h3: '1.375rem',
		h4: '1.125rem',
		body1: '1rem',
		body2: '0.875rem',
		body3: '0.75rem',
		body4: '0.625rem',
		disclaimer: '0.75rem',
	},
	fontWeight: {
		light: 300,
		default: 400,
		md: 500,
		bold: 700,
	},
	spacing: {
		noSpacing: 0,
		half: '0.25rem',
		default: '0.5rem',
		double: '1rem',
	},
	gap: {
		default: '0.75rem',
	},
	borderWidth: {
		default: '1px',
		double: '2px',
	},
	borderRadius: {
		half: '0.15625rem',
		default: '0.3125rem',
		round: '0.75rem',
		full: '5rem',
		circle: '50%',
	},
	heights: {
		input: {
			sm: '2.625rem',
			md: '5rem',
			lg: '5.625rem',
			xl: '7.5rem',
		},
	},
	zIndex: {
		footer: 750,
		sticky: 800,
		backdrop: 1000,
		drawer: 1100,
		modal: 1200,
		popover: 1300,
		tooltip: 1400,
		badge: 1500,
		datePicker: 1600,
		select: 1700,
		menu: 1800,
		toast: 2000,
	},
	transitions: {
		duration: {
			default: '0.2s',
			accordionExpansion: '0.3s',
			progressBar: '0.5s',
			rippleEnter: '850',
			rippleExit: '400',
		},
	},
	constants: {
		button: {
			borderRadius: '0.375rem',
			gap: '0.75rem',
		},
		card: { borderRadius: '0.3125rem' },
		input: { paddingInline: '1.25rem', paddingBlock: '0.75rem' },
		icon: { borderRadius: '0.375rem' },
		header: {
			height: '4.375rem',
		},
		form: {
			padding: '1rem',
		},
		section: {
			spacing: {
				default: '1.875rem',
			},
		},
	},
};
