import styled, { css } from 'styled-components';
import type { IFuseFlexItemProps } from './types';
import type { StylesMixin, Transient } from '../../../types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';

const stylesMixin: StylesMixin = (props: Transient<IFuseFlexItemProps>) => css`
	flex-basis: ${props.$basis};
	flex-grow: ${props.$grow};
	flex-shrink: ${props.$shrink};
	align-self: ${props.$alignSelf};
`;

export const FlexItem = styled.div<Transient<IFuseFlexItemProps>>`
	${getResponsiveStyles(stylesMixin)};
`;
