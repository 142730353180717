import { isEqual, toString } from 'lodash-es';
import type { ChangeEventHandler, PropsWithChildren } from 'react';
import React, { forwardRef, useContext } from 'react';
import { SCheckedIcon, SChildrenWrapper, SIconAndLabelWrapper, SLabelAndCheckmarkWrapper, SRadioButton, SUncheckedIcon } from './FuseRadio.styles';
import { FuseRadioGroupContext } from './FuseRadioGroup.context';
import type { IFuseRadioProps } from './types';
import { FuseTypography } from '../../dataDisplay/FuseTypography';
import { FuseSkeleton } from '../../dataDisplay/FuseSkeleton';

export const FuseRadio = forwardRef<HTMLInputElement, PropsWithChildren<IFuseRadioProps<unknown>>>(
	<T,>(
		{
			value,
			label,
			isDisabled = false,
			isLoading = false,
			icon: Icon,
			'data-test-id': testId,
			'data-test-value': testValue,
			children,
			...otherProps
		}: PropsWithChildren<IFuseRadioProps<T>>,
		ref
	): JSX.Element => {
		const context = useContext(FuseRadioGroupContext);

		const handleClick = (): void => {
			if (!isDisabled && !isLoading) {
				context?.onChange?.(value);
			}
		};

		const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
			context?.onChange?.(event.target.checked);
		};

		const isSelected = isEqual(value, context?.value);

		return (
			<>
				<SRadioButton
					$isSelected={isSelected}
					$isDisabled={isDisabled}
					$isLoading={isLoading}
					$hasIcon={!!Icon}
					onClick={handleClick}
					data-test-id={`${testId}-radio`}
					data-test-value={testValue}
				>
					<SLabelAndCheckmarkWrapper>
						<SIconAndLabelWrapper>
							{Icon && (isLoading ? <FuseSkeleton type="circle" width="1rem" height="1rem" /> : <Icon />)}

							{label &&
								(isLoading ? (
									<FuseSkeleton type="text" typographyType="b1" width="6rem" />
								) : (
									<FuseTypography variant="b1" as="span">
										{label}
									</FuseTypography>
								))}
						</SIconAndLabelWrapper>

						{isSelected ? <SCheckedIcon /> : <SUncheckedIcon />}
					</SLabelAndCheckmarkWrapper>

					{children && <SChildrenWrapper>{children}</SChildrenWrapper>}
				</SRadioButton>

				<input hidden type="radio" value={toString(value)} checked={isSelected} ref={ref} onChange={handleInputChange} {...otherProps} />
			</>
		);
	}
);

FuseRadio.displayName = 'FuseRadio';
