import type { InputHTMLAttributes, ReactNode } from 'react';
import type { Property } from 'csstype';
import type { IFuseCommonProps } from '../../../types';

export enum EFuseInputBaseLabelType {
	Primary = 'primary',
	Secondary = 'secondary',
}

export enum EFuseInputBaseElementSize {
	Sm = 'sm',
	Md = 'md',
}

export interface IFuseInputBaseProps extends InputHTMLAttributes<HTMLInputElement>, Omit<IFuseCommonProps, 'as' | 'data-test-id'> {
	error?: string;
	isLoading?: boolean;
	label?: string;
	labelType?: EFuseInputBaseLabelType;
	tooltipContent?: ReactNode;
	defaultValue?: string | number;
	startElement?: ReactNode;
	startElementSize?: EFuseInputBaseElementSize;
	hideStartElementBackground?: boolean;
	endElement?: ReactNode;
	'data-test-id': string;
	transform?: (value: string | number) => string | number;
	height?: Property.Height;
}

export interface ISInputAndIconsWrapperProps {
	$isDisabled?: boolean;
	$height?: Property.Height;
}

export interface ISInputProps {
	isLoading?: boolean;
	hasError?: boolean;
	hasStartElement?: boolean;
	hasEndElement?: boolean;
	startElementSize?: EFuseInputBaseElementSize;
}

export interface ISStartElementWrapperProps {
	$startElementSize?: EFuseInputBaseElementSize;
	$hideBackground?: boolean;
	$isDisabled?: boolean;
}

export interface ISEndElementWrapperProps {
	$isDisabled?: boolean;
}

export interface IFuseInputBaseLabelStylesProps {
	type?: EFuseInputBaseLabelType;
	isRequired?: boolean;
}
