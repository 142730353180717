export const formatAsCurrency = (num: number, minimumFractionDigits = 2): string =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits,
	}).format(num);

export const formatAsNumber = (num: number, minimumFractionDigits = 2, maximumFractionDigits = undefined): string =>
	new Intl.NumberFormat('en-US', {
		minimumFractionDigits,
		maximumFractionDigits,
	}).format(num);
