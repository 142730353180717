import React from 'react';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { logger } from '@transact-client/commonUtils/Logger';
import { type Primitive } from '@sentry/types/types/misc';

const { SENTRY_DSN, RELEASE_VERSION } = process.env;
const TRACES_SAMPLE_RATE = parseFloat(process.env.TRACES_SAMPLE_RATE || '1.0');
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';
const IGNORE_ERRORS = REACT_APP_ENVIRONMENT === 'local' ? ['*'] : [''];

export const { ErrorBoundary } = Sentry;
export type { FallbackRender } from '@sentry/react/types/errorboundary';

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

class ErrorReporter {
	private reduxEnhancer = Sentry.createReduxEnhancer({});
	init(): void {
		try {
			if (!SENTRY_DSN) {
				logger.warn('Sentry DSN is missing');

				return;
			}

			const tracingOrigin = `${process.env.ENDPOINT}/api`;

			Sentry.init({
				release: RELEASE_VERSION,
				dsn: SENTRY_DSN,
				integrations: [
					Sentry.reactRouterV6BrowserTracingIntegration({
						useEffect: React.useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes,
					}),
				],
				tracesSampleRate: TRACES_SAMPLE_RATE,
				environment: REACT_APP_ENVIRONMENT,
				normalizeDepth: 10,
				sendClientReports: false,
				ignoreErrors: IGNORE_ERRORS,
				tracePropagationTargets: [tracingOrigin],
			});

			logger.info(`Sentry has been initialized!`, {
				dsn: SENTRY_DSN,
				tracesSampleRate: TRACES_SAMPLE_RATE,
				environment: REACT_APP_ENVIRONMENT,
			});
		} catch (e) {
			logger.error('ErrorReporter:init failed', e);
		}
	}
	setUser(user: Sentry.User): void {
		Sentry.setUser(user);
	}
	setExtra(label: string, value: unknown): void {
		Sentry.setExtra(label, value);
	}
	setTag(label: string, value: Primitive): void {
		Sentry.setTag(label, value);
	}
	getReduxEnhancer(): any {
		return this.reduxEnhancer;
	}
}

export const errorReporter = new ErrorReporter();
