import type { IFuseModalBaseProps } from '../../../components/internal/FuseModalBase';

export enum EModalSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	ExtraLarge = 'extra-large',
}

export interface IFuseModalProps extends Omit<IFuseModalBaseProps, 'showAsDrawer' | 'isFullScreen'> {}

export interface ISModalProps {
	$isFixedHeight?: boolean;
	$size?: EModalSize;
}
