import type { Property } from 'csstype';
import type { ThemeSelector } from '../types';
import { borderColorDefault, borderColorSelected } from './colorSelectors';

export const borderWidthDefault: ThemeSelector<Property.BorderWidth> = ({ theme }) => theme.borderWidth.default;
export const borderWidthDouble: ThemeSelector<Property.BorderWidth> = ({ theme }) => theme.borderWidth.double;

export const borderDefault: ThemeSelector<Property.Border> = ({ theme }) => `${borderWidthDefault({ theme })} solid ${borderColorDefault({ theme })}`;

export const borderSelected: ThemeSelector<Property.Border> = ({ theme }) => `${borderWidthDefault({ theme })} solid ${borderColorSelected({ theme })}`;

export const borderRadiusHalf: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.borderRadius.half;
export const borderRadiusDefault: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.borderRadius.default;
export const borderRadiusRound: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.borderRadius.round;
export const borderRadiusFull: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.borderRadius.full;
export const borderRadiusCircle: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.borderRadius.circle;
