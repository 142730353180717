import styled, { css } from 'styled-components';
import type { StylesMixin, Transient } from '../../../types';
import { getResponsiveStyles } from '../../../utils/getResponsiveStyles';
import type { IFuseSpacerProps } from './types';

const stylesMixin: StylesMixin = (props: Transient<IFuseSpacerProps>) => css`
	width: ${props.$width};
	height: ${props.$height};
	flex-basis: ${props.$basis};
	flex-grow: ${props.$grow};
	flex-shrink: ${props.$shrink};
`;

export const Spacer = styled.div<Transient<IFuseSpacerProps>>`
	${getResponsiveStyles(stylesMixin)}
`;
