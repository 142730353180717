import type { Property } from 'csstype';
import type { ThemeSelector } from '../types';

export const zIndexSelect: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.select;
export const zIndexMenu: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.menu;
export const zIndexBadge: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.badge;
export const zIndexSticky: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.sticky;
export const zIndexBackdrop: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.backdrop;
export const zIndexDrawer: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.drawer;
export const zIndexModal: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.modal;
export const zIndexTooltip: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.tooltip;
export const zIndexPopover: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.popover;
export const zIndexDatePicker: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.datePicker;
export const ZIndexToast: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.toast;
export const zIndexFooter: ThemeSelector<Property.ZIndex> = ({ theme }) => theme.zIndex.footer;
