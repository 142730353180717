import type { LDClient, LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { initialize as initializeLdClient } from 'launchdarkly-js-client-sdk';
import type { IDealership } from '@shared/proto/dealership/v1/messages/Dealership';

class LaunchDarklyClient {
	private static instance: LaunchDarklyClient;
	private static client: LDClient;
	private static flags: Promise<LDFlagSet>;

	private constructor() {
		// Private for preventing the use of new, Do not remove constructor!
	}

	public static getInstance(): LaunchDarklyClient {
		if (!LaunchDarklyClient.instance) {
			LaunchDarklyClient.instance = new LaunchDarklyClient();
		}

		return LaunchDarklyClient.instance;
	}

	async initialize(dealershipId: IDealership['id'], name: string, email: string): Promise<void> {
		if (!LaunchDarklyClient.instance) {
			throw new Error('No instance of LaunchDarklyClient exist, must call getInstance() first');
		}

		LaunchDarklyClient.flags = new Promise((resolve, reject) => {
			LaunchDarklyClient.client = initializeLdClient(process.env['LD_CLIENT_ID'], { key: `${dealershipId}`, name, email });

			LaunchDarklyClient.client.on('initialized', () => {
				resolve(LaunchDarklyClient.client.allFlags());
			});

			LaunchDarklyClient.client.on('failed', reject);
		});
	}

	subscribeToChanges(onChange: (flags: LDFlagChangeset) => void): void {
		if (!LaunchDarklyClient.client) {
			throw new Error('LDClient was not initialized, must call initialize() first');
		}

		LaunchDarklyClient.client.on('change', onChange);
	}

	setUser(dealershipId: IDealership['id'], name: string, email: string): LaunchDarklyClient {
		if (!LaunchDarklyClient.client) {
			throw new Error('LDClient was not initialized, must call initialize() first');
		}

		LaunchDarklyClient.client.identify({ key: `${dealershipId}`, name, email });

		return LaunchDarklyClient.getInstance();
	}

	getFlags(): Promise<LDFlagSet> {
		if (!LaunchDarklyClient.flags) {
			throw new Error('LDFlagSet was not initialized, must call initialize() first');
		}

		return LaunchDarklyClient.flags;
	}
}

export const launchDarklyClient = LaunchDarklyClient.getInstance();
