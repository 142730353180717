import type { IconType } from 'react-icons';
import type { Property } from 'csstype';
import type { IFuseDataTestProps, ILoadableCommonProps } from '../../../types';

export enum ETableSelectType {
	NONE = 'none',
	SINGLE = 'single',
	MULTIPLE = 'multiple',
}

export interface IRowSingleSelectProps<T> {
	rowId?: IFuseTableRowId;
	rowData?: T;
}
export interface IRowMultipleSelectProps<T> {
	rowIds?: IFuseTableRowId[];
	rowsData?: T[];
}

export type OnRowSingleSelect<T> = ({ rowId, rowData }: IRowSingleSelectProps<T>) => void;
export type OnRowMultiSelect<T> = ({ rowIds, rowsData }: IRowMultipleSelectProps<T>) => void;

export interface IFuseTableProps<T, SelectType extends ETableSelectType = ETableSelectType.NONE>
	extends ILoadableCommonProps,
		Omit<IFuseDataTestProps, 'data-test-id'> {
	defaultSort?: ITableDataSortConfig;
	getRowDataId: (rowData: T) => IFuseTableRowId;
	columnsConfig: ITableColumnConfig<T>[];
	actions?: IFuseTableActions<T>[];
	data: T[];
	summary?: T[];
	onSortChange?: ({ labelName, direction }: ITableDataSortConfig) => void;
	onRowClick?: (rowData: T) => void;
	onRowSelect?: SelectType extends ETableSelectType.SINGLE
		? OnRowSingleSelect<T>
		: SelectType extends ETableSelectType.MULTIPLE
			? OnRowMultiSelect<T>
			: undefined;
	selectType?: SelectType;
	isDisabled?: (rowData: T) => boolean;
	'data-test-id': string;
	loadingRowsLength?: number;
	selectedRowIds?: SelectType extends ETableSelectType.SINGLE
		? IFuseTableRowId
		: SelectType extends ETableSelectType.MULTIPLE
			? IFuseTableRowId[]
			: undefined;
	hideSelectionIndication?: SelectType extends ETableSelectType.NONE ? undefined : boolean;
	isSelectableOnRowClick?: SelectType extends ETableSelectType.NONE ? undefined : boolean;
	isSelectRowDisabled?: SelectType extends ETableSelectType.NONE ? undefined : boolean;
	disableRowBorder?: boolean;
}

export interface IFuseTableHeaderStylesProps {
	columnAlignment?: Property.TextAlign;
}

export interface ITableColumnProps {
	$width: Property.Width;
}

export interface IFuseTableActions<T> {
	name: string;
	icon: IconType;
	cb: (rowData: T) => void;
	conditions: (rowData: T) => boolean;
}

export interface ITableDataSortConfig {
	labelName?: string;
	direction?: ESortDirection;
}

export type IFuseTableColumnName<T> = keyof T;
export type IFuseTableRowId = string | number;

export interface IFuseTableComponentProps<T> extends ILoadableCommonProps {
	rowData: T;
	testIdPrefix: string;
	isFooter: boolean;
}

export type IFuseTableComponent<T> = (props: IFuseTableComponentProps<T>) => JSX.Element;
export type IFuseTableSortComparator<T> = (a: T, b: T) => number;

export interface ITableColumnConfig<T> {
	label?: string;
	isSortable?: boolean;
	width?: string;
	component: IFuseTableComponent<T>;
	columnName: IFuseTableColumnName<T>;
	sortComparator?: IFuseTableSortComparator<T>;
	isHidden?: boolean;
}

export enum ESortDirection {
	ASC = 1,
	DESC = -1,
}
