import type { SignaturePadManager } from './SignaturePadManager';

export interface IFuseSignaturePadIntegrationProps {
	onSignatureCapture: (sig: string) => void;
	canvasId: string;
	signaturePadManager: SignaturePadManager;
	signaturePadStatus: ESignaturePadStatus;
	setSignaturePadStatus: (status: ESignaturePadStatus) => void;
}

export interface IStartTablet extends ISignaturePadStart {
	setSignaturePadStatus: (status: number) => void;
}

export interface ISignaturePadStart {
	onSignatureCapture: (sig: string) => void;
	canvasId: string;
}

export enum ESignaturePadStatus {
	Connecting = 1,
	CannotRecognizeWorkingSignaturePad = 2,
	CommunicationError = 3,
	Connected = 4,
}

export interface ISignaturePadProvider {
	isDriverInstalled: boolean;
	start: (ops: IStartTablet) => void;
	clear: () => void;
	close: () => void;
	status: ESignaturePadStatus;
	checkIsDriverInstalled: () => Promise<boolean>;
	name: string;
}

export interface ISignaturePadManager extends ISignaturePadProvider {
	isInitiated: boolean;
	start: (ops: ISignaturePadStart) => void;
	onStatusChange: (status: ESignaturePadStatus) => void;
}

export enum ESignaturePadProvider {
	topaz = 0,
}
