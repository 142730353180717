import type { PropsWithChildren } from 'react';
import React from 'react';
import type { StyledComponent } from 'styled-components';
import { clsx } from 'clsx';
import type { IFuseTypographyProps } from './types';
import { Body1, Body2, Body3, Body4, Disclaimer, H1, H2, H3, H4 } from './FuseTypography.styles';

const TypographyComponent: Record<string, StyledComponent<any, any, any>> = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	b1: Body1,
	b2: Body2,
	b3: Body3,
	b4: Body4,
	disclaimer: Disclaimer,
};

export const FuseTypography = ({
	dangerouslySetClassName,
	as,
	inline = false,
	variant = 'b1',
	color = 'text',
	weight = 'regular',
	uppercase = false,
	whiteSpace,
	children,
	...otherProps
}: PropsWithChildren<IFuseTypographyProps>): JSX.Element => {
	const SelectedTypographyComponent = TypographyComponent[variant];

	if (!SelectedTypographyComponent) {
		return null;
	}

	return (
		<SelectedTypographyComponent
			className={clsx(dangerouslySetClassName)}
			as={as}
			$color={color}
			$inline={inline}
			$uppercase={uppercase}
			$weight={weight}
			$whiteSpace={whiteSpace}
			{...otherProps}
		>
			{children}
		</SelectedTypographyComponent>
	);
};
