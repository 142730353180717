import React from 'react';
import { doubleSpacing, FuseFlex, FuseTypography } from '@fuse/react-ui';
import { useTheme } from 'styled-components';
import { capitalize, isNil } from 'lodash-es';
import { SAccessDeniedItemsWrapper, SAccessDeniedPageWrapper, SBackButton, SFuseLogo, SMainImage } from './styles';

export interface IAccessDeniedPageProps {
	onAction: () => void;
	errorMessage?: string;
}

export const AccessDeniedPage = ({ onAction, errorMessage }: IAccessDeniedPageProps): JSX.Element => {
	const theme = useTheme();

	return (
		<SAccessDeniedPageWrapper>
			<SAccessDeniedItemsWrapper height="100%" direction="column" justifyContent="space-between" alignItems="center">
				<SFuseLogo src="images/fuseLogo.svg" alt="Access denied" height="53px" />
				<SMainImage src="images/access-denied.svg" alt="Access denied" />
				<FuseFlex direction="column" gap={doubleSpacing({ theme })} alignItems="center">
					<FuseTypography variant="h2">We are sorry...</FuseTypography>
					<FuseTypography variant="b1" color="secondary">
						The page you’re trying to access is restricted. Please refer to your system administrator
					</FuseTypography>
					{!isNil(errorMessage) && (
						<FuseTypography variant="b3" color="error">
							* {capitalize(errorMessage)}
						</FuseTypography>
					)}
					<SBackButton text="Go Back" data-test-id="logout-button" onClick={onAction} />
				</FuseFlex>
				<FuseTypography variant="b2" color="secondary">
					© Copyrights Fuse Autotech | All Rights Reserved
				</FuseTypography>
			</SAccessDeniedItemsWrapper>
		</SAccessDeniedPageWrapper>
	);
};
