import styled, { css } from 'styled-components';
import type { FuseToastPosition } from '../../../components/overlay/FuseToast/types';
import type { cssHelperType } from '../../../types';
import type { ISAnchorContainerProps } from './types';
import { ZIndexToast } from '../../../theme/selectors';

const positionToStyles: Record<FuseToastPosition, cssHelperType> = {
	'bottom-start': css`
		inset-inline-start: 1rem;
		inset-block-end: 1rem;
	`,
	'bottom-center': css`
		inset-inline-start: 50%;
		inset-block-end: 1rem;
		transform: translate(-50%, 0);
	`,
	'bottom-end': css`
		inset-inline-end: 1rem;
		inset-block-end: 1rem;
	`,
	'top-start': css`
		inset-inline-start: 1rem;
		inset-block-start: 1rem;
	`,
	'top-center': css`
		inset-inline-start: 50%;
		inset-block-start: 1rem;
		transform: translate(-50%, 0);
	`,
	'top-end': css`
		inset-inline-end: 1rem;
		inset-block-start: 1rem;
	`,
};

export const SAnchorContainer = styled.div<ISAnchorContainerProps>`
	position: fixed;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	z-index: ${ZIndexToast};
	max-width: 50%;

	${(props): cssHelperType => positionToStyles[props.$position]};
`;
