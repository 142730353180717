import type { ComponentProps, FunctionComponent } from 'react';
import { entries } from 'lodash-es';
import type { OverlaysState } from '../providers/OverlaysProvider/types';

let overlays: OverlaysState = {};
let listeners: (() => void)[] = [];

const emitChange = (): void => {
	listeners.forEach((listener) => {
		listener();
	});
};

export const overlaysStore = {
	subscribe(listener: () => void) {
		listeners.push(listener);

		return (): void => {
			listeners = listeners.filter((l) => l !== listener);
		};
	},
	getSnapshot(): OverlaysState {
		return overlays;
	},
};

/**
 * Shows an overlay. this is only to be used outside of components and hooks.
 * If you're in the context of a component or a hook, use `useOverlay`.
 * @param component
 * @param props
 */
export const showOverlay = <T extends FunctionComponent<unknown>>(component: T, props?: ComponentProps<T>): void => {
	const id = component?.name;

	overlays = { ...overlays, [id]: { id, component, props, isShown: true } };
	emitChange();
};

/**
 * Hides an overlay. This is only to be used outside of components and hooks.
 * If you're in the context of a component or a hook, use `useOverlay`.
 * @param id
 */
export const hideOverlay = (id: string): void => {
	if (overlays?.[id]?.isShown) {
		overlays = { ...overlays, [id]: { ...overlays[id], isShown: false } };
		emitChange();
	}
};

export const removeAllHiddenOverlays = (): void => {
	overlays = entries(overlays).reduce((result, [key, value]) => {
		if (value.isShown) {
			result[key] = value;
		}

		return result;
	}, {});
	emitChange();
};

export const removeAllOverlays = (): void => {
	overlays = {};
	emitChange();
};
