import type { Property } from 'csstype';
import type { BreakpointsMap, IFuseCommonProps } from '../../../types';

export interface IStep {
	title: string;
}

export interface IFuseStepperProps extends Omit<IFuseCommonProps, 'data-test-id'> {
	activeStepIndex: number;
	width: Property.Width | BreakpointsMap<Property.Width>;
	steps: IStep[];
	'data-test-id': string;
}

export interface IStepperStylesProps {
	width: Property.Width | BreakpointsMap<Property.Width>;
}

export enum EStepStatus {
	Inactive,
	Active,
	Completed,
}

export interface ISFuseStepperStepIndexProps {
	$status: EStepStatus;
}
