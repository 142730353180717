import Color from 'color';
import { colors } from './colors';
import type { IColorGroup } from './types';

const ratio = 0.5;

export const generateColorGroup = (color: string): IColorGroup => ({
	light: Color(color).lighten(ratio).hex(),
	default: color,
	dark: Color(color).darken(ratio).hex(),
	contrastText: Color(color).isDark() ? colors.main.white : colors.main.darkGray,
	background: `${color}1A`,
});
