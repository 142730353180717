import type { ChangeEvent, FocusEventHandler, KeyboardEvent, MouseEventHandler } from 'react';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { FaSearch as SearchIcon } from 'react-icons/fa';
import { useImmer } from 'use-immer';
import { clsx } from 'clsx';
import { autoUpdate, flip, size, useClick, useDismiss, useFloating, useFloatingPortalNode, useInteractions, useRole } from '@floating-ui/react';
import { escapeRegExp, isEqual, isNil } from 'lodash-es';
import { createPortal } from 'react-dom';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import type { IFuseSelectBaseProps, ISelectState, OnChangeMultiSelect, OnChangeSingleSelect, SelectOption } from './types';
import { EFuseSelectEndElementType } from './types';
import { ETooltipPlacement, FuseTooltip } from '../../dataDisplay/FuseTooltip';
import {
	SArrowDownIcon,
	SArrowUpIcon,
	SClearIcon,
	SDivider,
	SNoOptionsMessage,
	SOption,
	SOptionLabel,
	SOptionsList,
	SSelectInput,
	SSelectInputAndIconsWrapper,
} from './FuseSelectBase.styles';
import {
	EFuseInputBaseLabelType,
	ErrorIcon,
	ErrorMessage,
	ErrorWrapper,
	FuseInputBaseWrapper,
	Label,
	LabelAndTooltipWrapper,
	SEndElementWrapper,
	SLoader,
	SLoaderWrapper,
	SStartElementWrapper,
	TooltipIcon,
} from '../FuseInputBase';
import { FuseCheckbox } from '../../inputs/FuseCheckbox';
import { getInputValue } from './utils';

export const FuseSelectBase = forwardRef(
	<T, IsMultiSelect extends boolean = false>(
		{
			dangerouslySetClassName,
			options,
			value,
			error,
			label,
			tooltipContent,
			onClick,
			onChange,
			onInputChange,
			onFocus,
			autoComplete = false,
			required,
			hideClearIcon = false,
			isLoading = false,
			isMultiSelect,
			endElementType = EFuseSelectEndElementType.Arrow,
			disableBordersWhenClosed = true,
			'data-test-id': testId,
			disabled: isDisabled = false,
			maxOptionsInView = 5,
			placement = 'bottom',
			clearOnFocus = true,
			optionsListProps = null,
			optionProps = null,
			disallowEmptyValue = false,
			customInputValue,
			skipClickOutsideAction = false,
			...otherProps
		}: IFuseSelectBaseProps<T, IsMultiSelect>,
		ref
	): JSX.Element => {
		const [{ isOptionsListOpen, inputValue, highlightedIndex, pendingValues }, setSelectState] = useImmer<ISelectState<T>>({
			isOptionsListOpen: false,
			inputValue: customInputValue ?? getInputValue(value, isMultiSelect),
			highlightedIndex: null,
			pendingValues: null,
		});

		const handleOpenChange = (isOpen: boolean): void => {
			setSelectState((state) => {
				state.isOptionsListOpen = isOpen;
			});
		};

		const {
			x,
			y,
			strategy,
			context,
			placement: resultPlacement,
			refs: { reference: referenceRef, setReference, setFloating },
		} = useFloating<HTMLDivElement>({
			open: isOptionsListOpen,
			onOpenChange: handleOpenChange,
			whileElementsMounted: autoUpdate,
			placement,
			middleware: [
				flip({ padding: 8 }),
				size({
					apply({ rects, availableHeight, elements }) {
						Object.assign(elements.floating.style, {
							width: `${rects.reference.width - 2}px`,
							maxHeight: `${availableHeight}px`,
						});
					},
					padding: 8,
				}),
			],
		});

		const click = useClick(context, {
			keyboardHandlers: false,
			enabled: !isDisabled && !isLoading && (!isMultiSelect || (isMultiSelect && !isOptionsListOpen)),
		});
		const role = useRole(context);
		const dismiss = useDismiss(context);

		const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

		const portalNode = useFloatingPortalNode();

		const handleClear: MouseEventHandler<SVGElement> = (event): void => {
			event.stopPropagation();

			if (isMultiSelect) {
				if (!disallowEmptyValue || (disallowEmptyValue && isOptionsListOpen)) {
					setSelectState((state) => {
						state.inputValue = '';
						state.pendingValues = [];
					});
				}

				if (!disallowEmptyValue) {
					(onChange as OnChangeMultiSelect<T>)([]);
				}
			} else {
				(onChange as OnChangeSingleSelect<T>)(null);
			}
		};

		const handleInputClick: MouseEventHandler<HTMLInputElement> = (event): void => {
			setSelectState((state) => {
				state.isOptionsListOpen = !state.isOptionsListOpen;
			});

			onClick?.(event);
		};

		const handleInputFocus: FocusEventHandler<HTMLInputElement> = (event): void => {
			if (clearOnFocus && autoComplete) {
				setSelectState((state) => {
					state.inputValue = '';
				});
			}

			onFocus?.(event);
		};

		const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
			const targetInputValue = event.target.value;

			setSelectState((state) => {
				state.inputValue = targetInputValue;

				if (targetInputValue.length > 0 && !isOptionsListOpen) {
					state.isOptionsListOpen = true;
				}
			});

			onInputChange?.(targetInputValue);
		};

		const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
			if (isOptionsListOpen) {
				switch (event.key) {
					case 'ArrowDown':
						event.preventDefault();
						setSelectState((state) => {
							state.highlightedIndex = Math.min(highlightedIndex + 1, filteredOptions.length - 1);
						});
						break;
					case 'ArrowUp':
						event.preventDefault();
						setSelectState((state) => {
							state.highlightedIndex = Math.max(highlightedIndex - 1, 0);
						});
						break;
					case 'Enter':
						event.preventDefault();

						if (highlightedIndex !== null) {
							handleItemSelect(filteredOptions[highlightedIndex]);
						}

						break;
					default:
						break;
				}
			}
		};

		const isItemSelected = (item: SelectOption<T>): boolean => {
			if (isMultiSelect) {
				if (!isNil(pendingValues)) {
					return !!pendingValues?.find((i) => isEqual(i?.value, item?.value));
				}

				return !!(value as SelectOption<T>[])?.find((i) => isEqual(i?.value, item?.value));
			}

			return isEqual((value as SelectOption<T>)?.value, item?.value);
		};

		const handleItemSelect = (item: SelectOption<T>): void => {
			if (isMultiSelect) {
				const newValues: SelectOption<T>[] = [...(pendingValues ?? (value as SelectOption<T>[]) ?? [])];

				if (isItemSelected(item)) {
					newValues.splice(
						newValues.findIndex((i) => isEqual(i?.value, item?.value)),
						1
					);
				} else {
					newValues.push(item);
				}

				setSelectState((state) => ({
					...state,
					inputValue: getInputValue(newValues, isMultiSelect),
					pendingValues: newValues,
				}));
			} else {
				if (isNil(item) && disallowEmptyValue) {
					return;
				}

				(onChange as OnChangeSingleSelect<T>)(item);

				setSelectState((state) => {
					state.inputValue = getInputValue(item, isMultiSelect);
					state.isOptionsListOpen = false;
				});
			}
		};

		const startElement = useMemo<JSX.Element>(() => {
			// In multi-select mode, we only show the icon if there is a single value selected
			// In single-select mode, we show the icon if there is a value selected
			if (isMultiSelect) {
				const values = pendingValues ?? (value as SelectOption<T>[]) ?? [];

				if (values.length === 1) {
					return values?.[0]?.icon ?? null;
				}

				return null;
			}

			return (value as SelectOption<T>)?.icon ?? null;
		}, [isMultiSelect, pendingValues, value]);

		const endElement =
			{
				[EFuseSelectEndElementType.Arrow]: isOptionsListOpen ? (
					<SArrowUpIcon data-test-id={`${testId}-select-menu-opened`} />
				) : (
					<SArrowDownIcon data-test-id={`${testId}-select-menu-closed`} />
				),
				[EFuseSelectEndElementType.Search]: <SearchIcon data-test-id={`${testId}-search-icon`} />,
			}[endElementType] ?? null;

		const filteredOptions = useMemo(
			() =>
				// If typing in the input is possible, filter the options based on the input value, otherwise show all options
				// Or if onInputChange exists do not filter internally
				!autoComplete || inputValue === getInputValue(pendingValues ?? value, isMultiSelect) || !isNil(onInputChange)
					? options
					: options.filter((option) => !inputValue || option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())),
			[autoComplete, inputValue, isMultiSelect, onInputChange, options, pendingValues, value]
		);

		const handleInputWrapperClick: MouseEventHandler<HTMLDivElement> = (event): void => {
			event.stopPropagation();
		};

		const handleClickOutside = (): void => {
			let newInputValue = inputValue;
			let shouldNullifyPendingValues = false;
			let shouldTriggerChangeWithPendingValues = false;

			if (skipClickOutsideAction) {
				return;
			}

			if (!isNil(customInputValue)) {
				newInputValue = customInputValue;
			} else if (isMultiSelect && !isNil(pendingValues)) {
				// If we don't allow empty values and the pending values are empty, we don't want to apply the change
				shouldTriggerChangeWithPendingValues = !(pendingValues.length === 0 && disallowEmptyValue);

				shouldNullifyPendingValues = true;

				if (shouldTriggerChangeWithPendingValues) {
					newInputValue = getInputValue(pendingValues, isMultiSelect);
				} else {
					newInputValue = getInputValue(value, isMultiSelect);
				}
			} else {
				newInputValue = getInputValue(value, isMultiSelect);
			}

			if (newInputValue !== inputValue) {
				setSelectState((state) => {
					state.inputValue = newInputValue;

					if (shouldNullifyPendingValues) {
						state.pendingValues = null;
					}
				});
			}

			if (shouldTriggerChangeWithPendingValues) {
				(onChange as OnChangeMultiSelect<T>)(pendingValues);
			}
		};

		useOnClickOutside(referenceRef.current as HTMLElement, handleClickOutside, [portalNode]);

		const renderOptionLabel = (str: string): JSX.Element => {
			if (inputValue?.length && autoComplete) {
				const regExpSafeInputValue = escapeRegExp(inputValue);
				const regExp = new RegExp(`(${regExpSafeInputValue})`, 'gi');

				return <span>{str.split(regExp).map((elem, index) => (index % 2 !== 0 ? <b key={index.toString()}>{elem}</b> : elem))}</span>;
			}

			if (inputValue === str) {
				return <b>{str}</b>;
			}

			return <>{str}</>;
		};

		const renderClearIcon = (): JSX.Element => {
			if (!value || hideClearIcon || (disallowEmptyValue && !isMultiSelect) || (disallowEmptyValue && isMultiSelect && !isOptionsListOpen)) {
				return null;
			}

			return <SClearIcon onClick={handleClear} data-test-id={`${testId}-clear-icon`} />;
		};

		useEffect(() => {
			// If a new value has been provided, and no custom input value was provided, update the input value
			if (!customInputValue) {
				setSelectState((state) => {
					state.inputValue = getInputValue(value, isMultiSelect);
				});
			}
		}, [customInputValue, isMultiSelect, setSelectState, value]);

		useEffect(() => {
			// If a new custom input value was provided, update the input value
			if (customInputValue) {
				setSelectState((state) => {
					state.inputValue = customInputValue;
				});
			}
		}, [customInputValue, setSelectState]);

		return (
			<FuseInputBaseWrapper className={clsx(dangerouslySetClassName)}>
				{(label || tooltipContent) && (
					<LabelAndTooltipWrapper data-test-id={`${testId}-label`}>
						{label && (
							<Label type={EFuseInputBaseLabelType.Primary} isRequired={required}>
								{label}
							</Label>
						)}

						{tooltipContent && (
							<FuseTooltip data-test-id={`${testId}-tooltip`} content={tooltipContent} placement={ETooltipPlacement.Right}>
								<TooltipIcon />
							</FuseTooltip>
						)}
					</LabelAndTooltipWrapper>
				)}

				<SSelectInputAndIconsWrapper
					onClick={handleInputWrapperClick}
					$isOptionsListOpen={isOptionsListOpen}
					$optionsListPlacement={resultPlacement}
					$disableBordersWhenClosed={disableBordersWhenClosed}
					$hideClearIcon={hideClearIcon}
					$isDisabled={isDisabled || isLoading}
					{...getReferenceProps({
						ref: setReference,
					})}
				>
					{startElement && <SStartElementWrapper $hideBackground>{startElement}</SStartElementWrapper>}

					<SSelectInput
						disabled={isDisabled || isLoading}
						readOnly={!autoComplete}
						autoComplete="off"
						hasError={error?.length > 0}
						hasEndElement
						onClick={handleInputClick}
						onFocus={handleInputFocus}
						onChange={handleInputValueChange}
						onKeyDown={handleInputKeyDown}
						isLoading={isLoading}
						value={inputValue ?? ''}
						ref={ref}
						{...otherProps}
						data-test-id={`${testId}-input`}
						data-test-loading={isLoading || undefined}
						data-test-error={error?.length > 0}
					/>

					{isLoading && (
						<SLoaderWrapper>
							<SLoader />
						</SLoaderWrapper>
					)}

					<SEndElementWrapper $isDisabled={isDisabled || isLoading} className="endElement">
						{renderClearIcon()}

						{endElement}
					</SEndElementWrapper>

					{isOptionsListOpen &&
						portalNode &&
						createPortal(
							<SOptionsList
								data-test-id={`${testId}-option-list`}
								$isOpen={isOptionsListOpen}
								$isEmpty={filteredOptions?.length === 0}
								$optionsCount={filteredOptions?.length}
								$maxOptionsInView={maxOptionsInView}
								$placement={resultPlacement}
								{...optionsListProps}
								{...getFloatingProps({
									ref: setFloating,
									style: {
										position: strategy,
										left: x ?? 0,
										top: y ?? 0,
									},
								})}
							>
								{resultPlacement === 'bottom' && <SDivider />}

								{filteredOptions?.length > 0 ? (
									filteredOptions.map((item: SelectOption<T>, index: number) => (
										<SOption
											key={`${item?.value?.toString()}-${index.toString()}`}
											onClick={(): void => handleItemSelect(item)}
											$isHighlighted={highlightedIndex === index}
											data-test-id={`${testId}-option-item-${index}`}
											data-test-value={item?.value?.toString()}
											{...optionProps}
										>
											{isMultiSelect && <FuseCheckbox data-test-id={`${testId}-checkbox`} value={isItemSelected(item)} />}

											{item?.icon}

											<SOptionLabel>{renderOptionLabel(item?.label)}</SOptionLabel>
										</SOption>
									))
								) : (
									<SNoOptionsMessage data-test-id={`${testId}-no-option`}>No options</SNoOptionsMessage>
								)}

								{resultPlacement === 'top' && <SDivider />}
							</SOptionsList>,
							portalNode
						)}
				</SSelectInputAndIconsWrapper>

				{error?.length > 0 && !isOptionsListOpen && (
					<ErrorWrapper>
						<ErrorIcon />

						<ErrorMessage>{error}</ErrorMessage>
					</ErrorWrapper>
				)}
			</FuseInputBaseWrapper>
		);
	}
);

FuseSelectBase.displayName = 'FuseSelectBase';
