import { configureStore } from '@reduxjs/toolkit';
import { api } from '@transact-client/store/api/common.api';
import { crashReporterMiddleware, rtkQueryErrorLogger } from '@transact-client/store/middlewares';
import LogRocket from 'logrocket';
import { persistStore } from 'redux-persist';
import { errorReporter } from '@transact-client/services/ErrorReport';
import { rootReducer } from './reducers';
import { pendingInvalidationsHandler } from './middlewares/pendingInvalidationsHandler';

const errorReportEnhancer = errorReporter.getReduxEnhancer();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createStore = () =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ serializableCheck: false }) // TODO need to enable serializable check after fixing some bad actions that use unserializable objects
				.prepend(pendingInvalidationsHandler)
				.concat(api.middleware)
				.concat(crashReporterMiddleware)
				.concat(LogRocket.reduxMiddleware())
				.concat(rtkQueryErrorLogger),
		devTools: true,
		enhancers: [errorReportEnhancer],
	});
export const store = createStore();

export const persistor = persistStore(store);
