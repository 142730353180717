import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import type { IFuseCheckboxBaseProps } from './types';
import {
	CheckboxLabel,
	CheckboxWrapper,
	Checkmark,
	CheckmarkWrapper,
	ErrorIcon,
	ErrorMessage,
	ErrorWrapper,
	LabelAndCheckmarkWrapper,
	SCheckboxInput,
	SCheckboxInputWrapper,
} from './FuseCheckboxBase.styles';

export const FuseCheckboxBase = forwardRef<HTMLInputElement, IFuseCheckboxBaseProps>(
	(
		{
			dangerouslySetClassName,
			label,
			value,
			error,
			onChange,
			disabled,
			borderColor,
			width,
			height,
			'data-test-id': testId,
			...otherProps
		}: IFuseCheckboxBaseProps,
		ref
	): JSX.Element => {
		const handleChange = (): void => {
			if (!disabled) {
				onChange?.(!value);
			}
		};

		return (
			<SCheckboxInputWrapper $width={width} $height={height}>
				<SCheckboxInput
					onChange={(e): void => onChange?.(e.target.checked)}
					checked={value}
					disabled={disabled}
					type="checkbox"
					ref={ref}
					{...otherProps}
					data-test-error={error?.length > 0 || undefined}
				/>

				<CheckboxWrapper $width={width} $height={height} className={clsx(dangerouslySetClassName)} onClick={handleChange}>
					<LabelAndCheckmarkWrapper $width={width} $height={height}>
						<CheckmarkWrapper
							$isChecked={value}
							$isDisabled={disabled}
							$borderColor={borderColor}
							$width={width}
							$height={height}
							data-test-id={`${testId}-checkmark-wrapper`}
							data-test-disabled={disabled || undefined}
							data-test-selected={value || undefined}
							data-test-error={error?.length > 0 || undefined}
						>
							<Checkmark data-test-id={`${testId}-checkmark`} $isChecked={value} />
						</CheckmarkWrapper>

						{!!label?.length && <CheckboxLabel>{label}</CheckboxLabel>}
					</LabelAndCheckmarkWrapper>

					{error?.length > 0 && (
						<ErrorWrapper>
							<ErrorIcon />

							<ErrorMessage>{error}</ErrorMessage>
						</ErrorWrapper>
					)}
				</CheckboxWrapper>
			</SCheckboxInputWrapper>
		);
	}
);

FuseCheckboxBase.displayName = 'FuseCheckboxBase';
