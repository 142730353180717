import { css } from 'styled-components';

export const ellipsisStyle = css`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const hideInPrint = css`
	@media print {
		display: none !important;
	}
`;

export const showOnlyInPrint = css`
	@media not print {
		display: none !important;
	}
`;
