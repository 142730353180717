import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import { MdError } from 'react-icons/md';
import type { cssHelperType } from '../../../types';
import {
	backgroundColorDefault,
	borderRadiusRound,
	defaultSpacing,
	disabledColorDefault,
	errorColorDefault,
	fontSizeBody2,
	fontSizeBody3,
	fontSizeBody4,
	halfSpacing,
	primaryColorDefault,
	whiteColor,
} from '../../../theme/selectors';
import type { IToggleOutsideWrapperStyleProps, IToggleWrapperStyleProps } from './types';
import { EFuseToggleSize, EFuseToggleTextDirection } from './types';

export const SFuseToggleContainer = styled.div`
	display: inline-flex;
`;
export const SFuseToggleWrapper = styled.div`
	display: flex;
	gap: ${defaultSpacing};
	flex-direction: column;
`;

export const SFuseToggleIndicatorAndLabelWrapper = styled.div<IToggleOutsideWrapperStyleProps>`
	display: flex;
	gap: ${defaultSpacing};
	flex-direction: ${({ labelDirection }): Property.FlexDirection => (labelDirection === EFuseToggleTextDirection.Right ? `row` : `row-reverse`)};
	align-items: center;
	cursor: ${({ isDisabled }): Property.Cursor => (isDisabled ? `not-allowed` : `pointer`)};

	label {
		cursor: ${({ isDisabled }): Property.Cursor => (isDisabled ? `not-allowed` : `pointer`)};
	}
`;

export const SFuseToggleIndicatorWrapper = styled.div<IToggleWrapperStyleProps>`
	position: relative;
	border-radius: ${borderRadiusRound};
	color: ${whiteColor};
	display: flex;
	align-items: center;
	padding: ${halfSpacing};

	${({ isDisabled }): cssHelperType =>
		isDisabled &&
		css`
			background-color: ${disabledColorDefault};
		`};

	${({ isActive, isDisabled }): cssHelperType =>
		isActive &&
		!isDisabled &&
		css`
			background-color: ${primaryColorDefault};
			flex-direction: row;
		`};

	${({ isActive }): cssHelperType =>
		!isActive &&
		css`
			background-color: ${disabledColorDefault};
			flex-direction: row-reverse;
		`};

	${({ size }): cssHelperType =>
		({
			[EFuseToggleSize.Small]: css`
				width: 2.5rem;
				height: 1.5rem;
				font-size: ${fontSizeBody4};
			`,
			[EFuseToggleSize.Medium]: css`
				width: 3.125rem;
				height: 1.6253rem;
				font-size: ${fontSizeBody3};
			`,
		})[size]}
`;

export const SFuseToggleIndicator = styled.div<IToggleWrapperStyleProps>`
	position: absolute;
	inset-block-start: 50%;
	transform: translateY(-50%);
	background-color: ${backgroundColorDefault};
	border-radius: 0.75rem;
	transition: inset-inline-start 0.15s linear;

	${({ isDisabled }): cssHelperType =>
		isDisabled &&
		css`
			background-color: ${({ theme }): Property.BackgroundColor => theme.colors.common.gray.gray2};
			cursor: not-allowed;
		`};

	${({ isActive }): cssHelperType =>
		!isActive &&
		css`
			inset-inline-start: 0.25rem;
		`};

	${({ isActive, size }): cssHelperType =>
		({
			[EFuseToggleSize.Small]: css`
				width: 1rem;
				height: 1rem;

				${isActive &&
				css`
					inset-inline-start: calc(100% - 1.25rem);
				`}
			`,
			[EFuseToggleSize.Medium]: css`
				width: 1.25rem;
				height: 1.25rem;

				${isActive &&
				css`
					inset-inline-start: calc(100% - 1.5rem);
				`}
			`,
		})[size]}
`;

export const SErrorWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.1rem;
	margin-block-start: ${defaultSpacing};
`;

export const SErrorIcon = styled(MdError)`
	fill: ${errorColorDefault};
	width: ${fontSizeBody2};
	height: ${fontSizeBody2};
	align-self: flex-start;
	flex-shrink: 0;
`;
