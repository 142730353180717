import { FuseFlex } from '@fuse/react-ui';
import type { JSX } from 'react';
import React from 'react';
import type { FallbackRender } from '@transact-client/services/ErrorReport';
import { SErrorPageItemsWrapper, SErrorPageRootWrapper } from './ErrorBoundary.styles';

export const ErrorPageRoot: FallbackRender = (): JSX.Element => (
	<SErrorPageRootWrapper>
		<SErrorPageItemsWrapper height="100%" direction="column" justifyContent="space-between" alignItems="center">
			<img src="images/fuseLogo.svg" alt="Fuse Logo" height="50px" />
			<img src="images/car-and-woman.svg" alt="Woman examining a car" />
			<FuseFlex direction="column" gap="2rem" alignItems="center">
				<h1>Whoops, something went wrong.</h1>
				<p>
					Please either refresh the page or return home to try again. If the issue continues, please contact support.{' '}
					<a href="mailto:support@fuseautotech.com">support@fuseautotech.com</a>
				</p>
			</FuseFlex>
			<p>© Copyrights Fuse autotech | All Rights Reserved</p>
		</SErrorPageItemsWrapper>
	</SErrorPageRootWrapper>
);
