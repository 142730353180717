import type { PropsWithChildren } from 'react';
import React, { useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { arrow, autoUpdate, flip, FloatingPortal, offset, shift, useDismiss, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { clsx } from 'clsx';
import type { IFuseTooltipProps } from './types';
import { ETooltipPlacement } from './types';
import { SArrow, SChildrenWrapper, STooltip } from './FuseTooltip.styles';
import { tooltipPlacementToFloatingUiPlacement } from './utils';

export const FuseTooltip = ({
	dangerouslySetClassName,
	content,
	placement = ETooltipPlacement.Bottom,
	disableOpenOnHover = false,
	disableCloseOnClickOutside = false,
	disableAutoPlacement = false,
	'data-test-id': testId,
	children,
	...otherProps
}: PropsWithChildren<IFuseTooltipProps>): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const arrowRef = useRef(null);

	const {
		x,
		y,
		strategy,
		context,
		refs: { setReference, setFloating },
	} = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement: tooltipPlacementToFloatingUiPlacement(placement),
		middleware: [offset(8), ...(disableAutoPlacement ? [] : [flip()]), shift(), arrow({ element: arrowRef })],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(context, { enabled: !disableOpenOnHover });
	const dismiss = useDismiss(context, { enabled: !disableCloseOnClickOutside });

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss]);

	return (
		<>
			<SChildrenWrapper data-test-id={`${testId}-tooltip-children-wrapper`} {...getReferenceProps({ ref: setReference })}>
				{children}
			</SChildrenWrapper>

			<AnimatePresence>
				{isOpen && (
					<FloatingPortal>
						<STooltip
							className={clsx(dangerouslySetClassName)}
							data-test-id={`${testId}-tooltip`}
							{...getFloatingProps({
								ref: setFloating,
								style: { position: strategy, top: y ?? 0, left: x ?? 0 },
							})}
							{...otherProps}
						>
							<SArrow context={context} ref={arrowRef} />

							{content}
						</STooltip>
					</FloatingPortal>
				)}
			</AnimatePresence>
		</>
	);
};
