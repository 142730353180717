import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import {
	ErrorIcon,
	ErrorMessage,
	ErrorWrapper,
	FuseInputBaseWrapper,
	Label,
	LabelAndTooltipWrapper,
	SEndElementWrapper,
	SInput,
	SInputAndIconsWrapper,
	SLoader,
	SLoaderWrapper,
	SStartElementWrapper,
	TooltipIcon,
} from './FuseInputBase.styles';
import { ETooltipPlacement, FuseTooltip } from '../../dataDisplay/FuseTooltip';
import type { IFuseInputBaseProps } from './types';
import { EFuseInputBaseElementSize, EFuseInputBaseLabelType } from './types';

export const FuseInputBase = forwardRef<HTMLInputElement, IFuseInputBaseProps>(
	(
		{
			dangerouslySetClassName,
			error,
			value,
			label,
			labelType = EFuseInputBaseLabelType.Primary,
			required = false,
			disabled = false,
			isLoading = false,
			tooltipContent,
			startElement,
			startElementSize = EFuseInputBaseElementSize.Sm,
			hideStartElementBackground = false,
			endElement,
			'data-test-id': testId,
			height,
			width,
			...otherProps
		}: IFuseInputBaseProps,
		ref
	): JSX.Element => (
		<FuseInputBaseWrapper className={clsx(dangerouslySetClassName)}>
			{(label || tooltipContent) && (
				<LabelAndTooltipWrapper data-test-id={`${testId}-label`}>
					{label && (
						<Label type={labelType} isRequired={required}>
							{label}
						</Label>
					)}

					{tooltipContent && (
						<FuseTooltip data-test-id={testId} content={tooltipContent} placement={ETooltipPlacement.Right}>
							<TooltipIcon data-test-id={`${testId}-tooltip-icon`} />
						</FuseTooltip>
					)}
				</LabelAndTooltipWrapper>
			)}

			<SInputAndIconsWrapper $isDisabled={disabled || isLoading} $height={height}>
				{startElement && (
					<SStartElementWrapper
						$startElementSize={startElementSize}
						$hideBackground={hideStartElementBackground}
						$isDisabled={disabled || isLoading}
						data-test-id={`${testId}-start-element-wrapper`}
						data-test-disabled={disabled || isLoading ? true : undefined}
						data-test-loading={isLoading || undefined}
					>
						{startElement}
					</SStartElementWrapper>
				)}

				<SInput
					hasError={error?.length > 0}
					isLoading={isLoading}
					disabled={disabled || isLoading}
					hasStartElement={!!startElement}
					startElementSize={startElementSize}
					hasEndElement={!!endElement}
					value={value ?? ''}
					autoComplete="off"
					ref={ref}
					width={width}
					data-test-id={`${testId}-input`}
					data-test-value={value || undefined}
					data-test-disabled={disabled || undefined}
					data-test-loading={isLoading || undefined}
					data-test-error={error?.length > 0 ? true : undefined}
					{...otherProps}
				/>

				{isLoading && (
					<SLoaderWrapper>
						<SLoader />
					</SLoaderWrapper>
				)}

				{endElement && <SEndElementWrapper data-test-id={`${testId}-end-element-wrapper`}>{endElement}</SEndElementWrapper>}
			</SInputAndIconsWrapper>

			{error?.length > 0 && (
				<ErrorWrapper>
					<ErrorIcon />

					<ErrorMessage>{error}</ErrorMessage>
				</ErrorWrapper>
			)}
		</FuseInputBaseWrapper>
	)
);

FuseInputBase.displayName = 'FuseInputBase';
