export const colors = {
	main: {
		blue: '#1A88EF',
		white: '#FFFFFF',
		darkGray: '#455365',
		midGray: '#8B99AA',
	},
	secondary: {
		green: '#24D55C',
		cyanBlue: '#00C3FF',
		turquoise: '#00B9E1',
		darkTurquoise: '#46A5CE',
		red: '#fa4b4b',
		violet: '#896BFF',
		purple: '#DB669E',
		yellow: '#FDF5AE',
		darkYellow: '#F7C410',
	},
	tint: {
		darkBlue: '#177AD7',
		darkGray1: '#343E4C',
		darkGray2: '#3E4B5B',
	},
	gray: {
		gray1: '#D4D3DC',
		gray2: '#E1E0E6',
		gray3: '#F3F3F5',
		gray4: '#F3F6F8',
		transparentMidGray: '#8B99AAB2',
		transparentGray: '#F3F3F5B2',
	},
	transparent: 'transparent',
};
