import type { Property } from 'csstype';
import type { IFuseDataTestProps } from '../../../types';

export enum EFuseAvatarSize {
	Sm = 'sm',
	Md = 'md',
	Lg = 'lg',
	Xl = 'xl',
}

export interface IFuseAvatarProps extends Omit<IFuseDataTestProps, 'data-test-id'> {
	imagePath: string;
	fullName: string;
	size?: EFuseAvatarSize;
	['data-test-id']: string;
}

export interface ISImageProps {
	$width: Property.Width;
	$height: Property.Height;
}
