import type { Property } from 'csstype';
import type { ThemeSelector } from '../types';

export const noSpacing: ThemeSelector<Property.Padding> = ({ theme }) => theme.spacing.noSpacing;
export const defaultSpacing: ThemeSelector<Property.Padding> = ({ theme }) => theme.spacing.default;
export const halfSpacing: ThemeSelector<Property.Padding> = ({ theme }) => theme.spacing.half;
export const doubleSpacing: ThemeSelector<Property.Padding> = ({ theme }) => theme.spacing.double;

export const buttonGapSpacing: ThemeSelector<Property.Gap> = ({ theme }) => theme.constants.button.gap;
export const buttonBorderRadius: ThemeSelector<Property.BorderRadius> = ({ theme }) => theme.constants.button.borderRadius;

export const gapDefaultSpacing: ThemeSelector<Property.Gap> = ({ theme }) => theme.gap.default;

export const sectionDefaultSpacing: ThemeSelector<Property.Padding> = ({ theme }) => theme.constants.section.spacing.default;
