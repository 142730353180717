import type { PropsWithChildren } from 'react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { cloneDeep, values } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { ToastsContainer } from './ToastsContainer/ToastsContainer';
import type { IFuseToastByType, IFuseToastContext, IFuseToastProviderProps, IFuseToasts, IToastState, IToastStateId } from './types';
import { ToastsContext } from './Toasts.context';
import { DefaultTemplate } from './DefaultComponents/DefaultTemplate';
import { toastsByPositionInitialValue } from './constants';

export const ToastsProvider = memo(({ configs, options, children }: PropsWithChildren<IFuseToastProviderProps>): JSX.Element => {
	const [toasts, setToasts] = useState<IFuseToasts>({});

	const defaultConfig = useMemo(
		() => ({
			info: DefaultTemplate,
			warning: DefaultTemplate,
			success: DefaultTemplate,
			error: DefaultTemplate,
			primary: DefaultTemplate,
			...(configs ?? {}),
		}),
		[configs]
	);

	const removeToast = useCallback((id: IToastStateId): void => {
		setToasts((prevState) => {
			const updatedState = cloneDeep(prevState);

			delete updatedState[id];

			return updatedState;
		});
	}, []);

	const addToast = useCallback((props: IToastState): void => {
		setToasts((prevState) => {
			const { id = uuid(), ...otherProps } = props;
			const updatedToasts = {
				...prevState,
				[id]: { id, ...otherProps },
			};

			return updatedToasts;
		});
	}, []);

	const selectToastsByPosition = useCallback((): IFuseToastByType => {
		const toastsData = values(toasts) ?? [];
		const initialValue: IFuseToastByType = toastsByPositionInitialValue;

		return toastsData.reduce(
			(result, config) => ({
				...result,
				[config.position ?? 'bottom-start']: [...(result[config.position] ?? []), config],
			}),
			initialValue
		);
	}, [toasts]);

	const contextValues: IFuseToastContext = useMemo(
		() => ({
			configs: defaultConfig,
			options,
			toasts,
			addToast,
			removeToast,
			selectToastsByPosition,
		}),
		[addToast, defaultConfig, options, removeToast, selectToastsByPosition, toasts]
	);

	return (
		<ToastsContext.Provider value={contextValues}>
			{children}
			<ToastsContainer />
		</ToastsContext.Provider>
	);
});
