import styled from 'styled-components';
import { errorColorContrastText, errorColorDefault, fontFamily, fontSizeBody3, zIndexBadge } from '../../../theme/selectors';

export const BadgeWrapper = styled.div`
	position: relative;
`;

export const SBadge = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	color: ${errorColorContrastText};
	background-color: ${errorColorDefault};
	border-radius: 0.625rem;
	position: absolute;
	top: 0;
	right: 0;
	z-index: ${zIndexBadge};
	box-sizing: border-box;
	padding: 0 0.375rem;
	transform: translate(50%, -50%);
`;
