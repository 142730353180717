import styled from 'styled-components';
import type { Property } from 'csstype';
import { FaEraser } from 'react-icons/fa';
import type { ISInitialsProps } from './types';
import { FuseFlex } from '../../../layout/FuseFlex';
import { activeColorDefault, borderColorDefault, borderWidthDefault, fontSizeBody4, primaryColorDefault } from '../../../../theme/selectors';
import { mediaQueries } from '../../../../utils/mediaQueries';
import { FuseTypography } from '../../FuseTypography';

export const SInitials = styled(FuseFlex).attrs(() => ({
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
}))<ISInitialsProps>`
	font-family: Helvetica, serif;
	font-size: ${(props): Property.FontSize => `${props.fontSize}px`};
`;

export const SAddInitialsBox = styled.button`
	width: 100%;
	height: 100%;
	border: ${borderWidthDefault} dashed ${borderColorDefault};
	background-color: ${({ theme }): Property.BackgroundColor => theme.colors.common.secondary.yellow};
	cursor: pointer;
`;

export const SClearIcon = styled(FaEraser)`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	fill: ${primaryColorDefault};
	cursor: pointer;

	:hover {
		fill: ${activeColorDefault};
	}
`;

export const SAddInitialsText = styled(FuseTypography)`
	${mediaQueries.mobileAndBelow} {
		font-size: ${fontSizeBody4};
	}
`;
