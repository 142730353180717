import styled, { css } from 'styled-components';
import { IoCloseCircleOutline } from 'react-icons/io5';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import {
	activeColorDefault,
	backgroundColorDefault,
	defaultSpacing,
	doubleSpacing,
	fontSizeH2,
	shadowDarker,
	textColorSecondary,
	zIndexDrawer,
} from '../../../theme/selectors';
import { mediaQueries } from '../../../utils/mediaQueries';
import { FuseBackdrop } from '../../overlay/FuseBackdrop';
import type { ISDrawerProps } from './types';
import { EFuseDrawerPlacement } from './types';
import type { cssHelperType } from '../../../types';

export const Backdrop = styled(FuseBackdrop)`
	z-index: ${zIndexDrawer};
`;

const placementToMotionProps: Record<EFuseDrawerPlacement, MotionProps> = {
	[EFuseDrawerPlacement.Left]: {
		initial: { x: '-100%' },
		animate: { x: 0 },
		exit: { x: '-100%' },
	},
	[EFuseDrawerPlacement.Right]: {
		initial: { x: '100%' },
		animate: { x: 0 },
		exit: { x: '100%' },
	},
	[EFuseDrawerPlacement.Top]: {
		initial: { y: '-100%' },
		animate: { y: 0 },
		exit: { y: '-100%' },
	},
	[EFuseDrawerPlacement.Bottom]: {
		initial: { y: '100%' },
		animate: { y: 0 },
		exit: { y: '100%' },
	},
};

const placementToStyles: Record<EFuseDrawerPlacement, cssHelperType> = {
	[EFuseDrawerPlacement.Left]: css`
		inset-inline-start: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	`,
	[EFuseDrawerPlacement.Right]: css`
		inset-inline-end: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	`,
	[EFuseDrawerPlacement.Top]: css`
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-start: 0;
	`,
	[EFuseDrawerPlacement.Bottom]: css`
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-end: 0;
	`,
};

export const SDrawer = styled(motion.div).attrs<ISDrawerProps, MotionProps>(({ key, $placement }) => ({
	key,
	...placementToMotionProps[$placement],
	transition: { duration: 0.2 },
}))<ISDrawerProps>`
	position: fixed;
	background-color: ${backgroundColorDefault};
	box-shadow: ${shadowDarker};
	z-index: ${zIndexDrawer};
	padding: 2rem ${doubleSpacing};
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	${({ $placement }): cssHelperType => placementToStyles[$placement]};

	${mediaQueries.mobile} {
		max-width: 100vw;
	}
`;

export const SCloseIcon = styled(IoCloseCircleOutline)`
	position: absolute;
	top: ${defaultSpacing};
	right: ${defaultSpacing};
	cursor: pointer;
	width: ${fontSizeH2};
	height: ${fontSizeH2};
	color: ${textColorSecondary};

	:active,
	:hover {
		color: ${activeColorDefault};
	}
`;
