import type { ComponentType } from 'react';
import type { DefaultTheme, FlattenInterpolation, FlattenSimpleInterpolation, ThemedStyledProps, ThemeProps } from 'styled-components';
import type { ITheme } from './theme/types';

export enum EBreakpoint {
	Mobile = '576px',
	Tablet = '768px',
	Laptop = '992px',
	Desktop = '1200px',
	LargeDesktop = '1600px',
}

export type BreakpointsMap<T> = Partial<
	{
		[a in keyof typeof EBreakpoint as Uncapitalize<keyof typeof EBreakpoint>]: T;
	} & { base: T }
>;

export type StylesMixin = (props: unknown) => FlattenSimpleInterpolation;

export interface IFuseDataTestProps {
	['data-test-id']?: string;
	['data-test-value']?: string | number;
	['data-test-selected']?: boolean;
	['data-test-disabled']?: boolean;
	['data-test-active']?: boolean;
	['data-test-loading']?: boolean;
	['data-test-error']?: boolean;
}

export interface IFuseCommonProps extends Omit<IFuseDataTestProps, 'data-test-id'> {
	id?: string;
	dangerouslySetClassName?: string;
	as?: string | ComponentType<any>;
	['data-test-id']?: string;
	['data-test-value']?: string | number;
}

export interface ILoadableCommonProps {
	isLoading?: boolean;
}

export type cssHelperType = FlattenInterpolation<ThemeProps<DefaultTheme>> | undefined;

// This type maps every property in the `Type` interface to the same property with `$` prefixed
export type Transient<Type> = ThemedStyledProps<
	{
		[Property in keyof Type as `$${string & Property}`]: Type[Property];
	},
	ITheme
>;

export type MediaQueries = {
	[key in Uncapitalize<keyof typeof EBreakpoint>]: string;
} & {
	[key in keyof typeof EBreakpoint as `${Uncapitalize<keyof typeof EBreakpoint>}AndBelow`]: string;
} & {
	[key in keyof typeof EBreakpoint as `${Uncapitalize<keyof typeof EBreakpoint>}AndAbove`]: string;
};
