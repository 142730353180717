import type { Placement } from '@floating-ui/react';
import type { IFuseCommonProps, IFuseDataTestProps } from '../../../types';
import type { IFuseInputBaseProps } from '../FuseInputBase';

export interface IFuseSelectBaseProps<T, IsMultiSelect extends boolean>
	extends Omit<IFuseInputBaseProps, 'autoComplete' | 'onChange' | 'value' | 'height'>,
		Omit<IFuseCommonProps, 'as' | 'data-test-id'> {
	options: SelectOption<T>[];
	value: IsMultiSelect extends true ? SelectOption<T>[] : SelectOption<T>;
	autoComplete?: boolean;
	onChange: IsMultiSelect extends true ? OnChangeMultiSelect<T> : OnChangeSingleSelect<T>;
	hideClearIcon?: boolean;
	endElementType?: EFuseSelectEndElementType;
	placement?: Placement;
	isMultiSelect?: IsMultiSelect;
	disableBordersWhenClosed?: boolean;
	maxOptionsInView?: number;
	onInputChange?: (value: string) => void;
	clearOnFocus?: boolean;
	optionsListProps?: Record<string, unknown>;
	optionProps?: Record<string, unknown>;
	disallowEmptyValue?: boolean;
	customInputValue?: string;
	'data-test-id': string;
	'data-test-value'?: string;
	skipClickOutsideAction?: boolean;
}

export type SelectOption<T> = { value: T; label: string; icon?: JSX.Element };

export enum EFuseSelectEndElementType {
	Arrow = 'arrow',
	Search = 'search',
}

export interface ISelectState<T> {
	readonly isOptionsListOpen: boolean;
	readonly inputValue: string;
	readonly highlightedIndex: number;
	readonly pendingValues: SelectOption<T>[] | null;
}

export type OnChangeSingleSelect<T> = (value: SelectOption<T>) => void;
export type OnChangeMultiSelect<T> = (value: SelectOption<T>[]) => void;

export interface ISSelectInputAndIconsWrapperProps {
	$hideClearIcon?: boolean;
	$disableBordersWhenClosed?: boolean;
	$isOptionsListOpen?: boolean;
	$optionsListPlacement?: Placement;
}

export interface ISOptionsListProps {
	$isOpen?: boolean;
	$isEmpty?: boolean;
	$optionsCount?: number;
	$maxOptionsInView?: number;
	$placement?: Placement;
}

export interface ISOptionProps extends Omit<IFuseDataTestProps, 'data-test-id'> {
	$isHighlighted?: boolean;
	$isSelected?: boolean;
	'data-test-id': string;
}
