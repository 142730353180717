import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectUserSelectedDealershipId } from '@transact-client/store/selectors/auth.selectors';
import { errorReporter } from './ErrorReporter';

export const ErrorReportService = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const dealershipId = useSelector(selectUserSelectedDealershipId);

	const user = useSelector(selectUser);

	useEffect(() => {
		if (user && dealershipId) {
			errorReporter.setUser({
				id: user.id,
				email: user.email,
				phoneNumber: user.phoneNumber,
			});
			errorReporter.setTag('dealershipId', dealershipId);
			errorReporter.setTag('isInternalUser', user.internalUser);
			errorReporter.setTag('isGuestUser', user.guestUser);
		}
	}, [user, dealershipId]);

	return <>{children}</>;
};
