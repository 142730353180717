/* eslint-disable */

// code from https://www.sigplusweb.com/SigWebLCD1x5Demo.htm
// modified to work with our react project.
import { ESignaturePadStatus } from './../types';

let tmr;
let eventTmr;

const resetIsSupported = false;

let lcdSize;
let lcdX;
let lcdY;
let scrn;
let canvasId: string;

export function startTablet(setSigCaptured: (args: any) => void, cnvId: string, setSignaturePadStatus: (status: ESignaturePadStatus) => void): Promise<void> {
	return new Promise((resolve, reject) => {
		try {
			SetTabletState(1);
			const retmod = TabletModelNumber();

			SetTabletState(0);

			if (retmod == 11 || retmod == 12 || retmod == 15) {
				canvasId = cnvId;
				ctx = (document.getElementById(canvasId) as HTMLCanvasElement).getContext('2d');
				eventTmr = setInterval(SigWebEvent, 20);
				tmr = SetTabletState(1, ctx, 50) || tmr;
				SetLCDCaptureMode(2);
				LcdRefresh(0, 0, 0, 240, 64);
				SetJustifyMode(0);
				KeyPadClearHotSpotList();
				ClearSigWindow(1);
				SetDisplayXSize(500);
				SetDisplayYSize(200);
				SetDisplayPenWidth(10);
				SetImageXSize(500);
				SetImageYSize(200);
				SetImagePenWidth(10);
				SetSigCompressionMode(0);
				SetLCDCaptureMode(2);

				LCDSendGraphicUrl(1, 2, 0, 20, 'https://res.cloudinary.com/fuse-autotech/image/upload/v1698754190/signature-pads/topaz/Sign.bmp'); // SignBox
				LCDSendGraphicUrl(1, 2, 207, 4, 'https://res.cloudinary.com/fuse-autotech/image/upload/v1698754190/signature-pads/topaz/OK.bmp'); // OK btn
				LCDSendGraphicUrl(1, 2, 15, 4, 'https://res.cloudinary.com/fuse-autotech/image/upload/v1698754190/signature-pads/topaz/CLEAR.bmp'); // clear btn

				// For Some reason this does not work
				// LcdWriteLocalImage(1, 2, 0, 20, '/assets/Sign.bmp');
				// LcdWriteLocalImage(1, 2, 207, 4, '/assets/OK.bmp');
				// LcdWriteLocalImage(1, 2, 15, 4, '/assets/CLEAR.bmp');

				lcdSize = LCDGetLCDSize();
				lcdX = lcdSize & 0xffff;
				lcdY = (lcdSize >> 16) & 0xffff;

				const data = 'To start the signing process, Please press Continue.';

				parse(data);

				LCDWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');

				KeyPadAddHotSpot(0, 1, 12, 40, 40, 15); // Continue

				ClearTablet();

				LCDSetWindow(0, 0, 1, 1);
				SetSigWindow(1, 0, 0, 1, 1);
				SetLCDCaptureMode(2);

				scrn = 2;

				onSigPenUp = () => {
					processPenUp(setSigCaptured);
				};

				SetLCDCaptureMode(2);
				setSignaturePadStatus(ESignaturePadStatus.Connected);
				resolve();
			} else {
				setSignaturePadStatus(ESignaturePadStatus.CannotRecognizeWorkingSignaturePad);
				reject('You do not have the appropriate signature pad plugged in.');
			}
		} catch (e) {
			console.error(e);
			setSignaturePadStatus(ESignaturePadStatus.CommunicationError);
			reject('Unable to communicate with Topaz Signature Pad.');
		}
	});
}

export function clearSignature() {
	showSignatureScreen();

	ClearSigWindow(1);
	LcdRefresh(1, 10, 0, 53, 17);

	LcdRefresh(2, 0, 0, 240, 64);
	ClearTablet();

	ClearSigWindow(1);
}

export function showSignatureScreen() {
	ClearSigWindow(1);
	LcdRefresh(2, 0, 0, 240, 64);
	ClearTablet();
	KeyPadClearHotSpotList();
	KeyPadAddHotSpot(2, 1, 10, 5, 53, 17); // CLEAR
	KeyPadAddHotSpot(3, 1, 197, 5, 19, 17); // OK
	// LCDSetWindow(2, 22, 236, 40);
	LCDSetWindow(2, 22, 636, 240);
	// SetSigWindow(1, 0, 22, 240, 40);
	SetSigWindow(1, 0, 22, 640, 240);
	SetDisplayPenWidth(10);

	SetLCDCaptureMode(2);
}

function processPenUp(setSigCaptured: any) {
	if (KeyPadQueryHotSpot(0) > 0) {
		ClearSigWindow(1);
		LcdRefresh(1, 16, 45, 50, 15);

		if (scrn == 1) {
			ClearTablet();
			LcdRefresh(0, 0, 0, 240, 64);

			const data2 = "We'll bind the signature to all the displayed text. Please press Continue.";

			parse(data2);

			LCDWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');
			LCDWriteString(0, 2, 200, 45, '9pt Arial', 15, 'Back');

			KeyPadAddHotSpot(1, 1, 195, 40, 20, 15); // Back

			scrn = 2;
		} else if (scrn == 2) {
			LcdRefresh(2, 0, 0, 240, 64);
			ClearTablet();
			KeyPadClearHotSpotList();
			KeyPadAddHotSpot(2, 1, 10, 5, 53, 17); // CLEAR
			KeyPadAddHotSpot(3, 1, 197, 5, 19, 17); // OK
			LCDSetWindow(2, 22, 236, 40);
			SetSigWindow(1, 0, 22, 640, 240);
		}

		SetLCDCaptureMode(2);
	}

	if (KeyPadQueryHotSpot(1) > 0) {
		ClearSigWindow(1);
		LcdRefresh(1, 200, 45, 25, 15);

		if (scrn == 2) {
			KeyPadClearHotSpotList();
			LcdRefresh(1, 200, 45, 25, 15);
			ClearTablet();
			LcdRefresh(0, 0, 0, 240, 64);

			const data = 'These are sample terms and conditions. Please press Continue.';

			parse(data);

			LCDWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');

			KeyPadAddHotSpot(0, 1, 12, 40, 40, 15); // Continue

			scrn = 1;
		}

		SetLCDCaptureMode(2);
	}

	if (KeyPadQueryHotSpot(2) > 0) {
		ClearSigWindow(1);
		LcdRefresh(1, 10, 0, 53, 17);

		LcdRefresh(2, 0, 0, 240, 64);
		ClearTablet();
	}

	if (KeyPadQueryHotSpot(3) > 0) {
		ClearSigWindow(1);
		LcdRefresh(1, 210, 3, 14, 14);

		if (NumberOfTabletPoints() > 0) {
			LcdRefresh(0, 0, 0, 240, 64);
			LCDWriteString(0, 2, 35, 25, '9pt Arial', 15, 'Signature capture complete.');

			// NOW, EXTRACT THE SIGNATURE IN THE TOPAZ BIOMETRIC FORMAT -- SIGSTRING
			// OR AS A BASE64-ENCODED PNG IMAGE
			// OR BOTH

			//* *******************USE THIS SECTION IF YOU WISH TO APPLY AUTOKEY TO YOUR TOPAZ SIGNATURE
			// READ ABOUT AUTOKEY AND THE TOPAZ SIGNATURE FORMAT HERE: http://topazsystems.com/links/robustsignatures.pdf
			// AUTOKEY IS CRITICAL TO SAVING AN eSIGN-COMPLIANT SIGNATURE
			// AUTOKEY ONLY APPLIES TO THE TOPAZ-FORMAT SIGSTRING AND DOES NOT APPLY TO AN IMAGE OF THE SIGNATURE
			// AUTOKEY ALLOWS THE DEVELOPER TO CRYPTOGRAPHICALLY BIND THE TOPAZ SIGNATURE TO A SET OF DATA
			// THE PURPOSE OF THIS IS TO SHOW THAT THE SIGNATURE IS BEING APPLIED TO THE DATA YOU PASS IN USING AutoKeyAddData()
			// IN GENERAL TOPAZ RECOMMENDS REPLICATING A TRADITIONAL 'PAPER AND PEN' APPROACH
			// IN OTHER WORDS, IF YOU WERE TO PRINT OUT ON PAPER THE TERMS/INFORMATION THE SIGNER IS SUPPOSED TO READ AND AGREE WITH
			// THE DATA ON THIS PAPER IS WHAT SHOULD IN WHOLE BE PASSED INTO AUTOKEYADDANSIDATA() DIGITALLY
			// THE TOPAZ SIGSTRING IS THEN BOUND TO THIS DATA, AND CAN ONLY BE SUCCESSFULLY DECRYPTED LATER USING THIS DATA
			// AUTOKEYADDDATA IS DEPRECATED AND REPLACED BY AUTOKEYADDANSIDATA
			let CryptoData = '';

			CryptoData = 'This represents sample data the signer reads and is agreeing to when signing.';
			CryptoData += 'Concatenate all this data into a single variable.';
			AutoKeyAddANSIData(CryptoData); // PASS THE DATA IN TO BE USED FOR AUTOKEY
			SetEncryptionMode(2);
			//* ******END AUTOKEY SECTION

			// NOTE THAT THE AUTOKEY SECTION ABOVE IS NOT REQUIRED TO RETURN A TOPAZ SIGSTRING
			// BUT IT IS STRONGLY RECOMMENDED IF YOU REQUIRE eSIGN COMPLIANCE
			// RETURN THE TOPAZ-FORMAT SIGSTRING
			SetSigCompressionMode(1);
			// alert("KEYSTRING:" + GetKeyString());

			clearInterval(eventTmr);
			// setTimeout(endDemo, 2000);
			// TO RETURN A BASE64-ENCODED PNG IMAGE OF THE SIGNATURE
			SetImageXSize(500);
			SetImageYSize(200);
			SetImagePenWidth(10);
			GetSigImageB64(setSigCaptured, canvasId); // PASS IN THE FUNCTION NAME SIGWEB WILL USE TO RETURN THE FINAL IMAGE
		} else {
			LcdRefresh(0, 0, 0, 240, 64);
			LCDSendGraphicUrl(0, 2, 4, 20, 'http://www.sigplusweb.com/SigWeb/please.bmp');
			// LCDWriteString(0, 2, 4, 20, "9pt Arial", 15, "Please Complete Signature");
			ClearTablet();
			LcdRefresh(2, 0, 0, 240, 64);
			SetLCDCaptureMode(2);
		}
	}

	ClearSigWindow(1);
}

function parse(textData) {
	const words = textData.split(' ');
	let writeData = '';
	let tempData = '';
	let xSize = 0;
	let ySize = 0;
	let i = 0;
	let yPos = 0;

	for (i = 0; i < words.length; i++) {
		tempData += words[i];

		xSize = LCDStringWidth('9pt Arial', tempData);

		if (xSize < lcdX) {
			writeData = tempData;
			tempData += ' ';

			xSize = LCDStringWidth('9pt Arial', tempData);

			if (xSize < lcdX) {
				writeData = tempData;
			}
		} else {
			ySize = LCDStringHeight('9pt Arial', tempData);

			LCDWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);

			tempData = '';
			writeData = '';
			yPos += ySize;
			i--;
		}
	}

	if (writeData != '') {
		LCDWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);
	}
}

function endDemo() {
	LcdRefresh(0, 0, 0, 240, 64);
	LCDSetWindow(0, 0, 240, 64);
	SetSigWindow(1, 0, 0, 240, 64);
	KeyPadClearHotSpotList();
	SetLCDCaptureMode(1);
	SetTabletState(0, tmr);
	ClearTablet();
}

function close() {
	if (resetIsSupported) {
		Reset();
	} else {
		endDemo();
	}
}

// function processPenUp()
// {
// ClearSigWindow(1);
// }

// export const listener = () => {
// TODO: could delete after decided how we would like to support self driver install
// window.onload = () => {
// 	if (IsSigWebInstalled()) {
// 		console.log('installed');
// 		resetIsSupported = GetResetSupported();
// 		if (!resetIsSupported) {
// 			let sigweb_link = document.createElement('a');
// 			sigweb_link.href = 'https://www.topazsystems.com/software/sigweb.exe';
// 			sigweb_link.innerHTML = 'https://www.topazsystems.com/software/sigweb.exe';
//
// 			let note = document.getElementById('sigWebVrsnNote');
// 			note.innerHTML = 'There is a newer version of SigWeb available here: ';
// 			note.appendChild(sigweb_link);
// 		}
// 	} else {
// 		alert('Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC.');
// 	}
// };
//
// 	window.onbeforeunload = function (evt) {
// 		close();
// 		clearInterval(tmr);
// 		evt.preventDefault(); // For Firefox, needed for browser closure
// 	};
// };
export function closeTablet(): void {
	close();
	clearInterval(tmr);
	clearInterval(eventTmr);
}

export function GetResetSupported() {
	const minSigWebVersionResetSupport = '1.6.4.0';

	if (isOlderSigWebVersionInstalled(minSigWebVersionResetSupport)) {
		console.log('Old SigWeb version installed.');

		return false;
	}

	return true;
}

function isOlderSigWebVersionInstalled(cmprVer) {
	const sigWebVer = GetSigWebVersion();

	if (sigWebVer != '') {
		return isOlderVersion(cmprVer, sigWebVer);
	}

	return false;
}

function isOlderVersion(oldVer, newVer) {
	const oldParts = oldVer.split('.');
	const newParts = newVer.split('.');

	for (let i = 0; i < newParts.length; i++) {
		const a = parseInt(newParts[i]) || 0;
		const b = parseInt(oldParts[i]) || 0;

		if (a < b) {
			return true;
		}

		if (a > b) {
			return false;
		}
	}

	return false;
}

//* ******************************************************************
// Ealier modified code in SigWebTablet.js below
// ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
// SigWebTablet JavaScript File for SigWeb
//
// Version - 1.0.4.0
//
// Last updated by Topaz Systems Inc. - 1/5/2021
//

// @ts-ignore
const getBlobURL = (window.URL && URL.createObjectURL.bind(URL)) || (window.webkitURL && webkitURL.createObjectURL.bind(webkitURL)) || window.createObjectURL;

// @ts-ignore
const revokeBlobURL =
	(window.URL && URL.revokeObjectURL.bind(URL)) || (window.webkitURL && webkitURL.revokeObjectURL.bind(webkitURL)) || (window as any).revokeObjectURL;

const baseUri = makeUri();
let ctx;

export function IsSigWebInstalled(): Promise<boolean> {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.open('GET', `${baseUri}TabletState` + `?noCache=${generateUUID()}`, true);

		xhr.timeout = 200;

		xhr.onload = function () {
			if (xhr.status != 404 && xhr.status != 0) {
				resolve(true);
			} else {
				resolve(false);
			}
		};
		xhr.onerror = function () {
			console.log('Unknown Error Occured. SigWeb Service response not received.');
			resolve(false);
		};
		xhr.ontimeout = function () {
			console.log('Request timed out.');
			reject('Timeout');
		};

		try {
			xhr.send();
		} catch (e) {
			console.log('catch', e);
			reject(e.toString());
		}
	});
}

function isIE() {
	return (
		navigator.appName == 'Microsoft Internet Explorer' ||
		(navigator.appName == 'Netscape' && new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})').exec(navigator.userAgent) != null)
	);
}

function isChrome() {
	const ua = navigator.userAgent;
	const chrome = false;

	// Javascript Browser Detection - Chrome
	if (ua.lastIndexOf('Chrome/') > 0) {
		// let version = ua.substr(ua.lastIndexOf('Chrome/') + 7, 2);
		return true;
	}

	return false;
}

function makeUri() {
	let prot = location.protocol;

	if (prot == 'file:') {
		prot = 'http:';
	}

	if (isIE()) {
		if (prot == 'https:') {
			return `${prot}//tablet.sigwebtablet.com:47290/SigWeb/`;
		}

		return `${prot}//tablet.sigwebtablet.com:47289/SigWeb/`;
	}

	if (isChrome()) {
		if (prot == 'https:') {
			return `${prot}//tablet.sigwebtablet.com:47290/SigWeb/`;
		}

		return `${prot}//tablet.sigwebtablet.com:47289/SigWeb/`;
	}

	// FIREFOX
	if (prot == 'https:') {
		return `${prot}//tablet.sigwebtablet.com:47290/SigWeb/`;
	}

	return `${prot}//tablet.sigwebtablet.com:47289/SigWeb/`;
}

function SigWebcreateXHR() {
	try {
		return new XMLHttpRequest();
	} catch (e) {}

	try {
		// @ts-ignore
		return new ActiveXObject('Msxml2.XMLHTTP.6.0');
	} catch (e) {}

	try {
		// @ts-ignore
		return new ActiveXObject('Msxml2.XMLHTTP.3.0');
	} catch (e) {}

	try {
		// @ts-ignore
		return new ActiveXObject('Msxml2.XMLHTTP');
	} catch (e) {}

	try {
		// @ts-ignore
		return new ActiveXObject('Microsoft.XMLHTTP');
	} catch (e) {}

	alert('XMLHttpRequest not supported');

	return null;
}

const Count = false;

function SigWebSetProperty(prop) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + prop, true);
		xhr.send(null);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

function SigWebSetPropertySync(prop) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + prop, false);
		xhr.send();

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

function SigWebSetStreamProperty(prop, strm) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + prop);
		xhr.setRequestHeader('Content-Type', 'text/plain');
		xhr.send(strm);
		//			if (xhr.readyState == 4 && xhr.status == 200) {
		//				return xhr.responseText;
		//			}
	}

	return '';
}

function SigWebSyncSetStreamProperty(prop, strm) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + prop, false);
		xhr.setRequestHeader('Content-Type', 'text/plain');
		xhr.send(strm);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

function SigWebSetImageStreamProperty(prop, strm) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + prop, false);
		xhr.setRequestHeader('Content-Type', 'image/png');
		xhr.send(strm);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

function SigWebSetImageBlobProperty(prop, strm) {
	const xhr = SigWebcreateXHR();

	//			let bb = new BlobBuilder();
	//			bb.append( strm );
	//			bb.append( "\0" );
	//			let blob = bb.getBlob( );

	if (xhr) {
		xhr.open('POST', baseUri + prop, false);
		xhr.setRequestHeader('Content-Type', 'blob');
		xhr.send(strm);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

function SigWebGetProperty(prop) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('GET', `${baseUri + prop}?noCache=${generateUUID()}`, false);
		xhr.send(null);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText;
		}
	}

	return '';
}

let SigImageB64;

//	function GetSigImageB64(callback)
//		{
//		let cvs = document.createElement('canvas');
//		cvs.width = GetImageXSize();
//		cvs.height = GetImageYSize();
//
//		let xhr2 = new XMLHttpRequest();
//		xhr2.open("GET", baseUri + "SigImage/1", false);
//		xhr2.responseType = "blob";
//		xhr2.send(null);
//		if (xhr2.readyState == 4 && xhr.status == 200)
//			{
//			let cntx = cvs.getContext('2d');
//			let img = new Image();
//			img.src = window.URL.createObjectURL(xhr2.response);
//			img.onload = function ()
//				{
//				cntx.drawImage(img, 0, 0);
//				let b64String = cvs.toDataURL("image/png");
//				let loc = b64String.search("base64,");
//				let retstring = b64String.slice(loc + 7, b64String.length);
//				if (callback)
//					{
//					callback(retstring);
//					}
//				}
//			}
//		}

function GetSigImageB64(callback: any, canvasId: string) {
	// let cvs = document.createElement('canvas');
	const cvs = document.getElementById(canvasId) as HTMLCanvasElement;

	// cvs.width = GetImageXSize();
	// cvs.height = GetImageYSize();

	const xhr2 = new XMLHttpRequest();

	xhr2.open('GET', `${baseUri}SigImage/1` + `?noCache=${generateUUID()}`, true);
	xhr2.responseType = 'blob';
	xhr2.send(null);

	xhr2.onload = function () {
		const cntx = cvs.getContext('2d');
		const img = new Image();

		//			img.src = window.URL.createObjectURL(xhr2.response);
		img.src = getBlobURL(xhr2.response);

		img.onload = function () {
			cntx.drawImage(img, 0, 0);
			const b64String = cvs.toDataURL('image/png');
			const loc = b64String.search('base64,');
			const retstring = b64String.slice(loc + 7, b64String.length);

			if (callback) {
				callback(retstring);
			}
		};
	};
}

function SigWebWaitForPenDown(callback) {
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('GET', `${baseUri}WaitForPenDown` + `?noCache=${generateUUID()}`);
		xhr.timeout = 10000;

		xhr.onreadystatechange = function () {
			if (xhr.readyState != 4) {
				return;
			}

			if (xhr.status == 200) {
				callback();
			}
		};

		xhr.send(null);
	}
}

function GetSigImage(ctx) {
	const xhr2 = new XMLHttpRequest();

	xhr2.open('GET', `${baseUri}SigImage/1` + `?noCache=${generateUUID()}`, true);
	xhr2.responseType = 'blob';
	xhr2.send(null);

	xhr2.onload = function () {
		let img = new Image();

		img.src = getBlobURL(xhr2.response);

		img.onload = function () {
			ctx.drawImage(img, 0, 0);
			// @ts-ignore
			revokeBlobURL(this.src);
			img = null;
		};
	};
}

let EvStatus;
let onSigPenDown;
let onSigPenUp;

function SigWebSetDisplayTarget(obj) {
	ctx = obj;
}

let NumPointsLastTime = 0;

function SigWebRefresh() {
	const NumPoints = NumberOfTabletPoints();

	if (NumPoints == NumPointsLastTime) {
		return;
	}

	NumPointsLastTime = NumPoints;

	const xhr2 = new XMLHttpRequest();

	xhr2.open('GET', `${baseUri}SigImage/0` + `?noCache=${generateUUID()}`, true);
	xhr2.responseType = 'blob';

	xhr2.onload = function () {
		let img = new Image();

		img.src = getBlobURL(xhr2.response);

		//				img.src = window.URL.createObjectURL(xhr2.response);
		img.onload = function () {
			ctx.drawImage(img, 0, 0);
			// @ts-ignore
			revokeBlobURL(this.src);
			img = null;
		};
	};

	xhr2.send(null);
}

function SigWebEvent() {
	const OldEvStatus = EvStatus;

	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('GET', `${baseUri}EventStatus` + `?noCache=${generateUUID()}`, true);

		xhr.onload = function () {
			EvStatus = xhr.responseText;

			if (OldEvStatus & 0x01 && EvStatus & 0x02) {
				if (onSigPenDown) {
					onSigPenDown();
				}
			}

			if (OldEvStatus & 0x02 && EvStatus & 0x01) {
				if (onSigPenUp) {
					onSigPenUp();
				}
			}
		};

		xhr.send(null);
	}
}

function generateUUID() {
	let d = new Date().getTime();

	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now(); // use high-precision timer if available
	}

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (d + Math.random() * 16) % 16 | 0;

		d = Math.floor(d / 16);

		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
}

let SigWebFontThreshold = 155;

function setSigWebFontThreshold(v) {
	SigWebFontThreshold = v;
}

function createLcdBitmapFromCanvas(ourCanvas, xp, yp, width, height) {
	const canvasCtx = ourCanvas.getContext('2d');
	const imgData = canvasCtx.getImageData(0, 0, width, height);
	let j = 0;
	const sVal = 0;
	let outData = '';
	const outIdx = 0;
	const { data } = imgData;

	for (let y = 0; y < height; y++) {
		for (let x = 0; x < width; x++) {
			const tmp1 = data[j];
			const tmp2 = data[j + 1];
			const tmp3 = data[j + 2];
			const tmp4 = data[j + 3];

			//					sVal = tmp1 + (tmp2 << 8 ) + ( tmp3 << 16 ) + (tmp4 << 24 );
			j += 4;

			if (tmp1 < SigWebFontThreshold) {
				outData += 'B';
			} else {
				outData += 'W';
			}
		}
	}

	return outData;
}

// @ts-ignore
function toHex(NibVal) {
	switch (NibVal) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return 'A';
		case 11:
			return 'B';
		case 12:
			return 'C';
		case 13:
			return 'D';
		case 14:
			return 'E';
		case 15:
			return 'F';
	}
}

function ToHexString(ByteVal) {
	let Str = '';

	Str += toHex((ByteVal >> 4) & 0x0f);
	Str += toHex(ByteVal & 0x0f);

	return Str;
}

function textToTablet(x, y, height, str, fnt) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	cntx.font = fnt;
	const txt = str;
	const xs = Math.round(cntx.measureText(txt).width);
	const ys = height;

	c.width = xs;
	c.height = ys;

	cntx.font = fnt;
	cntx.fillStyle = '#FFFFFF';
	cntx.rect(0, 0, xs, ys);
	cntx.fill();

	cntx.fillStyle = '#000000';
	cntx.textBaseline = 'top';
	cntx.fillText(txt, 0, 0);

	cntx.drawImage(cntx.canvas, 0, 0, xs, ys);

	const Gstr = createLcdBitmapFromCanvas(c, 0, 0, xs, ys);

	LcdWriteImageStream(0, 2, x, y, xs, ys, Gstr);
}

function LcdWriteImage(Dst, Mode, Xp, Yp, Url) {
	let Prop = 'LcdWriteImage/';
	const NewUrl = Url.replace(/\//g, '_');

	Prop = `${Prop + Dst},${Mode},${Xp},${Yp},${NewUrl}`;
	SigWebSetPropertySync(Prop);
}

function LcdWriteLocalImage(Dst, Mode, Xp, Yp, Url) {
	let Prop = 'LcdWriteImage/';

	Prop = `${Prop + Dst},${Mode},${Xp},${Yp},${Url}`;
	SigWebSetProperty(Prop);
}

function LcdWriteImageStream(Dst, Mode, Xp, Yp, Xs, Ys, Url) {
	const Prop1 = `LcdWriteImageStreamParams/${Dst},${Mode},${Xp},${Yp},${Xs},${Ys}`;
	const Prop2 = 'LcdWriteImageStream/';

	SigWebSetPropertySync(Prop1);
	SigWebSetImageStreamProperty(Prop2, Url);
}

function LcdWriteImageBlob(Dst, Mode, Xp, Yp, Xs, Ys, Url) {
	let Prop = 'LcdWriteImageStream/';

	Prop = `${Prop + Dst},${Mode},${Xp},${Yp},${Xs},${Ys}`;
	SigWebSetImageBlobProperty(Prop, Url);
}

function measureText(pText: any, pFontSize: any, pStyle: any) {
	let lDiv: any = document.createElement('lDiv');

	document.body.appendChild(lDiv);

	if (pStyle != null) {
		lDiv.style = pStyle;
	}

	lDiv.style.fontSize = `${pFontSize}px`;
	lDiv.style.position = 'absolute';
	// @ts-ignore
	lDiv.style.left = -1000;
	// @ts-ignore
	lDiv.style.top = -1000;

	lDiv.innerHTML = pText;

	const lResult = {
		width: lDiv.clientWidth,
		height: lDiv.clientHeight,
	};

	document.body.removeChild(lDiv);
	lDiv = null;

	return lResult;
}

function GetSigWebVersion() {
	const prop = 'SigWebVersion';

	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('GET', `${baseUri + prop}?noCache=${generateUUID()}`, false);
		xhr.send(null);

		if (xhr.readyState == 4 && xhr.status == 200) {
			return xhr.responseText.slice(1, xhr.responseText.length - 1);
		}

		return '1.5'; // the currentversion of the SigWeb service is not installed
	}

	return '';
}

//
//
//
//
//
//
//			Start of dll method wrappers
//
//
//			SigPlusNET.cs
//
function GetVersionString() {
	let Prop = 'Version';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);
	const trimStr = Str.slice(1, Str.length - 2);

	return trimStr;
}

function IsPenDown() {
	return EvStatus & 0x01;
}

function GetDaysUntilCertificateExpires() {
	let Prop = 'DaysUntilCertificateExpires';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

//
//			SigPlusNETSig.cs
//
function ClearTablet() {
	let Prop = 'ClearSignature';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function NumberOfTabletPoints() {
	let Prop = 'TotalPoints';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

//		function  ExportSigFile(  FileName ) {}
//		function  ImportSigFile(  FileName ) {}

function SetSigString(sigStr, ctx) {
	let Prop = 'SigString';

	Prop = Prop;
	const xhr = SigWebcreateXHR();

	if (xhr) {
		xhr.open('POST', baseUri + Prop);
		xhr.setRequestHeader('Content-Type', 'text/plain');
		xhr.send(sigStr);

		xhr.onload = function () {
			if (ctx) {
				const can = ctx.canvas;

				SetImageXSize(can.width);
				SetImageYSize(can.height);
				GetSigImage(ctx);
			}
		};
	}

	return '';
}

function GetSigString() {
	let Prop = 'SigString';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function SetSigCompressionMode(v) {
	let Prop = 'CompressionMode/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSigCompressionMode() {
	let Prop = 'CompressionMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetEncryptionMode(v) {
	let Prop = 'EncryptionMode/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetEncryptionMode() {
	let Prop = 'EncryptionMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

//		function  SetKey( Keydata ) {}
//		function  GetKey( ) {}

function SetKeyString(keyString) {
	let Prop = 'KeyString';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, keyString);
}

function GetKeyString() {
	let Prop = 'KeyString';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function AutoKeyStart() {
	let Prop = 'AutoKeyStart';

	Prop = Prop;
	SigWebSetPropertySync(Prop);
}

function AutoKeyFinish() {
	let Prop = 'AutoKeyFinish';

	Prop = Prop;
	SigWebSetPropertySync(Prop);
}

function SetAutoKeyData(keyData) {
	let Prop = 'SetAutoKeyData';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, keyData);
}

function AutoKeyAddData(keyData) {
	let Prop = 'AutoKeyAddData';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, keyData);

	return GetKeyString();
}

function AutoKeyAddANSIData(keyData) {
	let Prop = 'AutoKeyAddANSIData';

	Prop = Prop;
	const isASCII = SigWebSyncSetStreamProperty(Prop, keyData);

	return isASCII;
}

//		function  GetKeyReceipt( ) {}

function GetKeyReceiptAscii() {
	let Prop = 'KeyReceiptAscii';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

//		function  GetSigReceipt( ) {}

function GetSigReceiptAscii() {
	let Prop = 'SigReceiptAscii';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function SetTimeStamp(timeStamp) {
	let Prop = 'TimeStamp';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, timeStamp);
}

function GetTimeStamp() {
	let Prop = 'TimeStamp';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function SetAnnotate(annotate) {
	let Prop = 'Annotate';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, annotate);
}

function GetAnnotate() {
	let Prop = 'Annotate';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function SetSaveSigInfo(v) {
	let Prop = 'SaveSigInfo/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSaveSigInfo() {
	let Prop = 'SaveSigInfo';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetSavePressureData(v) {
	let Prop = 'SavePressureData/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSavePressureData() {
	let Prop = 'SavePressureData';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetSaveTimeData(v) {
	let Prop = 'SaveTimeData/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSaveTimeData() {
	let Prop = 'SaveTimeData';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetAntiAliasSpotSize(v) {
	let Prop = 'AntiAliasSpotSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetAntiAliasSpotSize() {
	let Prop = 'AntiAliasSpotSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetAntiAliasLineScale(v) {
	let Prop = 'AntiAliasLineScale/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetAntiAliasLineScale() {
	let Prop = 'AntiAliasLineScale';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function GetNumberOfStrokes() {
	let Prop = 'NumberOfStrokes';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function GetNumPointsForStroke(v) {
	let Prop = 'NumberOfPointsInStroke/';

	Prop += v;

	return SigWebGetProperty(Prop);
}

function GetPointXValue(v1, v2) {
	let Prop = 'PointXValue/';

	Prop = `${Prop + v1}/${v2}`;

	return SigWebGetProperty(Prop);
}

function GetPointYValue(v1, v2) {
	let Prop = 'PointYValue/';

	Prop = `${Prop + v1}/${v2}`;

	return SigWebGetProperty(Prop);
}

function SetAntiAliasEnable(v) {
	let Prop = 'AntiAliasEnable/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetAntiAliasEnable() {
	let Prop = 'AntiAliasEnable';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetUseAmbientColors(v) {
	let Prop = 'UseAmbientColors/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

//
//		SigPlusNETDisplay.cs
//
function SetDisplayXSize(v) {
	let Prop = 'DisplayXSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayXSize() {
	let Prop = 'DisplayXSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayYSize(v) {
	let Prop = 'DisplayYSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayYSize() {
	let Prop = 'DisplayYSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayPenWidth(v) {
	let Prop = 'DisplayPenWidth/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayPenWidth() {
	let Prop = 'DisplayPenWidth';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayTimeStamp(v) {
	let Prop = 'DisplayTimeStamp/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayTimeStamp() {
	let Prop = 'DisplayTimeStamp';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayTimeStampPosX(v) {
	let Prop = 'DisplayTimeStampPosX/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayTimeStampPosX() {
	let Prop = 'DisplayTimeStampPosX';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayTimeStampPosY(v) {
	let Prop = 'DisplayTimeStampPosY/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayTimeStampPosY() {
	let Prop = 'DisplayTimeStampPosY';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayTimeStampSize(v) {
	let Prop = 'DisplayTimeStampSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayTimeStampSize() {
	let Prop = 'DisplayTimeStampSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayAnnotate(v) {
	let Prop = 'DisplayAnnotate/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayAnnotate() {
	let Prop = 'DisplayAnnotate';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayAnnotatePosX(v) {
	let Prop = 'DisplayAnnotatePosX/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayAnnotatePosX() {
	let Prop = 'DisplayAnnotatePosX';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayAnnotatePosY(v) {
	let Prop = 'DisplayAnnotatePosY/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayAnnotatePosY() {
	let Prop = 'DisplayAnnotatePosY';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetDisplayAnnotateSize(v) {
	let Prop = 'DisplayAnnotateSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetDisplayAnnotateSize() {
	let Prop = 'DisplayAnnotateSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

//
//		SigPlusNETImage.cs
//
//		function  GetSigImageB64( )
//			{
//			let xhr2 = new XMLHttpRequest();
//			xhr2.open("GET", baseUri + "SigImage/1", false );
//			xhr2.responseType = "blob"
//			xhr2.send(null);
//			if (xhr2.readyState == 4 && xhr.status == 200)
//				{
//				return window.URL.createObjectURL(xhr2.response);
//				}
//			return null;
//			}

function SetImageXSize(v) {
	let Prop = 'ImageXSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageXSize() {
	let Prop = 'ImageXSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageYSize(v) {
	let Prop = 'ImageYSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageYSize() {
	let Prop = 'ImageYSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImagePenWidth(v) {
	let Prop = 'ImagePenWidth/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImagePenWidth() {
	let Prop = 'ImagePenWidth';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageTimeStamp(v) {
	let Prop = 'ImageTimeStamp/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageTimeStamp() {
	let Prop = 'ImageTimeStamp';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageTimeStampPosX(v) {
	let Prop = 'ImageTimeStampPosX/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageTimeStampPosX() {
	let Prop = 'ImageTimeStampPosX';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageTimeStampPosY(v) {
	let Prop = 'ImageTimeStampPosY/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageTimeStampPosY() {
	let Prop = 'ImageTimeStampPosY';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageTimeStampSize(v) {
	let Prop = 'ImageTimeStampSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageTimeStampSize() {
	let Prop = 'ImageTimeStampSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageAnnotate(v) {
	let Prop = 'ImageAnnotate/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageAnnotate() {
	let Prop = 'ImageAnnotate';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageAnnotatePosX(v) {
	let Prop = 'ImageAnnotatePosX/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageAnnotatePosX() {
	let Prop = 'ImageAnnotatePosX';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageAnnotatePosY(v) {
	let Prop = 'ImageAnnotatePosY/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageAnnotatePosY() {
	let Prop = 'ImageAnnotatePosY';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetImageAnnotateSize(v) {
	let Prop = 'ImageAnnotateSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetImageAnnotateSize() {
	let Prop = 'ImageAnnotateSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetJustifyX(v) {
	let Prop = 'JustifyX/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetJustifyX() {
	let Prop = 'JustifyX';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetJustifyY(v) {
	let Prop = 'JustifyY/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetJustifyY() {
	let Prop = 'JustifyY';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetJustifyMode(v) {
	let Prop = 'JustifyMode/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetJustifyMode() {
	let Prop = 'JustifyMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

//
//		SigPlusNETKeyPad.cs
//
function KeyPadAddHotSpot(key, coord, xp, yp, xs, ys) {
	let Prop = 'KeyPadAddHotSpot/';

	Prop = `${Prop + key},${coord},${xp},${yp},${xs},${ys}`;
	SigWebSetPropertySync(Prop);
}

function KeyPadMarkHotSpot(key, coord, xp, yp, xs, ys) {
	LCDWriteString(0, 2, xp, yp, '16pt sans-serif', 32, '+');
	LCDWriteString(0, 2, xp + xs, yp, '16pt sans-serif', 32, '+');
	LCDWriteString(0, 2, xp, yp + ys, '16pt sans-serif', 32, '+');
	LCDWriteString(0, 2, xp + xs, yp + ys, '16pt sans-serif', 32, '+');
}

function KeyPadQueryHotSpot(key) {
	let Prop = 'KeyPadQueryHotSpot/';

	Prop += key;

	return SigWebGetProperty(Prop);
}

function KeyPadClearHotSpotList() {
	const Prop = 'KeyPadClearHotSpotList';

	SigWebSetPropertySync(Prop);
}

function SetSigWindow(coords, xp, yp, xs, ys) {
	let Prop = 'SigWindow/';

	Prop = `${Prop + coords},${xp},${yp},${xs},${ys}`;
	SigWebSetPropertySync(Prop);
}

function ClearSigWindow(inside) {
	let Prop = 'ClearSigWindow/';

	Prop += inside;
	SigWebSetPropertySync(Prop);
}

//
//		SigPlusNETLCD.cs
//
function SetLCDCaptureMode(v) {
	let Prop = 'CaptureMode/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetLCDCaptureMode() {
	let Prop = 'CaptureMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function LCDSetWindow(xP, yP, xS, yS) {
	let Prop = 'LCDSetWindow/';

	Prop = `${Prop + xP},${yP},${xS},${yS}`;
	SigWebSetPropertySync(Prop);
}

function LCDWriteString(dest, mode, x, y, fnt, size, str) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	cntx.font = fnt;
	const txt = str;
	const xs = Math.round(cntx.measureText(txt).width);
	const ys = size;

	c.width = xs;
	c.height = ys;

	if (xs == 0) {
		return;
	}

	cntx.font = fnt;
	cntx.fillStyle = '#FFFFFF';
	cntx.rect(0, 0, xs, ys);
	cntx.fill();

	cntx.fillStyle = '#000000';
	cntx.textBaseline = 'top';
	cntx.fillText(txt, 0, 0);

	cntx.drawImage(cntx.canvas, 0, 0, xs, ys);

	const Gstr = createLcdBitmapFromCanvas(c, x, y, xs, ys);

	LcdWriteImageStream(dest, mode, x, y, xs, ys, Gstr);
}

function LCDDrawRectangle(dest, mode, x, y, xs, ys, fill) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	c.width = xs;
	c.height = ys;

	cntx.fillStyle = fill;
	cntx.rect(0, 0, xs, ys);
	cntx.fill();

	cntx.drawImage(cntx.canvas, 0, 0, xs, ys);
	const Gstr = createLcdBitmapFromCanvas(c, x, y, xs, ys);

	LcdWriteImageBlob(dest, mode, x, y, xs, ys, Gstr);
}

function LCDDrawButton(dest, mode, x, y, xs, ys, strys, fill, fnt, str) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	cntx.font = fnt;
	const txt = str;
	const sxs = Math.round(cntx.measureText(txt).width);
	const sys = strys;

	c.width = xs;
	c.height = ys;

	cntx.font = fnt;
	cntx.fillStyle = fill;
	cntx.rect(0, 0, xs, ys);
	cntx.fill();

	cntx.fillStyle = '#FFFFFF';
	cntx.textBaseline = 'top';
	cntx.fillText(txt, (xs - sxs) / 2, (ys - sys) / 2);

	cntx.drawImage(cntx.canvas, 0, 0, xs, ys);

	const Gstr = createLcdBitmapFromCanvas(c, x, y, xs, ys);

	LcdWriteImageBlob(dest, mode, x, y, xs, ys, Gstr);
}

function LCDWriteStringWindow(dest, mode, x, y, fnt, xsize, ysize, str) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	cntx.font = fnt;
	const txt = str;
	const xs = xsize;
	const ys = ysize;

	c.width = xs;
	c.height = ys;

	cntx.font = fnt;
	cntx.fillStyle = '#FFFFFF';
	cntx.rect(0, 0, xs, ys);
	cntx.fill();

	cntx.fillStyle = '#000000';
	cntx.textBaseline = 'top';
	cntx.fillText(txt, 0, 0);

	cntx.drawImage(cntx.canvas, 0, 0, xs, ys);

	const Gstr = createLcdBitmapFromCanvas(c, x, y, xs, ys);

	LcdWriteImageBlob(dest, mode, x, y, xs, ys, Gstr);
}

function LCDStringWidth(fnt, str) {
	const c = document.createElement('canvas');
	const cntx = c.getContext('2d');

	cntx.font = fnt;
	const txt = str;
	const xs = Math.round(cntx.measureText(txt).width);

	return xs;
}

function LCDStringHeight(fnt, str) {
	return 16;
}

function LcdRefresh(Mode, Xp, Yp, Xs, Ys) {
	let Prop = 'LcdRefresh/';

	Prop = `${Prop + Mode},${Xp},${Yp},${Xs},${Ys}`;
	SigWebSetPropertySync(Prop);
}

function LCDSendCmdString(CmdStr, ReturnCount, Result, TimeOut) {
	let Prop = 'LcdSendCmdString/';

	Prop = `${Prop + ReturnCount},${TimeOut}`;
	Result = SigWebSetStreamProperty(Prop, CmdStr);
}

function LCDSendCmdData(CmdStr, ReturnCount, Result, TimeOut) {
	let Prop = 'LcdSendCmdData/';

	Prop = `${Prop + ReturnCount},${TimeOut}`;
	Result = SigWebSetStreamProperty(Prop, CmdStr);
}

function LCDSendGraphicCanvas(dest, mode, x, y, canvas) {
	// @ts-ignore
	const Gstr = createLcdBitmapFromCanvas(canvas, 0, 0, xs, ys);

	LcdWriteImageStream(dest, mode, x, y, canvas.width, canvas.height, Gstr);
}

//		function  LCDSendWindowedGraphicCanvas(  dest, mode,  x,  y, canvas )
//			 {
//			 }

//		function  LCDSendWindowedGraphicCanvas(  dest, mode,  x,  y,  xs,  ys, canvas )
//			{
//			let Gstr = createLcdBitmapFromCanvas( canvas, 0, 0, xs, ys)
//			LcdWriteImageStream( dest, mode, x, y, xs, ys, Gstr );
//			}

function LCDSendWindowedGraphicCanvas(dest, mode, x, y, xs, ys, c, xps, yps) {
	// @ts-ignore
	const Gstr = createLcdBitmapFromCanvas(canvas, xps, yps, xs, ys);

	LcdWriteImageStream(dest, mode, x, y, xs, ys, Gstr);
}

function LCDSendGraphicUrl(dest, mode, x, y, url) {
	LcdWriteImage(dest, mode, x, y, url);
}

//		function  LCDSendWindowedGraphicUrl(  dest, mode,  X,  Y, url )
//			{
//			}

//		function  LCDSendWindowedGraphicUrl(  dest, mode,  x,  y,  xs,  ys, url )
//			{
//			LcdWriteImageStream(dest, mode, x, y, xs, ys, url);
//			}

function LCDSendWindowedGraphicUrl(dest, mode, x, y, xse, yse, url, xps, yps) {
	// @ts-ignore
	LcdWriteImageStream(dest, mode, x, y, xs, ys, url);
}

//		function  LCDSendGraphic(  Dest,  Mode,  XPos,  YPos,  ImageFileName ) {}
//		function  LCDSendGraphicURL(  Dest,  Mode,  XPos,  YPos,  URL ) {}

function LCDClear() {
	let Prop = 'LcdClear';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function LCDSetTabletMap(LCDType, LCDXSize, LCDYSize, LCDXStart, LCDYStart, LCDXStop, LCDYStop) {}

function LCDSetPixelDepth(v) {
	let Prop = 'LcdSetPixelDepth/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function LCDGetLCDSize() {
	let Prop = 'LcdGetLcdSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function LCDSetCompressionMode(NewMode) {
	let Prop = 'LcdCompressionMode/';

	// @ts-ignore
	Prop += v;
	SigWebSetPropertySync(Prop);
}

function LCDGetCompressionMode() {
	let Prop = 'LcdCompressionMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function LCDSetZCompressionMode(NewMode) {
	let Prop = 'LcdZCompressionMode/';

	// @ts-ignore
	Prop += v;
	SigWebSetPropertySync(Prop);
}

function LCDGetZCompressionMode() {
	let Prop = 'LcdZCompressionMode';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}
//
//		SigPlusNETLCD.cs
//

function SetRealTabletState(v) {
	let Prop = 'TabletState/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletState() {
	let Prop = 'TabletState';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletLogicalXSize(v) {
	let Prop = 'TabletLogicalXSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletLogicalXSize() {
	let Prop = 'TabletLogicalXSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function GetTabletLogicalYSize() {
	let Prop = 'TabletLogicalYSize';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletLogicalYSize(v) {
	let Prop = 'TabletLogicalYSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetTabletXStart(v) {
	let Prop = 'TabletXStart/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletXStart() {
	let Prop = 'TabletXStart';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletYStart(v) {
	let Prop = 'TabletYStart/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletYStart() {
	let Prop = 'TabletYStart';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletXStop(v) {
	let Prop = 'TabletXStop/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletXStop() {
	let Prop = 'TabletXStop';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletYStop(v) {
	let Prop = 'TabletYStop/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletYStop() {
	let Prop = 'TabletYStop';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletFilterPoints(v) {
	let Prop = 'TabletFilterPoints/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletFilterPoints() {
	let Prop = 'TabletFilterPoints';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletTimingAdvance(v) {
	let Prop = 'TabletTimingAdvance/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletTimingAdvance() {
	let Prop = 'TabletTimingAdvance';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletComPort(v) {
	let Prop = 'TabletComPort/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletComPort() {
	let Prop = 'TabletComPort';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletBaudRate(v) {
	let Prop = 'TabletBaudRate/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletBaudRate() {
	let Prop = 'TabletBaudRate';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletRotation(v) {
	let Prop = 'TabletRotation/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletRotation() {
	let Prop = 'TabletRotation';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletType(v) {
	let Prop = 'TabletType/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletType() {
	let Prop = 'TabletType';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetServerTabletType(v) {
	let Prop = 'ServerTabletType/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetServerTabletType() {
	let Prop = 'ServerTabletType';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletComTest(v) {
	let Prop = 'TabletComTest/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletComTest() {
	let Prop = 'TabletComTest';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletResolution(v) {
	let Prop = 'TabletResolution/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetTabletResolution() {
	let Prop = 'TabletResolution';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function TabletConnectQuery() {
	let Prop = 'TabletConnectQuery';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function TabletModelNumber() {
	let Prop = 'TabletModelNumber';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function TabletSerialNumber() {
	let Prop = 'TabletSerialNumber';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletPortPath(v) {
	let Prop = 'TabletPortPath/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetTabletLocalIniFilePath(v) {
	let Prop = 'TabletLocalIniFilePath/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetTabletModel(v) {
	let Prop = 'TabletModel/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetSerialPortCloseDelay(v) {
	let Prop = 'SerialPortCloseDelay/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSerialPortCloseDelay() {
	let Prop = 'SerialPortCloseDelay';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function EnableTabletEncryption() {
	let Prop = 'EnableTabletEncryption';

	Prop = Prop;
	SigWebSetPropertySync(Prop);
}

function SetTabletEncryptionMode(hmode, tmode) {
	let Prop = 'TabletEncryptionMode/';

	Prop = `${Prop + hmode},${tmode}`;
	SigWebSetPropertySync(Prop);
}

function SetMaxLogFileSize(v) {
	let Prop = 'MaxLogFileSize/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetSigSockServerPath() {
	let Prop = 'SigSockServerPath';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function GetSigSockClientName() {
	let Prop = 'SigSockClientName';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function GetSigSockPortNumber() {
	let Prop = 'SigSockPortNumber';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetSigSockServerPath(v) {
	let Prop = 'SigSockServerPath/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetSigSockClientName(v) {
	let Prop = 'SigSockClientName/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetPortNumber(v) {
	let Prop = 'PortNumber/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function SetSigSockPortNumber(v) {
	let Prop = 'SigSockPortNumber/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function GetFirmwareRevision() {
	let Prop = 'FirmwareRevision';

	Prop = Prop;

	return SigWebGetProperty(Prop);
}

function SetTabletData(sigStr) {
	let Prop = 'TabletData';

	Prop = Prop;
	SigWebSetStreamProperty(Prop, sigStr);
}

function GetTabletData() {
	let Prop = 'TabletData';

	Prop = Prop;
	const Str = SigWebGetProperty(Prop);

	return Str.slice(1, Str.length - 1);
}

function OpenTablet(v) {
	let Prop = 'OpenTablet/';

	Prop += v;
	SigWebSetPropertySync(Prop);
}

function CloseTablet() {
	let Prop = 'CloseTablet';

	Prop = Prop;
	SigWebSetProperty(Prop);
}

function ResetParameters() {
	let Prop = 'ResetParameters';

	Prop = Prop;
	SigWebSetPropertySync(Prop);
}

function testRawData() {
	OpenTablet(1);
	const Str1 = GetTabletData();

	CloseTablet();
}

function Reset() {
	const Prop = 'Reset';

	SigWebSetProperty(Prop);
}

function SetTabletState(v: any, ctx?: any, tv?: any) {
	let delay;

	if (tv) {
		delay = tv;
	} else {
		delay = 100;
	}

	if (GetTabletState() != v) {
		if (v == 1) {
			if (ctx) {
				const can = ctx.canvas;

				SetDisplayXSize(can.width);
				SetDisplayYSize(can.height);
				SigWebSetDisplayTarget(ctx);
			}

			SetRealTabletState(v);

			if (ctx && GetTabletState() != 0) {
				tmr = setInterval(SigWebRefresh, delay);
			} else {
				tmr = null;
			}

			return tmr;
		}

		if (ctx) {
			clearInterval(ctx);
		}

		SigWebSetDisplayTarget(null);
		SetRealTabletState(v);
	}

	return null;
}
