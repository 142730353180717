import React, { forwardRef } from 'react';
import type { IFuseAlertProps } from './types';
import { FuseAlertBase } from '../../internal/FuseAlertBase';

export const FuseAlert = forwardRef<HTMLDivElement, IFuseAlertProps>(
	({ content, color = 'primary', 'data-test-id': testId, ...otherProps }: IFuseAlertProps, ref): JSX.Element => (
		<FuseAlertBase data-test-id={testId} ref={ref} color={color} {...otherProps}>
			{content}
		</FuseAlertBase>
	)
);

FuseAlert.displayName = 'FuseAlert';
