import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import { LinkButtonBase, SBadge, SLoading } from './FuseLinkButtonBase.styles';
import type { IFuseLinkButtonBaseProps } from './types';

export const FuseLinkButtonBase = forwardRef<HTMLButtonElement, PropsWithChildren<IFuseLinkButtonBaseProps>>(
	(
		{
			dangerouslySetClassName,
			onClick,
			text,
			icon,
			isLoading,
			formId = '',
			bold = false,
			uppercase = false,
			isSubmit = false,
			disabled = false,
			color = 'primary',
			fontSize,
			iconPlacement = 'start',
			badge,
			'data-test-id': testId,
			...otherProps
		}: PropsWithChildren<IFuseLinkButtonBaseProps>,
		ref
	): JSX.Element => (
		<LinkButtonBase
			className={clsx(dangerouslySetClassName)}
			type={isSubmit ? 'submit' : 'button'}
			form={isSubmit ? formId : ''}
			color={color}
			fontSize={fontSize}
			onClick={onClick}
			bold={bold}
			uppercase={uppercase}
			disabled={isLoading || disabled}
			isLoading={isLoading}
			iconPlacement={iconPlacement}
			ref={ref}
			data-test-id={`${testId}-link-button`}
			data-test-loading={isLoading ? true : undefined}
			data-test-disabled={isLoading || disabled ? true : undefined}
			{...otherProps}
		>
			{isLoading ? (
				<SLoading color={color} />
			) : (
				<>
					{!!icon && icon}
					{text}
					{badge && <SBadge>{badge}</SBadge>}
				</>
			)}
		</LinkButtonBase>
	)
);

FuseLinkButtonBase.displayName = 'FuseLinkButtonBase';
