import type { ILDFlag } from 'shared/featureFlags';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '@transact-client/store/featureFlags';

export type FeatureFlagsClient = { getVariation: <T>(flag: ILDFlag<T>) => T };

export const useFeatureFlag = <T>(flag: ILDFlag<T>): T => {
	const flags = useSelector(selectFeatureFlags);

	return flags?.[flag.key] ?? flag.default;
};
