import { camelCase } from 'change-case';
import { isEmpty, isNil, isPlainObject, mapValues, omitBy } from 'lodash-es';
import type { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { $enum } from 'ts-enum-util';
import type { BreakpointsMap } from '../types';
import { EBreakpoint } from '../types';
import { isBreakpointName } from './isBreakpointName';

export const groupPropsByBreakpoint = <T extends ThemedStyledProps<unknown, DefaultTheme>>(props: T): BreakpointsMap<Partial<T>> => {
	let groupedStyles: BreakpointsMap<Partial<T>> = $enum(EBreakpoint)
		.getKeys()
		.reduce((result, bp) => ({ ...result, [camelCase(bp)]: {} }), {
			base: {},
		});

	Object.entries(props).forEach(([propName, propValue]) => {
		if (!isNil(propValue)) {
			if (!isPlainObject(propValue)) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				groupedStyles.base[propName as keyof T] = propValue;
			} else {
				Object.keys(propValue).forEach((bp) => {
					if (isBreakpointName(bp)) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						groupedStyles[bp][propName as keyof T] = propValue[bp];
					}
				});
			}
		}
	});

	// Remove empty keys from `groupedStyles` before return
	groupedStyles = omitBy(groupedStyles, isEmpty);

	// Make sure that any leftover groups have the theme for their use
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	groupedStyles = mapValues(groupedStyles, (group) => ({ ...group, theme: props?.theme }));

	return groupedStyles;
};
