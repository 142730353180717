import React, { useState } from 'react';
import { isEmpty, isNil } from 'lodash-es';
import { FuseSkeleton } from '../FuseSkeleton';
import { SImage } from './FuseImage.styles';
import type { IFuseImageProps } from './types';

export const FuseImage = ({ src, alt, onImageLoaded, width, height, fallbackSrc, ...otherProps }: IFuseImageProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);

	const handleImageLoad = (): void => {
		setIsLoading(false);

		onImageLoaded?.();
	};

	const handleImageLoadError = (): void => {
		setIsError(true);
		setIsLoading(false);

		onImageLoaded?.();
	};

	const isSrcValid = !isNil(src) && !isEmpty(src);

	return (
		<>
			{(isLoading || !isSrcValid) && <FuseSkeleton type="rectangle" width={width ?? '100%'} height={height ?? '100%'} />}

			{isSrcValid && (
				<SImage
					$isLoading={isLoading}
					src={isError ? fallbackSrc : src}
					alt={alt ?? src}
					onError={handleImageLoadError}
					onLoad={handleImageLoad}
					$width={width}
					$height={height}
					{...otherProps}
				/>
			)}
		</>
	);
};
