import type { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { launchDarklyClient } from '@transact-client/providers/LaunchDarklyProvider';
import type { ILaunchDarklyProviderProps } from '@transact-client/providers/LaunchDarklyProvider/types';
import { selectUserEmail, selectUserName, selectUserSelectedDealershipId } from '@transact-client/store/selectors/auth.selectors';
import { createAppAsyncThunk } from '@transact-client/store/utils';
import { updateFlags } from './featureFlags.slice';

export const initializeFeatureFlags = createAppAsyncThunk<LDFlagSet, ILaunchDarklyProviderProps>(
	'featureFlags/initialize',
	async ({ overrides }, { getState, dispatch }) => {
		const dealershipId = selectUserSelectedDealershipId(getState());
		const userName = selectUserName(getState());
		const userEmail = selectUserEmail(getState());
		const ldEmail = overrides?.email ?? userEmail;

		await launchDarklyClient.initialize(dealershipId, userName, ldEmail);

		launchDarklyClient.subscribeToChanges((changedFlags) => {
			dispatch(updateFlags(changedFlags));
		});

		return launchDarklyClient.getFlags();
	}
);

export const setFeatureFlagsUser = createAppAsyncThunk<LDFlagSet, ILaunchDarklyProviderProps>('featureFlags/setUser', async ({ overrides }, { getState }) => {
	const dealershipId = selectUserSelectedDealershipId(getState());
	const userName = selectUserName(getState());
	const userEmail = selectUserEmail(getState());
	const ldEmail = overrides?.email ?? userEmail;

	return launchDarklyClient.setUser(dealershipId, userName, ldEmail).getFlags();
});
