import React, { memo, useCallback, useContext, useMemo } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import type { FuseToastPosition } from '../../../components/overlay/FuseToast/types';
import { SAnchorContainer } from './ToastsContainer.styles';
import { ToastsContext } from '../Toasts.context';
import type { IToastState, IToastStateId } from '../types';
import { FuseToast } from '../../../components/overlay/FuseToast';

export const ToastsContainer = memo((): JSX.Element => {
	const { configs: toastsConfigs, removeToast, selectToastsByPosition } = useContext(ToastsContext);

	const toastsByPosition = selectToastsByPosition();

	const handleClose = useCallback(
		(id: IToastStateId): void => {
			removeToast(id);
		},
		[removeToast]
	);

	const RenderToastComponent = useCallback(
		(toast: IToastState) => {
			const { id, color, ...props } = toast ?? ({} as IToastState);
			const ToastComponent = toastsConfigs[color];

			if (ToastComponent) {
				return (
					<FuseToast {...props} key={id} color={color} id={id} onClose={(): void => handleClose(id)}>
						<ToastComponent id={id} {...props} onClose={(): void => handleClose(id)} />
					</FuseToast>
				);
			}

			// eslint-disable-next-line no-console
			console.error(`Could not recognize a toast with type "${color}". Please check your FuseReactUiProvider toasts configuration.`);

			return null;
		},
		[handleClose, toastsConfigs]
	);

	const positions: FuseToastPosition[] = useMemo(() => ['top-center', 'top-start', 'top-end', 'bottom-center', 'bottom-start', 'bottom-end'], []);

	return (
		<FloatingPortal id="fuse-toasts-container">
			{positions.map((position) => (
				<SAnchorContainer key={position} id={`anchor-container-${position}`} $position={position}>
					<AnimatePresence key={`${position}-animation`}>
						{toastsByPosition[position].map((props: IToastState) => (
							<RenderToastComponent key={props.id} {...props} />
						))}
					</AnimatePresence>
				</SAnchorContainer>
			))}
		</FloatingPortal>
	);
});
