import { combineReducers } from 'redux';
import { api } from '@transact-client/store/api/common.api';
import { reducer as protectionProductsReducer } from '@transact-client/store/slices/protectionProducts';
import { reducer as deskingReducer } from '@transact-client/store/reducers/desking/desking';
import { persistReducer } from 'redux-persist';
import { reducer as authReducer } from '@transact-client/store/slices/auth';
import { reducer as sessionReducer } from '@transact-client/store/slices/session';
import { reducer as remoteReducer } from '@transact-client/store/slices/remote';
import { reducer as creditApplicationReducer } from '@transact-client/store/creditApplication';
import { reducer as dealsDashboardReducer } from '@transact-client/store/slices/dealsDashboard';
import { reducer as inventoryFiltersReducer } from '@transact-client/store/slices/inventory';
import { reducer as featureFlagsReducer } from '@transact-client/store/featureFlags';
import { reducer as leadFiltersReducer } from '@transact-client/store/slices/leadFilters';
import { reducer as themeReducer } from '@transact-client/store/slices/theme';
import { reducer as signaturesReducer } from '@transact-client/store/slices/signatures';
import { reducer as pendingInvalidationsReducer } from '@transact-client/store/slices/pendingInvalidations';
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['selectedDealershipId', 'selectedDealershipIds', 'token'],
};

const themePersistConfig = {
	key: 'theme',
	storage,
};

export const rootReducer = combineReducers({
	[api.reducerPath]: api.reducer,
	protectionProducts: protectionProductsReducer,
	desking: deskingReducer,
	auth: persistReducer(authPersistConfig, authReducer),
	session: sessionReducer,
	remote: remoteReducer,
	creditApplication: creditApplicationReducer,
	dealsDashboard: dealsDashboardReducer,
	inventory: inventoryFiltersReducer,
	featureFlags: featureFlagsReducer,
	leadFilters: leadFiltersReducer,
	theme: persistReducer(themePersistConfig, themeReducer),
	signatures: signaturesReducer,
	pendingInvalidations: pendingInvalidationsReducer,
});
