import { colors } from './colors';
import type { ITheme } from './types';
import { EThemeName } from './types';
import { generateColorGroup } from './utils';
import { commonTheme } from './commonTheme';

export const darkTheme: ITheme = {
	...commonTheme,
	name: EThemeName.Dark,
	colors: {
		common: colors,
		primary: generateColorGroup(colors.main.blue),
		secondary: generateColorGroup(colors.main.darkGray),
		active: generateColorGroup(colors.tint.darkBlue),
		success: generateColorGroup(colors.secondary.green),
		info: generateColorGroup(colors.secondary.violet),
		warning: generateColorGroup(colors.secondary.darkYellow),
		error: generateColorGroup(colors.secondary.red),
		disabled: generateColorGroup(colors.tint.darkGray2),
		background: {
			default: colors.tint.darkGray1,
			secondary: colors.main.darkGray,
			backdrop: colors.main.darkGray,
			contrast: colors.gray.gray2,
			contrastSecondary: colors.tint.darkGray2,
		},
		text: {
			default: colors.main.white,
			secondary: colors.main.midGray,
			white: colors.main.white,
		},
		border: {
			default: colors.main.midGray,
			focused: colors.main.white,
			selected: colors.main.blue,
		},
		separator: {
			default: colors.gray.gray2,
		},
	},
	shadows: {
		default: `0px 3px 30px ${colors.gray.gray1}19`,
		darker: `0px 3px 30px ${colors.gray.gray1}33`,
	},
};
