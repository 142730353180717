import styled, { css } from 'styled-components';
import { MdError } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import type { Property } from 'csstype';
import type { IFuseInputBaseLabelStylesProps, ISEndElementWrapperProps, ISInputAndIconsWrapperProps, ISInputProps, ISStartElementWrapperProps } from './types';
import { EFuseInputBaseElementSize, EFuseInputBaseLabelType } from './types';
import type { cssHelperType } from '../../../types';
import {
	backgroundColorDefault,
	backgroundColorSecondary,
	borderColorDefault,
	borderColorFocused,
	borderRadiusDefault,
	borderWidthDefault,
	defaultSpacing,
	disabledColorDefault,
	doubleSpacing,
	errorColorDefault,
	fontFamily,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody3,
	halfSpacing,
	secondaryColorDefault,
	textColorDefault,
	textColorSecondary,
} from '../../../theme/selectors';

export const FuseInputBaseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	width: 100%;
	position: relative;
	min-width: 0;
`;

export const LabelAndTooltipWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.FuseInputBase-tooltip {
		margin-inline-start: auto;
	}
`;

export const Label = styled.label<IFuseInputBaseLabelStylesProps>`
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	font-weight: normal;
	text-transform: uppercase;

	${({ type }): cssHelperType =>
		type === EFuseInputBaseLabelType.Primary &&
		css`
			color: ${textColorDefault};
		`}

	${({ type }): cssHelperType =>
		type === EFuseInputBaseLabelType.Secondary &&
		css`
			color: ${textColorSecondary};
		`}

    ${({ isRequired }): cssHelperType =>
		isRequired &&
		css`
			::after {
				content: '   *';
				color: ${errorColorDefault};
			}
		`}
`;

export const ErrorMessage = styled.span`
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	color: ${errorColorDefault};
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const ErrorWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.1rem;
`;

export const ErrorIcon = styled(MdError)`
	fill: ${errorColorDefault};
	width: ${fontSizeBody2};
	height: ${fontSizeBody2};
	align-self: flex-start;
	flex-shrink: 0;
`;

export const SInputAndIconsWrapper = styled.div<ISInputAndIconsWrapperProps>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: ${borderRadiusDefault};
	border-width: ${borderWidthDefault};
	border-style: solid;
	border-color: ${borderColorDefault};
	height: ${({ $height }): Property.Height => $height ?? '2.625rem'};
	background-color: ${backgroundColorDefault};

	:focus-within {
		border-color: ${borderColorFocused};
	}

	${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			background-color: ${disabledColorDefault};
			cursor: not-allowed;
			opacity: 0.6;

			* {
				cursor: not-allowed;
			}
		`}
`;

export const SInput = styled.input<ISInputProps>`
	flex-grow: 1;
	padding: 0 ${doubleSpacing};
	border-radius: ${borderRadiusDefault};
	font-size: ${fontSizeBody1};
	line-height: ${fontSizeBody1};
	height: 100%;
	min-width: 0;
	box-sizing: border-box;
	border: none;
	color: ${textColorDefault};
	background-color: transparent;
	text-align: start;
	width: ${({ width }): any => width};
	outline: none;

	::placeholder {
		color: ${textColorSecondary};
	}

	:placeholder-shown {
		text-overflow: ellipsis;
	}

	${({ isLoading }): cssHelperType =>
		isLoading &&
		css`
			background-color: ${disabledColorDefault};

			::placeholder {
				visibility: hidden;
			}
		`}

	${({ hasError }): cssHelperType =>
		hasError &&
		css`
			border-color: ${errorColorDefault};
		`}
`;

export const SStartElementWrapper = styled.div<ISStartElementWrapperProps>`
	height: 100%;
	padding-inline-start: 0.8rem;
	padding-inline-end: 0.8rem;
	background-color: ${backgroundColorSecondary};
	font-size: ${fontSizeBody1};
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: ${borderRadiusDefault};
	border-bottom-left-radius: ${borderRadiusDefault};

	& i,
	svg {
		fill: ${textColorSecondary};
		width: ${fontSizeBody1};
		height: ${fontSizeBody1};
	}

	${({ $startElementSize }): cssHelperType =>
		$startElementSize === EFuseInputBaseElementSize.Md &&
		css`
			padding-inline-start: ${halfSpacing};
			padding-inline-end: 0;
		`}

	${({ $hideBackground }): cssHelperType =>
		$hideBackground &&
		css`
			background: ${backgroundColorDefault};
		`}

	${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			background-color: ${disabledColorDefault};
		`}
`;

export const SEndElementWrapper = styled.div<ISEndElementWrapperProps>`
	height: 100%;
	padding-inline-start: 0.8rem;
	padding-inline-end: 0.8rem;
	font-size: ${fontSizeBody1};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${defaultSpacing};
	pointer-events: auto;

	& i,
	svg {
		fill: ${textColorSecondary};
		width: ${fontSizeBody1};
		height: ${fontSizeBody1};
	}

	${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			background-color: ${disabledColorDefault};
			pointer-events: none;
		`}
`;

export const SLoaderWrapper = styled.div`
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: 50%;
	transform: translate(-50%, -50%);
	//display: flex;
	//align-items: center;
	//justify-content: center;
	//width: 100%;

	& > span > span {
		width: 0.5rem;
		height: 0.5rem;
	}
`;

export const SLoader = styled(PulseLoader).attrs((props) => ({
	color: secondaryColorDefault(props),
	size: fontSizeBody1(props),
}))``;

export const TooltipIcon = styled(FaInfoCircle)`
	fill: ${textColorDefault};
	width: ${fontSizeBody1};
	height: ${fontSizeBody1};
`;
