import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import type { IToastState } from '../types';
import { FuseLinkButton } from '../../../components/inputs/FuseLinkButton';
import { SButtonsContainer, SDescription, STemplateContainer, STitle } from './DefaultTemplate.styles';
import { ToastsContext } from '../Toasts.context';

export const DefaultTemplate = (props: IToastState): JSX.Element => {
	const { removeToast } = useContext(ToastsContext);

	const {
		data: { title, description, buttons },
		id,
	} = props;

	return (
		<STemplateContainer>
			{title && <STitle> {title}</STitle>}
			{description && <SDescription> {description}</SDescription>}
			{buttons && (
				<SButtonsContainer>
					{buttons.map(({ text, cb }, index) => (
						<FuseLinkButton
							key={index.toString()}
							text={text}
							data-test-id={`toast-button-${uuid()}`}
							onClick={(): void => {
								cb(uuid());
								removeToast(id);
							}}
							buttonSize="sm"
						/>
					))}
				</SButtonsContainer>
			)}
		</STemplateContainer>
	);
};
