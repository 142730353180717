import type { PropsWithChildren } from 'react';
import React from 'react';
import { BadgeWrapper, SBadge } from './FuseBadge.styles';
import type { IFuseBadgeProps } from './types';

export const FuseBadge = ({
	as = 'div',
	content,
	isHidden = false,
	children,
	'data-test-id': testId,
	...otherProps
}: PropsWithChildren<IFuseBadgeProps>): JSX.Element =>
	isHidden ? (
		<>{children}</>
	) : (
		<BadgeWrapper>
			<SBadge as={as} data-test-id={`${testId}-badge`} forwardedAs={as} {...otherProps}>
				{content}
			</SBadge>

			{children}
		</BadgeWrapper>
	);
