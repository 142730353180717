import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';
import type { IFuseAvatarProps } from './types';
import { EFuseAvatarSize } from './types';
import { useAppTheme } from '../../../hooks/useAppTheme';
import { FuseAvatarBase } from '../../internal/FuseAvatarBase';
import { getColorFromText } from './utils';
import { avatarSizeToDimensions, avatarSizeToFontSize, SImage } from './FuseAvatar.styles';

export const FuseAvatar = forwardRef<HTMLImageElement | HTMLDivElement, IFuseAvatarProps>(
	({ imagePath, fullName, size = EFuseAvatarSize.Md, 'data-test-id': testId, ...otherProps }: IFuseAvatarProps, ref): JSX.Element => {
		const theme = useAppTheme();
		const colors = theme.colors.common.secondary;
		const availableColors = [colors.green, colors.cyanBlue, colors.turquoise, colors.darkTurquoise, colors.red, colors.violet, colors.purple];

		return imagePath ? (
			<SImage
				src={imagePath}
				alt="avatar"
				$width={avatarSizeToDimensions[size]}
				$height={avatarSizeToDimensions[size]}
				ref={ref as ForwardedRef<HTMLImageElement>}
				{...otherProps}
			/>
		) : (
			<FuseAvatarBase
				data-test-id={testId}
				fullName={fullName}
				color={getColorFromText(fullName, availableColors)}
				size={avatarSizeToDimensions[size]}
				fontSize={avatarSizeToFontSize[size]({ theme })}
				ref={ref as ForwardedRef<HTMLDivElement>}
				{...otherProps}
			/>
		);
	}
);

FuseAvatar.displayName = 'FuseAvatar';
