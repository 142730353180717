import styled from 'styled-components';
import { FuseFlex } from '../../layout/FuseFlex';
import { doubleSpacing } from '../../../theme/selectors';

export const SMainWrapper = styled(FuseFlex).attrs(({ theme }) => ({
	direction: 'column',
	alignItems: 'center',
	gap: doubleSpacing({ theme }),
}))`
	padding: ${doubleSpacing} 0;
	height: 100%;
`;
