import { isObject } from 'lodash-es';
import { useCallback } from 'react';
import { useActiveBreakpoint } from '../useActiveBreakpoint';
import type { BreakpointsMap } from '../../types';

export const useResponsiveStyle = (): { getResponsiveStyle: <T>(style: T | BreakpointsMap<T>, defaultValue: T) => T } => {
	const { activeBreakpointName } = useActiveBreakpoint();

	const getResponsiveStyle = useCallback(
		<T>(style: T | BreakpointsMap<T>, defaultValue: T): T => {
			if (isObject(style)) {
				const defaultVal = style['base'] ?? defaultValue;

				return style[activeBreakpointName] ?? defaultVal;
			}

			return (style as T) ?? defaultValue;
		},
		[activeBreakpointName]
	);

	return {
		getResponsiveStyle,
	};
};
