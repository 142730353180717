import type { IFuseInputBaseProps } from '../../internal/FuseInputBase';

export enum EFuseInputType {
	TEXT = 'text',
	NUMBER = 'number',
	PRICE = 'price',
	PERCENT = 'percent',
	PHONE = 'phone',
	MAIL = 'mail',
	SSN = 'ssn',
	TEXT_AREA = 'textArea',
}

export const MAX_SSN_INPUT_LENGTH = 9;
export const MAX_PHONE_INPUT_LENGTH = 10;

export interface IFuseInputProps extends Omit<IFuseInputBaseProps, 'onChange' | 'value' | 'height'> {
	value: string | number;
	onChange?: (value: string | number | null) => void;
	inputType?: EFuseInputType;
	decimalScale?: number;
	isMasked?: boolean;
}

export type CountryCode = 'US' | 'CA';

export type PhoneCodes = {
	[key in CountryCode]: string;
};

export interface IShowHideIconProps {
	$isDisabled?: boolean;
}
