import type { DefaultTheme, FlattenSimpleInterpolation, ThemedStyledProps } from 'styled-components';
import { css } from 'styled-components';

import { $enum } from 'ts-enum-util';
import { camelCase } from 'change-case';
import type { BreakpointsMap, StylesMixin } from '../types';
import { EBreakpoint } from '../types';
import { groupPropsByBreakpoint } from './groupPropsByBreakpoint';
import { isBreakpointName } from './isBreakpointName';
import { isMediaQueryName } from './isMediaQueryName';
import { mediaQueries } from './mediaQueries';

const sortedBreakpointKeys: (keyof BreakpointsMap<unknown>)[] = [
	'base',
	...$enum(EBreakpoint)
		.getEntries()
		.sort(([, bpValue1], [, bpValue2]) => parseInt(bpValue2.replace('px', ''), 10) - parseInt(bpValue1.replace('px', ''), 10))
		.map(([bpName]) => camelCase(bpName) as keyof BreakpointsMap<unknown>),
];

export const getResponsiveStyles =
	<T extends ThemedStyledProps<unknown, DefaultTheme>>(stylesMixin: StylesMixin) =>
	(props: T): FlattenSimpleInterpolation => {
		const propsByBreakpoint: BreakpointsMap<Partial<T>> = groupPropsByBreakpoint(props);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const styles = sortedBreakpointKeys.reduce((result, breakpoint) => {
			const mediaQueryName = `${breakpoint}AndBelow`;

			if (isBreakpointName(breakpoint) && propsByBreakpoint?.[breakpoint] && isMediaQueryName(mediaQueryName)) {
				return [
					...result,
					...(breakpoint === 'base'
						? css`
								${stylesMixin(propsByBreakpoint[breakpoint])}
							`
						: css`
								${mediaQueries?.[mediaQueryName]} {
									${stylesMixin(propsByBreakpoint[breakpoint])}
								}
							`),
				];
			}

			return result;
		}, []);

		return css`
			${styles}
		`;
	};
