import type { Keyframes } from 'styled-components';
import styled, { keyframes } from 'styled-components';
import { rippleColor, transitionDurationRippleEnter } from '../../../../theme/selectors';
import type { IRippleSpanProps, IRippleSpeedProps } from './types';

const getRippleAnimation = (speed: number): Keyframes => keyframes`
  to {
    opacity: 0;
    transform: scale(${speed});
  }
`;

export const SRippleSpan = styled.span<IRippleSpanProps>`
	top: ${(props): number => props.$y}px;
	left: ${(props): number => props.$x}px;
	width: ${(props): number => props.$size}px;
	height: ${(props): number => props.$size}px;
`;
export const SRippleContainer = styled.div<IRippleSpeedProps>`
	position: absolute;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	${SRippleSpan} {
		transform: scale(0);
		border-radius: 100%;
		position: absolute;
		opacity: 0.75;
		background-color: ${rippleColor};
		animation-name: ${(props): Keyframes => getRippleAnimation(props.$speed)};
		animation-duration: ${transitionDurationRippleEnter}ms;
	}
`;
