import React, { forwardRef } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import type { IFuseLottieProps } from './types';
import { SLottieWrapper } from './FuseLottie.styles';

export const FuseLottie = forwardRef<HTMLDivElement, IFuseLottieProps>(
	({ src, loop = false, width = '100%', height = '100%', 'data-test-id': testId, ...otherProps }: IFuseLottieProps, ref): JSX.Element =>
		!!src && (
			<SLottieWrapper data-test-id={`${testId}-lottie`} $width={width} $height={height} ref={ref}>
				<DotLottieReact src={src} autoplay loop={loop} {...otherProps} />
			</SLottieWrapper>
		)
);

FuseLottie.displayName = 'FuseLottie';
