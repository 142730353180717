import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IAuthState } from '@transact-client/store/slices/auth/types';
import type { IUser } from '@transact-client/commonUtils/auth';
import type { IDealership } from '@shared/proto/dealership/v1/messages/Dealership';
import jwtDecode from 'jwt-decode';
import { isNil } from 'lodash-es';

const initialState: IAuthState = {
	user: null,
	token: null,
	selectedDealershipId: null,
	selectedDealershipIds: [],
};

export const {
	reducer,
	actions: { setUser, setUserToken, clearUserToken, setSelectedDealershipId, setSelectedDealershipIds, setRemoteAuthData },
} = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<IUser>): void => {
			state.user = action.payload;
		},

		setUserToken: (state, action: PayloadAction<string>): void => {
			state.token = action.payload;
		},

		clearUserToken: (state): void => {
			state.token = null;
		},

		setSelectedDealershipId: (state, action: PayloadAction<IDealership['id']>): void => {
			state.selectedDealershipId = action.payload;
		},

		setSelectedDealershipIds: (state, action: PayloadAction<IDealership['id'][]>): void => {
			state.selectedDealershipIds = action.payload;
			state.selectedDealershipId = action.payload?.[0];
		},
		setRemoteAuthData: (state, action: PayloadAction<string>): void => {
			const decodedToken = jwtDecode<any>(action.payload);

			state.selectedDealershipId = decodedToken?.dealershipId;

			if (!isNil(decodedToken?.dealershipIds) && decodedToken?.dealershipIds?.length > 0) {
				state.selectedDealershipIds = decodedToken.dealershipIds;
				state.selectedDealershipId = decodedToken.dealershipIds?.[0];
			}

			state.user = {
				permissions: decodedToken.permissions,
				dealershipIds: decodedToken.dealershipIds,
				email: decodedToken.email,
				id: decodedToken.id,
				picture: null,
				name: decodedToken.name,
				authStrategy: '',
				internalUser: false,
				inviterEmail: decodedToken.inviterEmail,
				remoteType: decodedToken.remoteType,
			};
		},
	},
});
