import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import type { IFuseButtonGroupStyleProps } from '@fuse/react-ui/components/inputs/FuseButtonGroup/types';
import {
	borderColorDefault,
	borderDefault,
	borderRadiusDefault,
	defaultSpacing,
	disabledColorBackground,
	doubleSpacing,
	fontSizeBody1,
	fontSizeBody2,
	fontWeightDefault,
	fontWeightMd,
	halfSpacing,
	primaryColorBackground,
	primaryColorDefault,
	textColorDefault,
	textColorDisabled,
} from '../../../theme/selectors';
import { FuseFlex } from '../../layout/FuseFlex/FuseFlex';
import { FuseTypography } from '../../dataDisplay/FuseTypography/FuseTypography';
import type { cssHelperType } from '../../../types';

export const SMainWrapper = styled(FuseFlex).attrs(() => ({
	alignItems: 'center',
}))<IFuseButtonGroupStyleProps>`
	flex-wrap: wrap;
	${({ $gap }): cssHelperType =>
		({
			sm: css`
				gap: ${halfSpacing};
			`,
			md: css`
				gap: ${defaultSpacing};
			`,
			lg: css`
				gap: ${doubleSpacing};
			`,
		})[$gap] ?? null}
`;

export const SOptionWrapper = styled(FuseFlex)<IFuseButtonGroupStyleProps>`
	padding: 0 ${doubleSpacing};
	align-items: center;
	cursor: ${({ $disabled }): Property.Cursor => ($disabled ? 'not-allowed' : 'pointer')};
	background-color: ${({ $isSelected, theme }): Property.Color => ($isSelected ? primaryColorBackground({ theme }) : 'transparent')};

	border: ${borderDefault};
	border-color: ${({ theme, $isSelected }): Property.Color => ($isSelected ? primaryColorDefault({ theme }) : borderColorDefault({ theme }))};

	height: ${({ $height }): Property.Height =>
		({
			sm: '2rem',
			md: '2.75rem',
		})[$height] ?? null};

	${({ $gap }): any =>
		$gap
			? css`
					border-radius: ${borderRadiusDefault};
				`
			: css`
					&:first-child {
						border-bottom-left-radius: ${borderRadiusDefault};
						border-top-left-radius: ${borderRadiusDefault};
					}

					&:last-child {
						border-bottom-right-radius: ${borderRadiusDefault};
						border-top-right-radius: ${borderRadiusDefault};
					}
				`};

	${({ $disabled, $isSelected }): cssHelperType =>
		$disabled &&
		!$isSelected &&
		css`
			background-color: ${disabledColorBackground};
		`};
`;

export const SOptionLabelText = styled(FuseTypography).attrs(() => ({}))<IFuseButtonGroupStyleProps>`
	font-size: ${({ $height, theme }): Property.Height =>
		({
			sm: fontSizeBody2({ theme }),
			md: fontSizeBody1({ theme }),
		})[$height] ?? null};

	font-weight: ${({ $isSelected, theme }): Property.FontWeight => ($isSelected ? fontWeightMd({ theme }) : fontWeightDefault({ theme }))};
	color: ${({ $isSelected, theme }): Property.Color => ($isSelected ? primaryColorDefault({ theme }) : textColorDefault({ theme }))};

	${({ $disabled, $isSelected }): cssHelperType =>
		$disabled &&
		!$isSelected &&
		css`
			color: ${textColorDisabled};
		`};
`;
