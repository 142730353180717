import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import { mediaQueries } from '../../../utils/mediaQueries';
import type { ISDocumentWrapperProps, ISOverlayItemWrapperProps } from './types';
import { borderDefault } from '../../../theme/selectors';
import type { cssHelperType } from '../../../types';
import { FuseFlex } from '../../layout/FuseFlex/FuseFlex';

export const SSkeletonWrapper = styled.div<ISDocumentWrapperProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: 4rem 8rem;
	box-sizing: border-box;
	aspect-ratio: 210 / 297; // A4
	overflow: auto;
	background: #fff;

	${mediaQueries.tabletAndBelow} {
		padding: 2rem;
	}

	${({ $showBorder }): cssHelperType =>
		$showBorder &&
		css`
			border: ${borderDefault};
		`}
`;

export const SDocumentWrapper = styled.div<ISDocumentWrapperProps>`
	width: 100%;
	box-sizing: border-box;
	overflow: auto;

	${({ $showBorder }): cssHelperType =>
		$showBorder &&
		css`
			border: ${borderDefault};
		`}
`;

export const SOverlayItemWrapper = styled.div<ISOverlayItemWrapperProps>`
	position: absolute;
	left: ${(props): Property.Left => `${props.$x}px`};
	bottom: ${(props): Property.Bottom => `${props.$y}px`};
	width: ${(props): Property.Width => `${props.$width}px`};
	height: ${(props): Property.Height => `${props.$height}px`};
	display: flex;
`;

export const SPageWrapper = styled(FuseFlex).attrs({ direction: 'column' })`
	width: 100%;
	position: relative;
`;

export const SImage = styled.img`
	width: 100%;
`;
