import React, { forwardRef } from 'react';
import type { IFuseToggleProps } from './types';
import { EFuseToggleSize, EFuseToggleTextDirection } from './types';
import { FuseTypography } from '../../dataDisplay/FuseTypography';
import {
	SErrorIcon,
	SErrorWrapper,
	SFuseToggleContainer,
	SFuseToggleIndicator,
	SFuseToggleIndicatorAndLabelWrapper,
	SFuseToggleIndicatorWrapper,
	SFuseToggleWrapper,
} from './FuseToggle.styles';

export const FuseToggle = forwardRef<HTMLInputElement, IFuseToggleProps>(
	(
		{
			value,
			isDisabled = false,
			showInsideLabel = false,
			label,
			labelDirection = EFuseToggleTextDirection.Left,
			errorMessage,
			size = EFuseToggleSize.Small,
			onChange,
			'data-test-id': testId,
			...otherProps
		}: IFuseToggleProps,
		ref
	): JSX.Element => {
		const handleChange = (): void => {
			if (!isDisabled) {
				onChange?.(!value);
			}
		};

		const insideLabel = value ? 'On' : 'Off';

		return (
			<SFuseToggleContainer>
				<input hidden onChange={(e): void => onChange?.(e.target.checked)} checked={value} type="checkbox" ref={ref} {...otherProps} />

				<SFuseToggleWrapper>
					<SFuseToggleIndicatorAndLabelWrapper isDisabled={isDisabled} labelDirection={labelDirection} onClick={handleChange}>
						<SFuseToggleIndicatorWrapper size={size} isDisabled={isDisabled} isActive={value}>
							<SFuseToggleIndicator
								data-test-id={`${testId}-toggle`}
								data-test-value={value}
								data-test-disabled={isDisabled ? true : undefined}
								data-test-error={errorMessage ? true : undefined}
								size={size}
								isDisabled={isDisabled}
								isActive={value}
							/>

							{(showInsideLabel || isDisabled) && insideLabel}
						</SFuseToggleIndicatorWrapper>

						{label?.length && (
							<FuseTypography as="label" variant="b2" color={isDisabled ? 'disabled' : 'text'}>
								{label}
							</FuseTypography>
						)}
					</SFuseToggleIndicatorAndLabelWrapper>

					{errorMessage?.length > 0 && (
						<SErrorWrapper data-test-id={`${testId}-toggle-error`}>
							<SErrorIcon />

							<FuseTypography variant="b3" color="error">
								{errorMessage}
							</FuseTypography>
						</SErrorWrapper>
					)}
				</SFuseToggleWrapper>
			</SFuseToggleContainer>
		);
	}
);

FuseToggle.displayName = 'FuseToggle';
