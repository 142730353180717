import styled from 'styled-components';
import type { Property } from 'csstype';
import { shadowDarker } from '@fuse/react-ui';
import type { IFuseChatBubbleStyleProps } from '@fuse/react-ui/components/chat/FuseChatBubble/types';
import { EFuseChatBubbleSize } from '@fuse/react-ui/components/chat/FuseChatBubble/types';

export const bubbleSizeToDimensions: Record<EFuseChatBubbleSize, Property.Width> = {
	[EFuseChatBubbleSize.Small]: '50px',
	[EFuseChatBubbleSize.Large]: '55px',
};

export const SFuseChatBubbleIcon = styled.div<IFuseChatBubbleStyleProps>`
	width: ${(props): number | string => props.$width};
	height: ${(props): number | string => props.$height};
	background-color: ${({ theme }): Property.BackgroundColor => theme.colors.primary.default};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		width: ${bubbleSizeToDimensions[EFuseChatBubbleSize.Large]};
		height: ${bubbleSizeToDimensions[EFuseChatBubbleSize.Large]};
	}
	box-shadow: ${shadowDarker};
`;
