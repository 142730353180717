import styled, { css } from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import type { Property } from 'csstype';
import type { ISCheckboxInputWrapperProps, ISCheckboxWrapperProps, ISCheckmarkProps, ISCheckmarkWrapperProps } from './types';
import {
	backgroundColorDefault,
	borderColorDefault,
	borderRadiusHalf,
	borderWidthDefault,
	defaultSpacing,
	disabledColorDefault,
	errorColorDefault,
	fontFamily,
	fontSizeBody1,
	fontSizeBody2,
	fontSizeBody3,
	fontWeightDefault,
	halfSpacing,
	primaryColorContrastText,
	primaryColorDefault,
	textColorDefault,
	transitionDurationDefault,
} from '../../../theme/selectors';
import type { cssHelperType } from '../../../types';

export const CheckboxWrapper = styled.div<ISCheckboxWrapperProps>`
	display: flex;
	flex-direction: column;
	gap: ${halfSpacing};
	width: ${({ $width }): Property.Width => $width ?? 'auto'};
	height: ${({ $height }): Property.Height => $height ?? 'auto'};
`;

export const LabelAndCheckmarkWrapper = styled.div<ISCheckboxWrapperProps>`
	display: inline-flex;
	align-items: baseline;
	gap: ${defaultSpacing};
	cursor: pointer;
	position: relative;
	width: ${({ $width }): Property.Width => $width ?? 'auto'};
	height: ${({ $height }): Property.Height => $height ?? 'auto'};
`;

export const CheckmarkWrapper = styled.span<ISCheckmarkWrapperProps>`
	width: ${({ $width, theme }): Property.Width => $width ?? fontSizeBody1({ theme })};
	height: ${({ $height, theme }): Property.Height => $height ?? fontSizeBody1({ theme })};
	border-radius: ${borderRadiusHalf};
	transition: all ${transitionDurationDefault};
	border: ${borderWidthDefault} solid ${({ $borderColor, theme }): Property.BorderColor => $borderColor ?? borderColorDefault({ theme })};
	background-color: ${backgroundColorDefault};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	box-sizing: border-box;

	${({ $isChecked }): cssHelperType =>
		$isChecked &&
		css`
			background-color: ${primaryColorDefault};
			border: none;
		`}

	${({ $isDisabled }): cssHelperType =>
		$isDisabled &&
		css`
			background-color: ${disabledColorDefault};
			border: none;
		`}
`;

export const Checkmark = styled(FaCheck)<ISCheckmarkProps>`
	color: ${primaryColorContrastText};
	opacity: 0;
	transition: all ${transitionDurationDefault};
	width: 75%;
	height: 75%;

	${({ $isChecked }): cssHelperType =>
		$isChecked &&
		css`
			opacity: 1;
		`}
`;

export const ErrorMessage = styled.span`
	font-family: ${fontFamily};
	font-size: ${fontSizeBody3};
	color: ${errorColorDefault};
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const ErrorWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.1rem;
`;

export const ErrorIcon = styled(MdError)`
	fill: ${errorColorDefault};
	width: ${fontSizeBody2};
	height: ${fontSizeBody2};
	align-self: flex-start;
	flex-shrink: 0;
`;

export const CheckboxLabel = styled.label`
	color: ${textColorDefault};
	font-family: ${fontFamily};
	font-size: ${fontSizeBody1};
	font-weight: ${fontWeightDefault};
	cursor: pointer;
`;

export const SCheckboxInput = styled.input`
	pointer-events: none;
	opacity: 0;
	position: absolute;
	width: 0;
`;

export const SCheckboxInputWrapper = styled.div<ISCheckboxInputWrapperProps>`
	position: relative;
	width: ${({ $width }): Property.Width => $width ?? 'auto'};
	height: ${({ $height }): Property.Height => $height ?? 'auto'};
`;
