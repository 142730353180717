import styled from 'styled-components';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { backgroundColorBackdrop, zIndexBackdrop } from '../../../theme/selectors';

export const SBackdrop = styled(motion.div).attrs<void, MotionProps>(({ key }) => ({
	key,
	initial: { opacity: 0 },
	animate: { opacity: 0.6 },
	exit: { opacity: 0 },
	transition: { duration: 0.2 },
}))`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${backgroundColorBackdrop};
	z-index: ${zIndexBackdrop};
`;
