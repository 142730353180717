import type { MouseEventHandler, PropsWithChildren } from 'react';
import React, { forwardRef, useCallback } from 'react';
import { clsx } from 'clsx';
import type { IFuseIconButtonProps } from './types';
import { SButtonWrapper } from './FuseIconButton.styles';
import { FuseBadge } from '../../dataDisplay/FuseBadge';

export const FuseIconButton = forwardRef<HTMLButtonElement, IFuseIconButtonProps>(
	(
		{
			dangerouslySetClassName,
			onClick,
			icon: Icon,
			isDisabled = false,
			isCircle = false,
			color = 'secondary',
			size = 'md',
			'data-test-id': testId,
			badgeProps,
			...otherProps
		}: PropsWithChildren<IFuseIconButtonProps>,
		ref
	): JSX.Element => {
		const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
			(event) => {
				if (!isDisabled) {
					onClick?.(event);
				}
			},
			[isDisabled, onClick]
		);

		return (
			<SButtonWrapper
				className={clsx(dangerouslySetClassName)}
				onClick={handleClick}
				disabled={isDisabled}
				ref={ref}
				$isCircle={isCircle}
				$color={color}
				$size={size}
				data-test-id={`${testId}-icon-button`}
				data-test-disabled={isDisabled ? true : undefined}
				{...otherProps}
			>
				{badgeProps ? (
					<FuseBadge {...badgeProps}>
						<Icon data-test-id={`${testId}-icon-button-icon`} />
					</FuseBadge>
				) : (
					<Icon data-test-id={`${testId}-icon-button-icon`} />
				)}
			</SButtonWrapper>
		);
	}
);

FuseIconButton.displayName = 'FuseIconButton';
