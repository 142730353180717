import type { JSX } from 'react';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import type { ChartData } from 'chart.js';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useResponsiveStyle } from '../../../hooks/useResponsiveStyle';
import { formatAsNumber } from '../../../utils/textFormatting';
import { backgroundColorDefault, textColorDefault } from '../../../theme/selectors';
import { SMainWrapper } from './FusePieChart.styles';
import type { IFusePieChartProps } from './types';
import { FuseChartWrapper } from '../../internal/FuseChartsWrapper';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const FusePieChart = ({
	title = 'test',
	data,
	'data-test-id': testId,
	width,
	height,
	tooltip,
	labelPosition = 'bottom',
}: IFusePieChartProps): JSX.Element => {
	const theme = useTheme();
	const { getResponsiveStyle } = useResponsiveStyle();

	const pieColors = [
		theme.colors.common.main.blue,
		theme.colors.common.secondary.violet,
		theme.colors.common.secondary.darkTurquoise,
		theme.colors.common.secondary.purple,
		theme.colors.common.secondary.red,
		theme.colors.common.secondary.green,
		theme.colors.common.secondary.yellow,
	];
	const chartData: ChartData<'doughnut'> = {
		datasets: [
			{
				data: data.map((item) => +formatAsNumber(item.value, 0, 2)),
				backgroundColor: pieColors,
				borderColor: pieColors,
				borderWidth: 1,
			},
		],
		labels: data.map((item) => `${item.label} | ${formatAsNumber(item.value, 0, 2)}`),
	};

	const labelFormatter = (v, context): string => {
		const sumOfValues: number = (context.dataset.data as number[]).reduce((acc, val) => acc + val);

		return sumOfValues ? `${formatAsNumber((v / sumOfValues) * 100, 0, 0)}%` : '';
	};

	return (
		<FuseChartWrapper width={width} height={height} data-test-id={`${testId}-pie-chart`} title={title} tooltip={tooltip}>
			<SMainWrapper>
				<Doughnut
					data={chartData}
					options={{
						plugins: {
							datalabels: {
								color: textColorDefault({ theme }),
								formatter: labelFormatter,
								backgroundColor: backgroundColorDefault({ theme }),
								borderRadius: 50,
								borderColor: textColorDefault({ theme }),
							},
							legend: {
								display: true,
								position: getResponsiveStyle(labelPosition, 'bottom'),
								align: 'center',
								labels: {
									color: textColorDefault({ theme }),
									padding: 16,
									usePointStyle: true,
									boxHeight: 5,
								},
							},
						},
					}}
				/>
			</SMainWrapper>
		</FuseChartWrapper>
	);
};
