import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { isEqual } from 'lodash-es';
import { SMainWrapper, SOptionLabelText, SOptionWrapper } from './FuseButtonGroup.styles';
import type { IFuseButtonGroupProps } from './types';

export const FuseButtonGroup = <ValueType,>({
	onClick,
	value,
	'data-test-id': testId,
	options,
	gap,
	height = 'md',
	disabled = false,
}: IFuseButtonGroupProps<ValueType>): JSX.Element => {
	const selected = useMemo(() => options?.find((opt) => isEqual(opt.value, value)), [value, options]);

	const handleClick = (val: ValueType): void => {
		if (!disabled) {
			onClick(val);
		}
	};

	return (
		<SMainWrapper $gap={gap} data-test-id={`${testId ? `${testId}-` : ''}group-button`}>
			{options.map((item, index) => (
				<SOptionWrapper
					data-test-id={`${testId ? `${testId}-` : ''}group-button-item-wrapper-${index}`}
					$height={height}
					$gap={gap}
					$disabled={disabled}
					$isSelected={isEqual(item.value, selected?.value)}
					onClick={() => handleClick(item.value)}
					key={item.label}
				>
					<SOptionLabelText
						data-test-id={`${testId ? `${testId}-` : ''}group-button-item-label-${index}`}
						$height={height}
						$disabled={disabled}
						$isSelected={isEqual(item.value, selected?.value)}
					>
						{item.label}
					</SOptionLabelText>
				</SOptionWrapper>
			))}
		</SMainWrapper>
	);
};

FuseButtonGroup.displayName = 'FuseGroupButton';
